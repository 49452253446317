import React, { useState } from "react";
import { Switch } from "@saleshandy/designs";
import { Placement } from "@saleshandy/designs/lib/types/enum";
import { IProps } from "./types";
import { getSwitchTooltip } from "../../util/helper";

const AssignClientStatus: React.FC<IProps> = ({
  status,
  isDisabled,
  onChange,
}) => {
  const [isChecked] = useState<boolean>(status);

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <Switch
      variant={Switch.Variant.Default}
      size={Switch.Size.Small}
      checked={isChecked}
      onChange={(e) => handleChange(e)}
      className='switch'
      disabled={isDisabled}
      tooltip={getSwitchTooltip(isChecked, isDisabled)}
      placement={Placement.Bottom}
    />
  );
};

export default AssignClientStatus;
