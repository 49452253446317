/* eslint-disable global-require */
import { constants } from "@saleshandy/designs/lib/types/enum";

export const Images = {
  Neptik: require("../../assets/images/neptik.svg").default,
  FileText: require("../../assets/images/file-text.svg").default,
  Pencil: require("../../assets/images/pencil.svg").default,
  Trash: require("../../assets/images/trash.svg").default,
  TrashDanger: require("../../assets/images/trash-danger.svg").default,
  Login: require("../../assets/images/login.svg").default,
  Checks: require("../../assets/images/checks.svg").default,
  UserCheck: require("../../assets/images/user-check.svg").default,
  Refresh: require("../../assets/images/refresh.svg").default,
  UserPlus: require("../../assets/images/user-plus.svg").default,
  SaleshandyHeaderLogo:
    require("../../assets/images/saleshandy-header-logo.svg").default,
  NeptikBlue: require("../../assets/images/neptik-blue.svg").default,
  Logout: require("../../assets/images/logout.svg").default,
  FileBlank: require("../../assets/images/file-blank.svg").default,
  InfoCircle: require("../../assets/images/info-circle.svg").default,
  InfoCircleDanger: require("../../assets/images/info-circle-danger.svg")
    .default,
  SaleshandyLogo: require("../../assets/images/saleshandy-logo.svg").default,
  Loader: require("../../assets/images/loader.svg").default,
  AlertTriangle: require("../../assets/images/alert-triangle.svg").default,
  Rocket: require("../../assets/images/rocket.svg").default,
  WalletBlue: require("../../assets/images/wallet-blue.svg").default,
  SHLogoIcon: require("../../assets/images/sh-logo-icon.svg").default,
};

export const paginationOptions = {
  options: {
    page: 1,
    limit: constants.DEFAULT_PAGE_NUM,
  },
};
