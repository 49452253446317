import React, { useState } from "react";
import { IntercomEvents } from "../../enum/intercom-events";
import { Cross, MessageCircle } from "../../svg";

const HelpscoutButton = () => {
  const [isHelpscoutBeaconOpen, setIsHelpscoutBeaconOpen] = useState(false);

  const intercomHelperFunction = () => {
    if (window.Intercom && !isHelpscoutBeaconOpen) {
      window.Intercom(IntercomEvents.SHOW, IntercomEvents.ON_SHOW, () => {
        setIsHelpscoutBeaconOpen(true);
      });
    }
    if (window.Intercom && isHelpscoutBeaconOpen) {
      window.Intercom(IntercomEvents.HIDE, IntercomEvents.ON_HIDE, () => {
        setIsHelpscoutBeaconOpen(false);
      });
    }
  };

  const onIntercomScoutToggle = () => {
    setIsHelpscoutBeaconOpen(!isHelpscoutBeaconOpen);
    intercomHelperFunction();
  };

  if (window.Intercom) {
    window.Intercom(IntercomEvents.ON_SHOW, () => {
      setIsHelpscoutBeaconOpen(true);
    });
    window.Intercom(IntercomEvents.ON_HIDE, () => {
      setIsHelpscoutBeaconOpen(false);
    });
  }

  return (
    <button
      type='button'
      className='helpscout-button'
      onClick={onIntercomScoutToggle}
    >
      {isHelpscoutBeaconOpen ? <Cross /> : <MessageCircle />}
    </button>
  );
};

export default HelpscoutButton;
