import React from "react";
import { SkeletonLoading } from "@saleshandy/design-system";

const EVCreditsLoader = () => (
  <div className="card">
    <div className="card-body">
      <div className="ev-credits-data evc-loader">
        <SkeletonLoading width={150} height={20} />
        <SkeletonLoading width={80} height={20} className="mt-1 mb-2" />
        <SkeletonLoading width={350} height={20} className="mt-1" />
      </div>
      <SkeletonLoading width={150} height={32} />
    </div>
  </div>
);

export default EVCreditsLoader;
