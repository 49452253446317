export enum BillingsAndSubscriptionsTabs {
  Usage = "usage",
  Subscription = "subscription",
}

export enum SubscriptionPlans {
  FREE = "free",
  TRIAL = "trial",
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Paused = "paused",
}
