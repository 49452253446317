import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from "../../../../shared/types";
import { ForgotPasswordRequest } from "../../types";
import { forgotPassword } from "../../helper/auth.api";

export const forgotPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  ForgotPasswordRequest,
  { rejectValue: ResponseErrorWithHandled }
>("create-password/createPasswordRequest", async (args, thunkAPI) => {
  try {
    return await forgotPassword(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
