import { RequestStatus } from "../enum/request-status";

export type ExecuteOnRequestStatusProps = {
  status: string;
  onIdeal?: () => void;
  onPending?: () => void;
  onSuccess?: () => void;
  onFailed?: () => void;
};

export const executeOnRequestStatus = ({
  status,
  onIdeal,
  onPending,
  onSuccess,
  onFailed,
}: ExecuteOnRequestStatusProps) => {
  if (status === RequestStatus.Ideal && onIdeal) {
    onIdeal();
    return;
  }
  if (status === RequestStatus.Pending && onPending) {
    onPending();
    return;
  }
  if (status === RequestStatus.Succeeded && onSuccess) {
    onSuccess();
    return;
  }
  if (status === RequestStatus.Failed && onFailed) {
    onFailed();
  }
};

export type ExecuteOnRequestStatusWithPrevStatusCheckProps = {
  status: string;
  prevStatus: string;
  onIdeal?: () => void;
  onPending?: () => void;
  onSuccess?: () => void;
  onFailed?: () => void;
};

export const executeOnRequestStatusWithPrevStatusCheck = ({
  status,
  prevStatus,
  onIdeal,
  onPending,
  onSuccess,
  onFailed,
}: ExecuteOnRequestStatusWithPrevStatusCheckProps) => {
  if (status !== prevStatus) {
    if (status === RequestStatus.Ideal && onIdeal) {
      onIdeal();
      return;
    }
    if (status === RequestStatus.Pending && onPending) {
      onPending();
      return;
    }
    if (status === RequestStatus.Succeeded && onSuccess) {
      onSuccess();
      return;
    }
    if (status === RequestStatus.Failed && onFailed) {
      onFailed();
    }
  }
};
