export enum IntercomEvents {
  SHOW = "show",
  HIDE = "hide",
  ON_SHOW = "onShow",
  ON_HIDE = "onHide",
  UPDATE = "update",
  SHUTDOWN = "shutdown",
  SHOW_MESSAGES = "showMessages",
  SHOW_NEW_MESSAGES = "showNewMessages",
}
