import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Tabs, { TabPane } from "rc-tabs";

import type { IProps } from "./billing-container";
import { AgencyRole, BillingsAndSubscriptionsTabs } from "../../shared/enum";

import { capitalize } from "../../shared/utils/string";

import Usage from "./components/usage";
import Subscription from "./components/subscription";

const Billing: React.FC<IProps> = ({ role, planAmount }) => {
  const history = useHistory();

  const [activeTab, setActiveTab] =
    useState<BillingsAndSubscriptionsTabs>(null);

  const onTabChange = (key: string) => {
    setActiveTab(key as BillingsAndSubscriptionsTabs);
  };

  const updateActiveTab = () => {
    const paths =
      history.location.pathname.split("/").filter((path) => path) || [];

    if (
      role === AgencyRole.AgencyOwner &&
      planAmount !== null &&
      paths[1] === BillingsAndSubscriptionsTabs.Subscription
    ) {
      setActiveTab(BillingsAndSubscriptionsTabs.Subscription);
    } else {
      setActiveTab(BillingsAndSubscriptionsTabs.Usage);
    }
  };

  useEffect(() => {
    updateActiveTab();
  }, [role]);

  useEffect(() => {
    if (activeTab === BillingsAndSubscriptionsTabs.Usage) {
      history.push({
        pathname: "/billing/usage",
      });
    }

    if (
      activeTab === BillingsAndSubscriptionsTabs.Subscription &&
      planAmount !== null
    ) {
      history.push({
        pathname: "/billing/subscription",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      updateActiveTab();
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <div className="billing-subscription-container">
        <Tabs
          activeKey={activeTab}
          prefixCls="bs-tabs"
          className="bs-tabs-small"
          onChange={onTabChange}
        >
          <TabPane
            tab={capitalize(BillingsAndSubscriptionsTabs.Usage)}
            key={BillingsAndSubscriptionsTabs.Usage}
          >
            <Usage activeTab={activeTab} />
          </TabPane>
          {role === AgencyRole.AgencyOwner && planAmount !== null && (
            <TabPane
              tab={capitalize(BillingsAndSubscriptionsTabs.Subscription)}
              key={BillingsAndSubscriptionsTabs.Subscription}
            >
              <Subscription />
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default Billing;
