import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/root-reducer";

import {
  getEVCreditsRequest,
  getAccountPlanUsageRequest,
} from "../../extra-actions";

import Usage from "./usage";
import {
  resetGetAccountPlanUsageRequest,
  resetGetEVCreditsRequest,
} from "../../billing-slice";

const mapStateToProps = (state: RootState) => ({
  getEVCreditsRequestStatus: state.billing.getEVCreditsRequest.status,
  getEVCreditsRequestError: state.billing.getEVCreditsRequest.error,

  getAccountPlanUsageRequestStatus:
    state.billing.getAccountPlanUsageRequest.status,
  getAccountPlanUsageRequestError:
    state.billing.getAccountPlanUsageRequest.error,

  evCredits: state.billing.evCredits,
  accountPlanUsage: state.billing.accountPlanUsage,
});

const mapDispatchToProps = {
  sendGetEVCreditsRequest: () => getEVCreditsRequest(),
  resetGetEVCreditsRequest: () => resetGetEVCreditsRequest(),

  sendGetAccountPlanUsageRequest: () => getAccountPlanUsageRequest(),
  resetGetAccountPlanUsageRequest: () => resetGetAccountPlanUsageRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  activeTab: string;
};

export default connector(Usage);
