/* eslint-disable import/no-cycle */
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import {
  getUserSettingsRequest,
  updateOnboardingRequest,
  completeOnboardingRequest,
} from "./extra-actions";
import Home from "./home";
import { resendVerificationEmailRequest } from "../auth/components/signup/extra-action";
import { UpdateOnboardingPayload } from "./types/request/update-onboarding";
import { resetUpdateOnboardingRequest } from "./home-slice";

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.firstName,
  lastName: state.home.lastName,
  email: state.home.email,
  verified: state.home.verified,
  agencyUserProfileProgress: state.home.agencyUserProfileProgress,
  subscription: state.home.subscription,
  agencyLogo: state.home.agencyLogo?.tertiary,
  role: state.home.role,
  adminFirstName: state.home.adminDetails?.firstName,

  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,

  updateOnboardingRequestStatus: state.home.updateOnboardingRequest.status,
  updateOnboardingRequestError: state.home.updateOnboardingRequest.error,

  resendVerificationEmailRequestStatus:
    state.signup.resendVerificationEmailRequest.status,
  resendVerificationEmailRequestError:
    state.signup.resendVerificationEmailRequest.error,
});

const mapDispatchToProps = {
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),

  sendUpdateOnboardingRequest: (payload: UpdateOnboardingPayload) =>
    updateOnboardingRequest(payload),

  sendCompleteOnboardingRequest: () => completeOnboardingRequest(),

  resetUpdateOnboardingRequest: () => resetUpdateOnboardingRequest(),

  resendVerificationEmailRequest: (email: string) =>
    resendVerificationEmailRequest(email),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Home);
