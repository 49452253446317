import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import Clients from "./clients";
import {
  addClientRequest,
  deleteClientRequest,
  editClientRequest,
  getClientsRequest,
  oneClickLoginRequest,
  toggleClientStatusRequest,
} from "./extra-actions";
import { AddClientRequest } from "./types/request/add-client-request";
import { GetClientsRequest } from "./types/request/get-clients";
import { EditClientRequest } from "./types";
import { GetAgencyUsersRequest } from "../teams/types";
import { getAgencyUsersRequest } from "../teams/extra-action";

const mapStateToProps = (state: RootState) => ({
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
  getClientsRequestStatus: state.clients.getClientsRequest.status,
  getClientsRequestMessage: state.clients.getClientsRequest?.message,
  clients: state.clients.clients,
  clientsCount: state.clients.clientCount,
  oneClickLoginToken: state.clients.oneClickLoginToken,
  loginUrl: state.clients.loginUrl,
  addClientRequestStatus: state.clients.addClientRequest.status,
  addClientRequestMessage: state.clients.addClientRequest.message,
  toggleClientStatusRequestStatus:
    state.clients.toggleClientStatusRequest.status,
  toggleClientStatusRequestMessage:
    state.clients.toggleClientStatusRequest.message,
  editClientRequestStatus: state.clients.editClientRequest.status,
  editClientRequestMessage: state.clients.editClientRequest.message,
  oneClickLoginRequestStatus: state.clients.oneClickLoginRequest.status,
  oneClickLoginRequestMessage: state.clients.oneClickLoginRequest.message,
  deleteClientRequestStatus: state.clients.deleteClientRequest.status,
  deleteClientRequestMessage: state.clients.deleteClientRequest.message,
  firstName: state.home.firstName,
  lastName: state.home.lastName,
  email: state.home.email,
  role: state.home.role,
  planCode: state.home.subscription?.planCode,
  adminFirstName: state.home.adminDetails?.firstName,
});

const mapDispatchToProps = {
  sendGetClientsRequest: (params: GetClientsRequest) =>
    getClientsRequest(params),
  sendAddClientRequest: (payload: AddClientRequest) =>
    addClientRequest(payload),
  sendToggleClientRequest: (clientId: number) =>
    toggleClientStatusRequest({ clientId }),
  sendEditClientRequest: (payload: EditClientRequest) =>
    editClientRequest(payload),
  sendOneClickLoginRequest: (clientId: number) =>
    oneClickLoginRequest({ clientId }),
  sendGetAgencyUsersRequest: (params: GetAgencyUsersRequest) =>
    getAgencyUsersRequest(params),
  sendDeleteClientRequest: (clientId: number) =>
    deleteClientRequest({ clientId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Clients);
