import React, { useState, useEffect } from "react";
import { Button, ImageIcon, Input } from "@saleshandy/designs";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import type { IProps } from "./create-password-container";
import PasswordValidationChecklist from "../../../../shared/components/password-validation-checklist";
import { Images } from "../../../../shared/utils/app-constants";
import {
  executeOnRequestStatus,
  redirectWithoutRefresh,
} from "../../../../shared/utils";
import { isPasswordValid } from "../../util/helper";
import { getCurrentTimeZone } from "../../../../shared/utils/date-time";
import {
  getIsRequestPending,
  getIsRequestSucceeded,
} from "../../../../shared/utils/get-request-status";
import SignupForm from "../signup-form";
import Loader from "../../../../shared/components/loader";
import routes from "../../../../routes";

const CreatePassword: React.FC<IProps> = ({
  createPasswordRequestStatus,
  sendCreatePasswordRequest,
  verifyTokenRequestStatus,
  verifyTokenRequestMessage,
  sendVerifyPasswordRequest,
  verifyResetPasswordTokenRequestStatus,
  verifyResetPasswordTokenRequestMessage,
  sendVerifyResetPasswordTokenRequest,
  resetPasswordRequestStatus,
  resetPasswordRequestMessage,
  sendResetPasswordRequest,

  firstName,
  lastName,
  email,
}) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const isResetPasswordAction = params.get("reset-password");

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPasswordSetSuccessfully, setIsPasswordSetSuccessfully] =
    useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword((preState) => !preState);
  };

  const onInputChange = (value) => {
    setPassword(value);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    let timeZone = getCurrentTimeZone();

    if (timeZone === "Asia/Calcutta") {
      timeZone = "Asia/Kolkata";
    }

    if (isResetPasswordAction) {
      sendResetPasswordRequest(token, password);
    } else {
      sendCreatePasswordRequest({ token, password, timeZone });
    }
  };

  useEffect(() => {
    if (isResetPasswordAction) {
      sendVerifyResetPasswordTokenRequest(token);
    } else {
      sendVerifyPasswordRequest(token);
    }
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: resetPasswordRequestStatus,
      onSuccess: () => {
        setIsPasswordSetSuccessfully(true);
        toaster.success(resetPasswordRequestMessage, { theme: Theme.New });
      },
      onFailed: () => {
        toaster.error(resetPasswordRequestMessage);
        redirectWithoutRefresh(routes.LOGIN);
      },
    });
  }, [resetPasswordRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyTokenRequestStatus,
      onFailed: () => {
        toaster.error(verifyTokenRequestMessage);
        redirectWithoutRefresh(routes.LOGIN);
      },
    });
  }, [verifyTokenRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyResetPasswordTokenRequestStatus,
      onFailed: () => {
        toaster.error(verifyResetPasswordTokenRequestMessage);
      },
    });
  }, [verifyResetPasswordTokenRequestStatus]);

  if (
    !isResetPasswordAction &&
    getIsRequestSucceeded(verifyTokenRequestStatus)
  ) {
    return (
      <SignupForm
        isInvitedUser
        firstName={firstName}
        lastName={lastName}
        email={email}
        invitationToken={token}
      />
    );
  }

  if (
    isResetPasswordAction &&
    getIsRequestSucceeded(verifyResetPasswordTokenRequestStatus)
  ) {
    return (
      <div className="auth__container">
        <div className="card">
          {!isPasswordSetSuccessfully && (
            <div className="logo--section">
              <ImageIcon src={Images.SaleshandyLogo} />
            </div>
          )}
          <div className="card-body">
            {!isPasswordSetSuccessfully && (
              <div className="card-body__title">
                <span className="semibold-4">Create New Password</span>
              </div>
            )}
            {isPasswordSetSuccessfully ? (
              <div className="password-set-success__container">
                <ImageIcon src={Images.Checks} className="mt-1" />
                <div className="d-flex flex-column">
                  <span className="semibold-4 mt-3">
                    Password set successfully
                  </span>
                  <span className="semibold-1 sub-text">
                    Please login with the new password.
                  </span>
                </div>

                <Button
                  variant={Button.Variant.Primary}
                  onClick={() => redirectWithoutRefresh("/login")}
                  className="w-100 btn-primary-disable"
                >
                  Login
                </Button>
              </div>
            ) : (
              <form onSubmit={onFormSubmit}>
                <Input
                  name="password"
                  label="Create Password"
                  placeholder="Enter your password here..."
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={onInputChange}
                  autoComplete="current-password"
                  icons={[
                    {
                      place: Input.IconPlace.Right,
                      identifier: showPassword ? "eye-alt" : "eye",
                      className: "pointer",
                      onClick: togglePasswordVisibility,
                    },
                  ]}
                  className="input-field"
                />
                <PasswordValidationChecklist password={password} />
                <div className="card-body__btn">
                  <Button
                    variant={Button.Variant.Primary}
                    type={Button.Type.Submit}
                    isLoading={getIsRequestPending(createPasswordRequestStatus)}
                    disabled={
                      getIsRequestPending(createPasswordRequestStatus) ||
                      !isPasswordValid(password)
                    }
                    className="w-100 btn-primary-disable"
                  >
                    Set Password
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <Loader />;
};

export default CreatePassword;
