import { combineReducers, Reducer } from "@reduxjs/toolkit";
import { reset } from "./actions";
import { loginReducer } from "../components/auth/components/login";
import { signupReducer } from "../components/auth/components/signup";
import { createPasswordReducer } from "../components/auth/components/create-password";
import { forgotPasswordReducer } from "../components/auth/components/forgot-password";
import { clientsReducer } from "../components/clients";
import { teamsReducer } from "../components/teams";
import { homeReducer } from "../components/home";
import { billingReducer } from "../components/billing";

const combinedReducer = combineReducers({
  login: loginReducer,
  signup: signupReducer,
  createPassword: createPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  home: homeReducer,
  clients: clientsReducer,
  teams: teamsReducer,
  billing: billingReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === reset.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
