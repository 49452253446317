import React from "react";
import {
  Avatar,
  ImageIcon,
  OverlayTooltip,
  Table,
  TruncatedTextWithoutTooltip,
} from "@saleshandy/designs";
import {
  Column,
  PaginationShowHide,
} from "@saleshandy/designs/lib/table/types";
import { IProps } from "./types";
import {
  Images,
  paginationOptions,
} from "../../../../shared/utils/app-constants";
import { AgencyUserStatus } from "../../../../shared/enum/agency-user-status";
import { AgencyRole } from "../../../../shared/enum/agency-role";
import AssignClientStatus from "../assign-client-modal/components/assign-client-status";
import { Order } from "../../../../shared/enum";
import { SortBy } from "../../../../shared/enum/team-sort-by";
import {
  isMemberStatusDisable,
  userHasDeleteAssignClientPermission,
} from "./utils/helper";

const TeamListTable: React.FC<IProps> = ({
  teamList,
  userId,
  role,
  sort,
  onPageChange,
  onSortHandler,
  showAssignClientModal,
  showDeleteTeamMemberModal,
  sendToggleUserStatusRequest,
}) => {
  const onAction = (key, row) => {
    switch (key) {
      case "assignClient":
        const agencyUserName = `${row.firstName} ${row.lastName}`;
        showAssignClientModal(
          row.id,
          row.role === AgencyRole.AgencyAdmin,
          agencyUserName
        );
        break;
      case "delete":
        showDeleteTeamMemberModal(row.id);
        break;
    }
  };

  const actions = [
    {
      key: "assignClient",
      icon: Images.UserCheck,
      displayName: "Assign Clients",
      position: "out",
    },
    {
      key: "delete",
      icon: Images.Trash,
      displayName: "Delete",
      position: "out",
    },
  ];

  const data = teamList.map((team) => ({
    ...team,
    userStatus: team.status === AgencyUserStatus.Active,
    memberName: `${team?.firstName} ${team?.lastName}`,
    email: team.email,
  }));

  const columns: Column[] = [
    {
      dataField: "userStatus",
      text: "Status",
      cellClasses: "left client-status",
      component: (cell, row) => (
        <AssignClientStatus
          status={cell}
          onChange={(e) => onStatusChange(e, row)}
          isDisabled={isMemberStatusDisable(row, role) || row.id === userId}
        />
      ),
    },
    {
      dataField: "memberName",
      text: "Member Name",
      headerAlign: "left",
      cellClasses: "left client-name",
      sort: true,
      onSort: (_field, order) => {
        return onSortHandler(SortBy.MemberName, order);
      },
      component: (cell, row) => {
        const isOwner = row.role === AgencyRole.AgencyOwner;
        const firstName = row?.firstName;
        const lastName = row?.lastName;
        const divClass =
          (!row.userStatus || row.status === AgencyUserStatus.Invited) &&
          "disable-action";
        const ownerClass = !isOwner && "hover-link";

        if (firstName) {
          return (
            <div
              role='button'
              className={`d-flex ${divClass}`}
              onClick={() => !isOwner && onAction("assignClient", row)}
            >
              <OverlayTooltip text={`${firstName} ${lastName || ""}`}>
                <div>
                  <Avatar
                    firstName={firstName}
                    lastName={lastName || ""}
                    className='avatar mr-10'
                  />
                  <TruncatedTextWithoutTooltip
                    string={cell}
                    length={30}
                    textClassName={`regular-2 ${ownerClass}`}
                  />
                </div>
              </OverlayTooltip>
            </div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerAlign: "left",
      cellClasses: "left",
      component: (cell, row) => {
        const isOwner = row.role === AgencyRole.AgencyOwner;
        const isAdmin = row.role === AgencyRole.AgencyAdmin;

        return (
          <div>
            <TruncatedTextWithoutTooltip
              string={cell}
              length={50}
              textClassName='mr-2'
            />
            {isOwner && <span className='regular-1  owner-tag'>Owner</span>}
            {isAdmin && <span className='regular-1  admin-tag'>Admin</span>}
          </div>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      cellClasses: "left action",
      component: (_cell, row) => {
        if (row.role === AgencyRole.AgencyOwner) {
          return;
        }

        return (
          <div className='d-flex'>
            {actions.map((action) => {
              const actionClass =
                !userHasDeleteAssignClientPermission(
                  action.key,
                  row.userStatus,
                  row.status,
                  role
                ) && "disable-action";

              return (
                <div
                  key={action.key}
                  role='button'
                  className={`mr-3 ${actionClass}`}
                  onClick={() => onAction(action.key, row)}
                >
                  <OverlayTooltip text={action.displayName}>
                    <ImageIcon src={action.icon} className='action-icon' />
                  </OverlayTooltip>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const onPaginationChangeHandler = ({ page, limit }) => {
    paginationOptions.options.page = page;
    paginationOptions.options.limit = limit;

    onPageChange(page);
  };

  const onStatusChange = (checked, row) => {
    sendToggleUserStatusRequest(checked, row.id);
  };

  return (
    <div className='clients__list-container'>
      <Table
        data={data}
        columns={columns}
        onAction={onAction}
        paginationOptions={{
          options: {
            ...paginationOptions.options,
            totalElements: teamList.length,
          },
        }}
        sort={{
          dataField:
            sort.sortBy === SortBy.MemberName ? "memberName" : sort.sortBy,
          order: sort.order === Order.Ascending ? "asc" : "desc",
        }}
        onPaginationOptionsChange={onPaginationChangeHandler}
        pagination={PaginationShowHide.SHOW}
        headerWrapperClasses='client-table-header'
        bodyWrapperClasses='client-body-wrapper'
      />
    </div>
  );
};

export default TeamListTable;
