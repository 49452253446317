import React from "react";
import { Button, ImageIcon, Modal } from "@saleshandy/designs";
import { IProps } from "./types";
import { Images } from "../../../../shared/utils/app-constants";

const DeleteClientModal: React.FC<IProps> = ({
  show,
  isRequestPending,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      show={show}
      titleContent={
        <div className='d-flex align-items-center'>
          <ImageIcon src={Images.TrashDanger} />
          <div className='ml-3 semibold-3'>Delete Client</div>
        </div>
      }
      showCloseIcon
      onClose={onClose}
      onHide={onClose}
      submitButtonText='Delete'
      submitButtonClassName='submit-btn'
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      isCancelDisabled={isRequestPending}
      cancelButtonVarient={Button.Variant.Outlined}
      onSubmit={onSubmit}
      className='delete-modal modal-no-header-border'
    >
      <div>
        <p>Are you sure you want to delete the selected client?</p>
        <p>
          All the active sequences will be paused. All the data will be erased
          and cannot be retrived!
        </p>
      </div>
    </Modal>
  );
};

export default DeleteClientModal;
