import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../../shared/enum/request-status";
import { RequestState } from "../../../../shared/types";
import {
  createPasswordRequest,
  verifyTokenRequest,
  verifyResetPasswordTokenRequest,
  resetPasswordRequest,
} from "./extra-action";

type State = {
  createPasswordRequest: RequestState;
  verifyTokenRequest: RequestState;
  verifyResetPasswordTokenRequest: RequestState;
  resetPasswordRequest: RequestState;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  agencyId: number;
  role: string;
  status: boolean;
  deletedAt: Date;
  createdAt: Date;
};

const initialState: State = {
  createPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyResetPasswordTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resetPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  agencyId: null,
  role: null,
  status: null,
  deletedAt: null,
  createdAt: null,
};

const createPasswordSlice = createSlice({
  name: "createPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create Password
    builder.addCase(createPasswordRequest.pending, (state) => {
      state.createPasswordRequest.status = RequestStatus.Pending;
    });
    builder.addCase(createPasswordRequest.fulfilled, (state, action) => {
      state.createPasswordRequest.status = RequestStatus.Succeeded;
      state.createPasswordRequest.message = action.payload.message;
      state.id = action.payload.payload;
      state.email = action.payload.payload;
      state.agencyId = action.payload.payload;
      state.role = action.payload.payload;
      state.status = action.payload.payload;
      state.deletedAt = action.payload.payload;
      state.createdAt = action.payload.payload;
    });
    builder.addCase(createPasswordRequest.rejected, (state, action) => {
      state.createPasswordRequest.status = RequestStatus.Failed;
      state.createPasswordRequest.error = action.payload;
    });

    // Reset Password
    builder.addCase(resetPasswordRequest.pending, (state) => {
      state.resetPasswordRequest.status = RequestStatus.Pending;
    });
    builder.addCase(resetPasswordRequest.fulfilled, (state, action) => {
      state.resetPasswordRequest.status = RequestStatus.Succeeded;
      state.resetPasswordRequest.message = action.payload.message;
      state.resetPasswordRequest.error = null;
    });
    builder.addCase(resetPasswordRequest.rejected, (state, action) => {
      state.resetPasswordRequest.status = RequestStatus.Failed;
      state.resetPasswordRequest.error = action.payload;
    });

    // Verify Token
    builder.addCase(verifyTokenRequest.pending, (state) => {
      state.verifyTokenRequest.status = RequestStatus.Pending;
      state.verifyTokenRequest.message = null;
      state.verifyTokenRequest.error = null;
    });
    builder.addCase(verifyTokenRequest.fulfilled, (state, action) => {
      state.verifyTokenRequest.status = RequestStatus.Succeeded;
      state.verifyTokenRequest.message = action.payload.message;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
    });
    builder.addCase(verifyTokenRequest.rejected, (state, action) => {
      state.verifyTokenRequest.status = RequestStatus.Failed;
      state.verifyTokenRequest.message = action.payload.message;
      state.verifyTokenRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Verify Reset Password Token
    builder.addCase(verifyResetPasswordTokenRequest.pending, (state) => {
      state.verifyResetPasswordTokenRequest.status = RequestStatus.Pending;
      state.verifyResetPasswordTokenRequest.message = null;
      state.verifyResetPasswordTokenRequest.error = null;
    });
    builder.addCase(
      verifyResetPasswordTokenRequest.fulfilled,
      (state, action) => {
        state.verifyResetPasswordTokenRequest.status = RequestStatus.Succeeded;
        state.verifyResetPasswordTokenRequest.message = action.payload.message;
      }
    );
    builder.addCase(
      verifyResetPasswordTokenRequest.rejected,
      (state, action) => {
        state.verifyResetPasswordTokenRequest.status = RequestStatus.Failed;
        state.verifyResetPasswordTokenRequest.message = action.payload.message;
        state.verifyResetPasswordTokenRequest.error =
          !action.payload.isHandled && action.payload;
      }
    );
  },
});

export { createPasswordRequest, verifyTokenRequest, resetPasswordRequest };
export default createPasswordSlice.reducer;
