import React from "react";
import { Router } from "react-router-dom";
import { Provider, ProviderProps } from "react-redux";
import { RouterProps } from "react-router";
import { ToastManager } from "@saleshandy/designs";
import toaster from "@saleshandy/designs/lib/toaster";
import App from "./components/app/app";
import HelpscoutButton from "./shared/components/helpscout-button";

type RootProps = RouterProps & ProviderProps;

const Root: React.FC<RootProps> = ({ store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <ToastManager bindActions={toaster.bindActions} />
      <App />
      <HelpscoutButton />
    </Router>
  </Provider>
);

export default Root;
