import React from "react";
import { Modal } from "@saleshandy/designs";

type IProps = {
  show: boolean;
  firstName: string;
  onClose: VoidFunction;
};

const WatchVideoModal: React.FC<IProps> = ({ show, firstName, onClose }) => {
  const generateWelcomeText = () => {
    if (firstName && firstName.length > 15) {
      return `Hi ${firstName.substring(
        0,
        15
      )}...👋, Welcome to Saleshandy Agency Portal`;
    }
    return `Hi ${firstName}👋, Welcome to Saleshandy Agency Portal`;
  };

  return (
    <Modal
      show={show}
      className="watch-video-modal"
      titleContent={generateWelcomeText()}
      showCloseIcon={false}
      hideSubmitButton
      cancelButtonText="Close"
      onCancel={onClose}
      backdrop="static"
    >
      <div className="watch-video-modal-body">
        <p className="watch-video-modal-text">
          Before you get started, we recommend watching this video to learn how
          to set up and run your agency portal.
        </p>

        <div className="d-flex justify-content-center align-items-center">
          <iframe
            width="560"
            height="315"
            frameBorder={0}
            src="https://www.youtube.com/embed/pOvzJp84QI0?si=Peyhvrzon2e6ad2o"
            title="Quick Guide to Saleshandy Agency Portal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
};

export default WatchVideoModal;
