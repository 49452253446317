import React, { useState, useEffect } from "react";
import { Select } from "@saleshandy/designs";
import { IProps } from "./types";
import { RoleOption } from "../client-list-table/types";
import { roleOptions } from "../../../../utils/helper";
import { AgencyRole } from "../../../../../../shared/enum";

const SelectRole: React.FC<IProps> = ({
  selectedRole,
  onRoleChange,
  isDisabled,
}) => {
  const [selectRole, setSelectRole] = useState<RoleOption>(null);

  useEffect(() => {
    if (selectedRole && selectedRole !== AgencyRole.AgencyAdmin) {
      const getRole = roleOptions.find(({ key }) => key === selectedRole);
      setSelectRole({ key: selectedRole, role: getRole.role });
    }
  }, []);

  return (
    <Select<RoleOption>
      selectedOptionKey={selectRole?.key}
      options={roleOptions}
      optionRenderer={(option) => <span>{option.role}</span>}
      showOptionsSeparator={true}
      onSelect={(option) => {
        setSelectRole(option);
      }}
      onChange={([{ key }]) => {
        onRoleChange(key);
      }}
      selectedOptionRenderer={([option]) => <span>{option?.role}</span>}
      placeholder={"Select"}
      disabled={isDisabled}
    />
  );
};

export default SelectRole;
