import validator from "validator";
import passwordValidator from "../../../../shared/utils/password-validator";

const nameValidator = (value: string, key: string): string => {
  if (validator.isEmpty(value)) {
    return `${key} name is required.`;
  }
  if (!validator.isAlpha(value)) {
    return `Alphabetic characters only.`;
  }
  if (!validator.isLength(value, { min: 2, max: 25 })) {
    return `Should be between 2-25 characters.`;
  }
  return "";
};

const trimDialCode = (phoneNumber: string, dialCode: string): string => {
  const dialCodeLength = dialCode.toString().length;
  return phoneNumber.toString().substring(dialCodeLength);
};

export const validate = (
  name: string,
  value: string,
  dialCode: string
): string => {
  switch (name) {
    case "firstName":
      return nameValidator(value, "First");

    case "lastName":
      return nameValidator(value, "Last");

    case "email":
      if (!validator.isEmail(value)) {
        return "Please enter a valid email address.";
      }
      break;

    case "phone":
      if (validator.isEmpty(value)) {
        return "Phone number is required.";
      }

      if (
        !validator.isLength(trimDialCode(value, dialCode), { min: 7, max: 15 })
      ) {
        return "Should be between 7-15 numbers.";
      }
      break;

    case "password":
      if (!passwordValidator.all(value)) {
        return "Password must be atleast 8 characters with a mix of letters & numbers.";
      }
      break;
    default:
      break;
  }

  return "";
};
