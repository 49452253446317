import { RequestStatus } from "../enum";

export const getIsRequestIdeal = (status: RequestStatus): boolean =>
  status === RequestStatus.Ideal;

export const getIsRequestPending = (status: RequestStatus): boolean =>
  status === RequestStatus.Pending;

export const getIsRequestSucceeded = (status: RequestStatus): boolean =>
  status === RequestStatus.Succeeded;

export const getIsRequestFailed = (status: RequestStatus): boolean =>
  status === RequestStatus.Failed;
