import axios from "axios";
import api from "../../../api";
import { getToken } from "../../../shared/utils";
import { ResponseSuccess } from "../../../shared/types";
import { PurchaseEVCreditsRequest } from "../types";

export const fetchEvCredits = async () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/agency/ev-credits`;
  const authToken = getToken();

  if (authToken) {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  }
  return null;
};

export const fetchAccountPlanUsage = async () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/agency/feature-limitations`;
  const authToken = getToken();

  if (authToken) {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  }
  return null;
};

export const getEVCredits = async () =>
  api.get<ResponseSuccess>("/agency/ev-credits");

export const getAccountPlanUsage = async () =>
  api.get<ResponseSuccess>("/agency/feature-limitations");

export const getEVCreditsPlan = () =>
  api.get<ResponseSuccess>("/email-verification-plans");

export const purchaseEVCredits = (payload: PurchaseEVCreditsRequest) =>
  api.post<ResponseSuccess>(
    "/agency/email-verification-credits/purchase",
    payload
  );
