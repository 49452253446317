import React from "react";

type IProps = {
  label: string;
  value: string;
};

const SubscriptionDetail: React.FC<IProps> = ({ label = "", value = "" }) => (
  <div className="subscription-detail">
    <p>{label}</p>
    <h2>{value}</h2>
  </div>
);

export default SubscriptionDetail;
