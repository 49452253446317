import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/root-reducer";

import {
  getEVCreditsPlanRequest,
  purchaseEVCreditsRequest,
} from "../../extra-actions";

import EVCreditsDetails from "./ev-credits-details";
import {
  resetGetEVPlanRequest,
  resetPurchaseEVCreditsRequest,
  resetPurchaseEVCreditsResponse,
} from "../../billing-slice";

import { PurchaseEVCreditsRequest } from "../../types";

const mapStateToProps = (state: RootState) => ({
  getEVCreditsRequestStatus: state.billing.getEVCreditsRequest.status,

  getEVCreditsPlanRequestStatus: state.billing.getEVCreditsPlanRequest.status,
  getEVCreditsPlanRequestError: state.billing.getEVCreditsPlanRequest.error,

  purchaseEVCreditsRequestStatus: state.billing.purchaseEVCreditsRequest.status,
  purchaseEVCreditsRequestError: state.billing.purchaseEVCreditsRequest.error,

  evCreditsPlans: state.billing.evCreditsPlans,
  purchaseEVCreditsResponse: state.billing.purchaseEVCreditsResponse,

  role: state.home.role,
});

const mapDispatchToProps = {
  sendGetEVCreditsPlanRequest: () => getEVCreditsPlanRequest(),
  resetGetEVPlanRequest: () => resetGetEVPlanRequest(),

  sendPurchaseEVCreditsRequest: (payload: PurchaseEVCreditsRequest) =>
    purchaseEVCreditsRequest(payload),
  resetPurchaseEVCreditsRequest: () => resetPurchaseEVCreditsRequest(),

  resetPurchaseEVCreditsResponse: () => resetPurchaseEVCreditsResponse(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  availableCredits: number;
};

export default connector(EVCreditsDetails);
