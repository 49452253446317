import validator from "validator";
import { AgencyRole } from "../../../shared/enum";
import { RoleOption } from "../components/assign-client-modal/components/client-list-table/types";

export const roleOptions: RoleOption[] = [
  { key: AgencyRole.AgencyTeamLeader, role: "Team Leader" },
  { key: AgencyRole.AgencyUser, role: "User" },
];

export const isValidEmail = (email: string): boolean =>
  validator.isEmail(email);

export const isInviteMemberBtnDisable = (
  isRequestPending: boolean,
  firstName: string,
  lastName: string,
  email: string
): boolean =>
  isRequestPending || validator.isEmpty(firstName) || validator.isEmpty(email);
