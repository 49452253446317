import { connect, ConnectedProps } from "react-redux";

import { RootState } from "../../../../store/root-reducer";

import Subscription from "./subscription";

const mapStateToProps = (state: RootState) => ({
  email: state.home.email,
  userId: state.home.userId,
  planName: state.home.subscription?.planName,
  planCode: state.home.subscription?.planCode,
  startAt: state.home.subscription?.startAt,
  nextBillingAt: state.home.subscription?.nextBillingAt,
  planAmount: state.home.subscription?.planAmount,
  stripeCredits: state.home.subscription?.stripeCredits,
  status: state.home.subscription?.status,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Subscription);
