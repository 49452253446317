import { createSlice } from "@reduxjs/toolkit";
import {
  AgencyRole,
  AgencyUserOnboardingSteps,
  AgencyUserSettingsCode,
} from "../../shared/enum";
import { RequestStatus } from "../../shared/enum/request-status";
import { RequestState } from "../../shared/types";
import {
  getUserSettingsRequest,
  updateOnboardingRequest,
  completeOnboardingRequest,
} from "./extra-actions";

type State = {
  getUserSettingsRequest: RequestState;
  updateOnboardingRequest: RequestState;
  completeOnboardingRequest: RequestState;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  role: AgencyRole;
  userId: number;
  agencyLogo: {
    secondary: string;
    primary: string;
    tertiary: string;
  };
  agencyUserProfileProgress: {
    agencyUserId: number;
    code: AgencyUserSettingsCode;
    id: number;
    value: AgencyUserOnboardingSteps;
  }[];
  agency: {
    id: number;
    name: string;
    portalBaseUrl: string;
    isWhiteLabeled: boolean;
    agencyCode: string;
    createdAt: Date;
    deletedAt: Date;
  };
  createdAt: Date;
  subscription: Record<string, any>;
  adminDetails: {
    firstName: string;
    email: string;
  };
};

const initialState: State = {
  getUserSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateOnboardingRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  completeOnboardingRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  agencyUserProfileProgress: null,
  firstName: null,
  lastName: null,
  email: null,
  verified: true,
  role: null,
  userId: null,
  agencyLogo: null,
  createdAt: null,
  agency: null,
  subscription: null,
  adminDetails: {
    firstName: "",
    email: "",
  },
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetUpdateOnboardingRequest: (state) => {
      state.updateOnboardingRequest = initialState.updateOnboardingRequest;
    },
  },
  extraReducers: (builder) => {
    // Get User Settings
    builder.addCase(getUserSettingsRequest.pending, (state) => {
      state.getUserSettingsRequest.status = RequestStatus.Pending;
      state.getUserSettingsRequest.message = null;
      state.getUserSettingsRequest.error = null;
    });
    builder.addCase(getUserSettingsRequest.fulfilled, (state, action) => {
      state.getUserSettingsRequest.status = RequestStatus.Succeeded;
      state.getUserSettingsRequest.message = action.payload.message;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
      state.verified = action.payload.payload.verified;
      state.role = action.payload.payload.role;
      state.userId = action.payload.payload.id;
      state.agencyLogo = action.payload.payload.agencyConfig.logo;
      state.createdAt = action.payload.payload.createdAt;
      state.agency = action.payload.payload.agency;
      state.agencyUserProfileProgress =
        action.payload.payload.agencyUserProfileProgress;

      state.subscription = action.payload.payload.accountSubscription;
      state.adminDetails = action.payload.payload.adminDetails;

      if (state.subscription) {
        state.subscription.nextBillingAt =
          state.subscription?.nextBillingAt?.replace(/-/g, "/");
        state.subscription.startAt = state.subscription?.startAt?.replace(
          /-/g,
          "/"
        );
      }
    });
    builder.addCase(getUserSettingsRequest.rejected, (state, action) => {
      state.getUserSettingsRequest.status = RequestStatus.Failed;
      state.getUserSettingsRequest.message = action.payload.message;
      state.getUserSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Onboarding
    builder.addCase(updateOnboardingRequest.pending, (state) => {
      state.updateOnboardingRequest.status = RequestStatus.Pending;
      state.updateOnboardingRequest.message = null;
      state.updateOnboardingRequest.error = null;
    });
    builder.addCase(updateOnboardingRequest.fulfilled, (state, action) => {
      state.updateOnboardingRequest.status = RequestStatus.Succeeded;
      state.updateOnboardingRequest.message = action.payload.message;
    });
    builder.addCase(updateOnboardingRequest.rejected, (state, action) => {
      state.updateOnboardingRequest.status = RequestStatus.Failed;
      state.updateOnboardingRequest.message = action.payload.message;
      state.updateOnboardingRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Complete Onboarding
    builder.addCase(completeOnboardingRequest.pending, (state) => {
      state.completeOnboardingRequest.status = RequestStatus.Pending;
      state.completeOnboardingRequest.message = null;
      state.completeOnboardingRequest.error = null;
    });
    builder.addCase(completeOnboardingRequest.fulfilled, (state, action) => {
      state.completeOnboardingRequest.status = RequestStatus.Succeeded;
      state.completeOnboardingRequest.message = action.payload.message;
    });
    builder.addCase(completeOnboardingRequest.rejected, (state, action) => {
      state.completeOnboardingRequest.status = RequestStatus.Failed;
      state.completeOnboardingRequest.message = action.payload.message;
      state.completeOnboardingRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const { resetUpdateOnboardingRequest } = homeSlice.actions;
export default homeSlice.reducer;
