import React, { useState, useEffect } from "react";
import { Button, ImageIcon, Input } from "@saleshandy/designs";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import { Link } from "react-router-dom";
import validator from "validator";
import { IProps } from "./forgot-password-container";
import { Images } from "../../../../shared/utils/app-constants";
import { executeOnRequestStatus } from "../../../../shared/utils/execute-on-request-status";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";
import routes from "../../../../routes";
import { redirectWithoutRefresh } from "../../../../shared/utils";

const ForgotPassword: React.FC<IProps> = ({
  forgotPasswordRequestStatus,
  forgotPasswordRequestMessage,
  sendForgotPasswordRequest,
  resetForgotPasswordState,
}) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isPasswordResetSuccessfully, setIsPasswordResetSuccessfully] =
    useState<boolean>(false);

  const redirectToLoginPage = () => {
    setIsPasswordResetSuccessfully(false);
    redirectWithoutRefresh(routes.LOGIN);
  };

  const validateEmail = () => {
    if (!validator.isEmail(email)) {
      return "Enter a valid email address";
    }
    return "";
  };

  const onInputBlur = () => {
    setError(validateEmail());
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    sendForgotPasswordRequest({
      email: email,
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: forgotPasswordRequestStatus,
      onSuccess: () => {
        setIsPasswordResetSuccessfully(true);
      },
      onFailed: () => {
        toaster.error(forgotPasswordRequestMessage);
      },
    });
  }, [forgotPasswordRequestStatus]);

  useEffect(() => {
    return () => {
      resetForgotPasswordState();
    };
  }, []);

  return (
    <div className='auth__container'>
      <div className='card'>
        {!isPasswordResetSuccessfully && (
          <div className='logo--section'>
            <ImageIcon src={Images.SaleshandyLogo} />
          </div>
        )}
        <div className='card-body'>
          {!isPasswordResetSuccessfully && (
            <div className='card-body__title d-flex flex-column'>
              <span className='semibold-4 blue-txt-15'>Reset Password</span>
              <span className='regular-1 gray-txt-15 mt-2'>
                We’ll email you instructions to reset your password.
              </span>
            </div>
          )}
          {isPasswordResetSuccessfully ? (
            <div className='password-set-success__container'>
              <ImageIcon src={Images.Checks} className='mt-1' />
              <div className='d-flex flex-column'>
                <span className='semibold-4 blue-txt-15 mt-3'>
                  Password reset request sent successfully
                </span>
                <span className='regular-1 sub-text'>
                  If the email does not arrive soon, check your spam folder. It
                  was sent from support@saleshandy.com.
                </span>
              </div>

              <Button
                variant={Button.Variant.Primary}
                onClick={redirectToLoginPage}
                className='w-100 btn-primary-disable'
              >
                Return to login
              </Button>
            </div>
          ) : (
            <form onSubmit={onFormSubmit}>
              <Input
                name='email'
                label='Email'
                placeholder='Enter your email address...'
                value={email}
                variant={error && Input.Variant.Error}
                onChange={(e) => setEmail(e)}
                onBlur={onInputBlur}
                caption={error}
                autoComplete='current-email'
                className='input-field input-with-label'
              />

              <div className='card-body__btn'>
                <Button
                  variant={Button.Variant.Primary}
                  type={Button.Type.Submit}
                  isLoading={getIsRequestPending(forgotPasswordRequestStatus)}
                  disabled={
                    !validator.isEmail(email) ||
                    getIsRequestPending(forgotPasswordRequestStatus)
                  }
                  className='w-100 btn-primary-disable'
                >
                  Reset Password
                </Button>
              </div>
            </form>
          )}
          {!isPasswordResetSuccessfully && (
            <div className='w-100 text-center mt-3 pb-2'>
              <Link to={routes.LOGIN} className='regular-2 pointer'>
                Back to Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
