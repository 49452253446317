import React from "react";
import { Switch } from "react-router-dom";
import ConfigRoute from "../../shared/components/config-route";
import ProtectedRoute from "../../shared/components/protected-route";
import routes from "../../routes";
import Auth from "../auth";
import Home from "../home";
import VerifyEmailCallback from "../auth/components/verify-email-callback";

const App: React.FC = () => (
  <>

  
    <Switch>
      <ConfigRoute
        title="Login"
        type={ConfigRoute.RouteType.Auth}
        exact
        path={routes.LOGIN}
        component={Auth}
      />
      <ConfigRoute
        title="Sign up"
        type={ConfigRoute.RouteType.Auth}
        exact
        path={routes.SIGNUP}
        component={Auth}
      />
      <ConfigRoute
        title="Create Password"
        type={ConfigRoute.RouteType.Auth}
        exact
        path={routes.CREATE_PASSWORD}
        component={Auth}
      />
      <ConfigRoute
        title="Reset Password"
        type={ConfigRoute.RouteType.Auth}
        exact
        path={routes.RESET_PASSWORD}
        component={Auth}
      />
      <ConfigRoute
        title="Verify Email"
        exact
        path={routes.VERIFY_EMAIL}
        component={VerifyEmailCallback}
      />
      <ProtectedRoute exact path={routes.VERIFY_EMAIL} component={Home} />
      <ProtectedRoute exact path={routes.CLIENTS} component={Home} />
      <ProtectedRoute exact path={routes.TEAM} component={Home} />
      <ProtectedRoute exact path={routes.BILLING} component={Home} />
      <ProtectedRoute exact path={routes.BILLING_USAGE} component={Home} />
      <ProtectedRoute
        exact
        path={routes.BILLING_SUBSCRIPTION}
        component={Home}
      />
      <ProtectedRoute exact path="/" component={Home} />
    </Switch>
  </>
);

export default App;
