import React from "react";
import { ImageIcon } from "@saleshandy/designs";
import { Images } from "../../utils/app-constants";

const Loader: React.FC = () => {
  return (
    <div className='loader d-flex justify-content-center align-items-center'>
      <ImageIcon src={Images.Loader} className='loading-spinner' />
    </div>
  );
};

export default Loader;
