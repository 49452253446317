import React, { useEffect } from "react";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import ProtectedRoute from "../protected-route";

export enum RouteType {
  Auth = "auth",
  Protected = "protected",
  Default = "default",
}

type ConfigRouteProps = Omit<RouteProps, "render"> & {
  title?: string;
  type?: RouteType;
  showBrandingSuffix?: boolean;
};

type ConfigRouteStatic = {
  RouteType: typeof RouteType;
};

const ConfigRoute: React.FC<ConfigRouteProps> & ConfigRouteStatic = ({
  title,
  type,
  showBrandingSuffix,
  ...rest
}) => {
  const getNewTitle = (oldTitle: string, addSuffix: boolean): string => {
    let newTitle = oldTitle;
    if (addSuffix) {
      newTitle = `${oldTitle} - Saleshandy`;
    }
    return newTitle;
  };

  useEffect(() => {
    if (title || title === "") {
      document.title = getNewTitle(title, showBrandingSuffix);
    }
  }, [title, showBrandingSuffix]);

  let RouteComponent;

  switch (type) {
    // case RouteType.Auth:
    //   RouteComponent = AuthRoute;
    //   break;
    case RouteType.Protected:
      RouteComponent = ProtectedRoute;
      break;
    case RouteType.Default:
    default:
      RouteComponent = Route;
      break;
  }

  return <RouteComponent {...rest} />;
};

ConfigRoute.defaultProps = {
  type: RouteType.Default,
  showBrandingSuffix: true,
};

ConfigRoute.RouteType = RouteType;

export default ConfigRoute;
