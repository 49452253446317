import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../shared/enum/request-status";
import { RequestState } from "../../shared/types";
import {
  getEVCreditsRequest,
  getAccountPlanUsageRequest,
  getEVCreditsPlanRequest,
  purchaseEVCreditsRequest,
} from "./extra-actions";
import {
  AccountPlanUsage,
  EVCreditsPlan,
  PurchaseEVCreditsResponse,
} from "./types";

type State = {
  getEVCreditsRequest: RequestState;
  getAccountPlanUsageRequest: RequestState;
  getEVCreditsPlanRequest: RequestState;
  purchaseEVCreditsRequest: RequestState;
  evCredits: number;
  accountPlanUsage: AccountPlanUsage;
  evCreditsPlans: EVCreditsPlan[];
  purchaseEVCreditsResponse: PurchaseEVCreditsResponse;
};

const initialState: State = {
  getEVCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAccountPlanUsageRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEVCreditsPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseEVCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  evCredits: 0,
  accountPlanUsage: null,
  evCreditsPlans: [],
  purchaseEVCreditsResponse: null,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetGetEVCreditsRequest: (state) => {
      state.getEVCreditsRequest = initialState.getEVCreditsRequest;
    },
    resetGetAccountPlanUsageRequest: (state) => {
      state.getAccountPlanUsageRequest =
        initialState.getAccountPlanUsageRequest;
    },
    resetGetEVPlanRequest: (state) => {
      state.getEVCreditsPlanRequest = initialState.getEVCreditsPlanRequest;
    },
    resetPurchaseEVCreditsRequest: (state) => {
      state.purchaseEVCreditsRequest = initialState.purchaseEVCreditsRequest;
    },
    resetPurchaseEVCreditsResponse: (state) => {
      state.purchaseEVCreditsResponse = initialState.purchaseEVCreditsResponse;
    },
  },
  extraReducers: (builder) => {
    // Get EV Credits
    builder.addCase(getEVCreditsRequest.pending, (state) => {
      state.getEVCreditsRequest.status = RequestStatus.Pending;
      state.getEVCreditsRequest.message = null;
      state.getEVCreditsRequest.error = null;
    });
    builder.addCase(getEVCreditsRequest.fulfilled, (state, action) => {
      state.getEVCreditsRequest.status = RequestStatus.Succeeded;
      state.getEVCreditsRequest.message = action.payload.message;
      state.evCredits = action.payload.payload;
    });
    builder.addCase(getEVCreditsRequest.rejected, (state, action) => {
      state.getEVCreditsRequest.status = RequestStatus.Failed;
      state.getEVCreditsRequest.message = action.payload.message;
      state.getEVCreditsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Account Usage
    builder.addCase(getAccountPlanUsageRequest.pending, (state) => {
      state.getAccountPlanUsageRequest.status = RequestStatus.Pending;
      state.getAccountPlanUsageRequest.message = null;
      state.getAccountPlanUsageRequest.error = null;
    });
    builder.addCase(getAccountPlanUsageRequest.fulfilled, (state, action) => {
      state.getAccountPlanUsageRequest.status = RequestStatus.Succeeded;
      state.getAccountPlanUsageRequest.message = action.payload.message;
      state.accountPlanUsage = action.payload.payload;
    });
    builder.addCase(getAccountPlanUsageRequest.rejected, (state, action) => {
      state.getAccountPlanUsageRequest.status = RequestStatus.Failed;
      state.getAccountPlanUsageRequest.message = action.payload.message;
      state.getAccountPlanUsageRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get EV Credits Plans
    builder.addCase(getEVCreditsPlanRequest.pending, (state) => {
      state.getEVCreditsPlanRequest.status = RequestStatus.Pending;
      state.getEVCreditsPlanRequest.message = null;
      state.getEVCreditsPlanRequest.error = null;
    });
    builder.addCase(getEVCreditsPlanRequest.fulfilled, (state, action) => {
      state.getEVCreditsPlanRequest.status = RequestStatus.Succeeded;
      state.getEVCreditsPlanRequest.message = action.payload.message;
      state.evCreditsPlans = action.payload.payload.rows;
    });
    builder.addCase(getEVCreditsPlanRequest.rejected, (state, action) => {
      state.getEVCreditsPlanRequest.status = RequestStatus.Failed;
      state.getEVCreditsPlanRequest.message = action.payload.message;
      state.getEVCreditsPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Purchase EV Credits
    builder.addCase(purchaseEVCreditsRequest.pending, (state) => {
      state.purchaseEVCreditsRequest.status = RequestStatus.Pending;
      state.purchaseEVCreditsRequest.message = null;
      state.purchaseEVCreditsRequest.error = null;
    });
    builder.addCase(purchaseEVCreditsRequest.fulfilled, (state, action) => {
      state.purchaseEVCreditsRequest.status = RequestStatus.Succeeded;
      state.purchaseEVCreditsRequest.message = action.payload.message;
      state.purchaseEVCreditsResponse = action.payload.payload;
    });
    builder.addCase(purchaseEVCreditsRequest.rejected, (state, action) => {
      state.purchaseEVCreditsRequest.status = RequestStatus.Failed;
      state.purchaseEVCreditsRequest.message = action.payload.message;
      state.purchaseEVCreditsRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetGetEVCreditsRequest,
  resetGetAccountPlanUsageRequest,
  resetGetEVPlanRequest,
  resetPurchaseEVCreditsRequest,
  resetPurchaseEVCreditsResponse,
} = billingSlice.actions;
export default billingSlice.reducer;
