import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import Billing from "./billing";

const mapStateToProps = (state: RootState) => ({
  role: state.home.role,
  planAmount: state.home.subscription?.planAmount,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Billing);
