import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RouteComponentProps } from 'react-router';
import { getUserSettingsRequest } from '../../../home/extra-actions';
import VerifyEmailCallback from './verify-email-callback';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(VerifyEmailCallback);
