import React from "react";
import {
  ImageIcon,
  OverlayTooltip,
  Table,
  TruncatedTextWithTooltip,
} from "@saleshandy/designs";
import {
  Column,
  PaginationShowHide,
} from "@saleshandy/designs/lib/table/types";
import { IProps } from "./types";
import AvatarRow from "../avatar-row/avatar-row";
import {
  Images,
  paginationOptions,
} from "../../../../shared/utils/app-constants";
import { Client } from "../../types/response/get-client";
import { Order, SortBy } from "../../../../shared/enum";
import { userHasEditDeletePermission } from "./utils/helper";
import AssignClientStatus from "../../../teams/components/assign-client-modal/components/assign-client-status/assign-client-status";
import { isMemberStatusDisable } from "../../../teams/components/team-list-table/utils/helper";
import { accessibleOnClick } from "../../../../shared/utils/accessible-on-click";
import Loader from "../../../../shared/components/loader";

const ClientsListTable: React.FC<IProps> = ({
  clients,
  sendToggleClientRequest,
  onEditAction,
  onDeleteAction,
  onOneClickLoginAction,
  clientsCount,
  onPageChange,
  sort,
  onSortHandler,
  role,
  currentPage,
  isLoading,
}) => {
  const onAction = (key, row) => {
    switch (key) {
      case "edit":
        onEditAction(row.clientName, row.clientId);
        break;
      case "delete":
        onDeleteAction(row.clientId);
        break;
      default:
        break;
    }
  };

  const onStatusChange = (checked: boolean, row: Client) => {
    sendToggleClientRequest(checked, +row.clientId);
  };

  const actions = [
    {
      key: "edit",
      icon: Images.Pencil,
      displayName: "Edit",
      position: "out",
    },
    {
      key: "delete",
      icon: Images.Trash,
      displayName: "Delete",
      position: "out",
    },
  ];

  const data = clients.map((client) => ({
    ...client,
    clientStatus: client.clientStatus === 1,
    clientName: client.clientName,
    activeSequences: client.activeSequences,
    activeEmailAccount: client.activeEmails,
    managedBy: client.managedBy,
  }));

  const columns: Column[] = [
    {
      dataField: "clientStatus",
      text: "Status",
      cellClasses: "left, client-status",
      component: (cell, row) => (
        <AssignClientStatus
          status={cell}
          onChange={(e) => onStatusChange(e, row)}
          isDisabled={isMemberStatusDisable(row, role)}
        />
      ),
    },
    {
      dataField: "clientName",
      text: "Client Name",
      headerAlign: "right",
      cellClasses: "left client-name",
      sort: true,
      onSort: (_field, order) => onSortHandler(SortBy.ClientName, order),
      component: (cell, row) => {
        const spanClass = row.clientStatus ? "active-client" : "disable-client";

        return (
          <div
            className="d-flex"
            role="button"
            {...accessibleOnClick(() => onOneClickLoginAction(row.clientId))}
          >
            <div>
              <TruncatedTextWithTooltip
                string={cell}
                length={30}
                textClassName={`semibold-2 ${spanClass}`}
              />
            </div>
            {row.clientStatus && (
              <OverlayTooltip text="Login to client's account">
                <div className="ml-3">
                  <ImageIcon src={Images.Login} className="login-icon" />
                </div>
              </OverlayTooltip>
            )}
          </div>
        );
      },
    },
    {
      dataField: "activeSequences",
      text: "Active Sequence",
      headerAlign: "left",
      cellClasses: "left active-sequence",
      component: (cell, row) => {
        const spanClass = row.clientStatus ? "active-client" : "disable-client";

        return <span className={spanClass}>{cell}</span>;
      },
    },
    {
      dataField: "activeEmailAccount",
      text: "Active Email Account",
      headerAlign: "left",
      cellClasses: "left active-email-account",
      component: (cell, row) => {
        const spanClass = row.clientStatus ? "active-client" : "disable-client";

        return <span className={spanClass}>{cell}</span>;
      },
    },
    {
      dataField: "managedBy",
      text: "Managed By",
      headerAlign: "left",
      cellClasses: "left client-managed-by",
      component: (cell) => {
        if (cell) {
          const managedByArray = cell.split(",");

          const shuffledArray = managedByArray
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);

          return <AvatarRow avatars={shuffledArray} />;
        }

        return <span>-</span>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      cellClasses: "left action",
      component: (_cell, row) => (
        <div className="d-flex">
          {actions.map((action) => {
            const disableClass =
              !userHasEditDeletePermission(role) && "disable-action";
            return (
              <div
                key={action.key}
                className={`mr-3 ${disableClass}`}
                {...accessibleOnClick(() => onAction(action.key, row))}
              >
                <OverlayTooltip text={action.displayName}>
                  <ImageIcon src={action.icon} className="action-icon" />
                </OverlayTooltip>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  const onPaginationChangeHandler = ({ page }) => {
    onPageChange(page);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="clients__list-container">
      <Table
        data={data}
        columns={columns}
        onAction={onAction}
        onChange={(checked, _event, _cell, row) => onStatusChange(checked, row)}
        paginationOptions={{
          options: {
            ...paginationOptions.options,
            page: currentPage,
            totalElements: clientsCount,
          },
        }}
        sort={{
          dataField:
            sort.sortBy === SortBy.ClientName ? "clientName" : sort.sortBy,
          order: sort.order === Order.Ascending ? "asc" : "desc",
        }}
        onPaginationOptionsChange={onPaginationChangeHandler}
        pagination={PaginationShowHide.SHOW}
        headerWrapperClasses="client-table-header"
        bodyWrapperClasses="client-body-wrapper"
      />
    </div>
  );
};

export default ClientsListTable;
