import React from "react";
import SignupForm from "../signup-form";
import type { IProps } from "./signup-container";

const Signup: React.FC<IProps> = () => (
  <>
    <SignupForm />
  </>
);

export default Signup;
