import React, { useState, useEffect } from "react";
import { Table } from "@saleshandy/designs";
import {
  Column,
  PaginationShowHide,
} from "@saleshandy/designs/lib/table/types";
import { IProps } from "./types";
import { paginationOptions } from "../../../../../../shared/utils/app-constants";
import SelectRole from "../select-role/select-role";
import AssignClientStatus from "../assign-client-status";
import { generateRandomString } from "../../../../../../shared/utils/string";

const ClientListTable: React.FC<IProps> = ({
  clients,
  clientsCount,
  isAdminAccess,
  isSelfView,
  handleRoleChange,
  handleStatusChange,
  onPageChange,
  currentPage,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [tableKey, setTableKey] = useState<string>(generateRandomString(4));

  const handleStatusChangeFn = (checked, row) => {
    const tempData = [...data];
    const index = tempData.findIndex(
      (object) => object.clientId === row.clientId
    );
    tempData[index].status = checked;
    setData(tempData);

    handleStatusChange(row.clientId, checked, row.assignedRole, row.isAssigned);

    if (!checked) {
      handleRoleChange(row.clientId, checked, row?.assignedRole);
    }
  };

  const columns: Column[] = [
    {
      dataField: "status",
      text: "Status",
      headerAlign: "left",
      cellClasses: "client-status",
      component: (cell, row) => (
        <AssignClientStatus
          status={cell || isAdminAccess || isSelfView}
          onChange={(checked) => handleStatusChangeFn(checked, row)}
          isDisabled={isAdminAccess || isSelfView}
        />
      ),
    },
    {
      dataField: "clientName",
      text: "Client Name",
      headerAlign: "left",
      cellClasses: "left client-name",
      component: (cell) => (
        <span className={`semibold-2 mr-2 ${cell}`}>{cell}</span>
      ),
    },
    {
      dataField: "status",
      text: "Role",
      cellClasses: "left select-role",
      component: (cell, row) => (
        <SelectRole
          selectedRole={row?.assignedRole}
          onRoleChange={(role) => handleRoleChange(row.clientId, cell, role)}
          isDisabled={!cell || isAdminAccess}
        />
      ),
    },
  ];

  const onPaginationChangeHandler = ({ page }) => {
    onPageChange(page);
  };

  useEffect(() => {
    setTableKey(generateRandomString(4));
  }, [isAdminAccess]);

  useEffect(() => {
    setData(
      clients.map((client) => ({
        ...client,
        status: client?.isAssigned,
        clientName: client.clientName,
      }))
    );
  }, [clients]);

  return (
    <Table
      id={tableKey}
      data={data}
      columns={columns}
      onChange={(checked, _event, _cell, row) =>
        handleStatusChangeFn(checked, row)
      }
      paginationOptions={{
        options: {
          ...paginationOptions.options,
          page: currentPage,
          totalElements: clientsCount,
        },
      }}
      onPaginationOptionsChange={onPaginationChangeHandler}
      pagination={PaginationShowHide.SHOW}
      headerWrapperClasses="client-table-header"
      bodyWrapperClasses="client-body-wrapper"
    />
  );
};

export default ClientListTable;
