import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseErrorWithHandled, ResponseSuccess } from "../../shared/types";
import {
  addClient,
  deleteClient,
  editClient,
  getClients,
  oneClickLogin,
  toggleClientStatus,
} from "./helper/clients.api";
import {
  AddClientRequest,
  EditClientRequest,
  GetClientsRequest,
} from "./types";

export const addClientRequest = createAsyncThunk<
  ResponseSuccess,
  AddClientRequest,
  { rejectValue: ResponseErrorWithHandled }
>("addClientRequest", async (args, thunkAPI) => {
  try {
    return await addClient(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getClientsRequest = createAsyncThunk<
  ResponseSuccess,
  GetClientsRequest,
  { rejectValue: ResponseErrorWithHandled }
>("getClients/getClientRequest", async (args, thunkAPI) => {
  try {
    return await getClients(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAgencyUserClientsRequest = createAsyncThunk<
  ResponseSuccess,
  GetClientsRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  "getAgencyUserClients/getAgencyUserClientsRequest",
  async (args, thunkAPI) => {
    try {
      return await getClients(args);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const toggleClientStatusRequest = createAsyncThunk<
  ResponseSuccess,
  { clientId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  "toggleClientStatus/toggleClientStatusRequest",
  async ({ clientId }, thunkAPI) => {
    try {
      return await toggleClientStatus(clientId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const oneClickLoginRequest = createAsyncThunk<
  ResponseSuccess,
  { clientId: number },
  { rejectValue: ResponseErrorWithHandled }
>("oneClickLogin/oneClickLoginRequest", async ({ clientId }, thunkAPI) => {
  try {
    return await oneClickLogin(clientId);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const editClientRequest = createAsyncThunk<
  ResponseSuccess,
  EditClientRequest,
  { rejectValue: ResponseErrorWithHandled }
>("editClient/editClientRequest", async (args, thunkAPI) => {
  try {
    return await editClient(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteClientRequest = createAsyncThunk<
  ResponseSuccess,
  { clientId: number },
  { rejectValue: ResponseErrorWithHandled }
>("editClient/deleteClientRequest", async ({ clientId }, thunkAPI) => {
  try {
    return await deleteClient(clientId);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
