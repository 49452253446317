import { RootState } from "../../../../store/root-reducer";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import ForgotPassword from "./forgot-password";
import { forgotPasswordRequest } from "./extra-action";
import { resetForgotPasswordState } from "./forgot-password-slice";
import { ForgotPasswordRequest } from "../../types";

const mapStateToProps = (state: RootState) => ({
  forgotPasswordRequestStatus:
    state.forgotPassword.forgotPasswordRequest.status,
  forgotPasswordRequestMessage:
    state.forgotPassword.forgotPasswordRequest.message,
});

const mapDispatchToProps = {
  sendForgotPasswordRequest: (payload: ForgotPasswordRequest) =>
    forgotPasswordRequest(payload),
  resetForgotPasswordState: () => resetForgotPasswordState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(ForgotPassword);
