import React from "react";
import { ProgressBar } from "@saleshandy/design-system";
import { Images } from "../../../../shared/utils/app-constants";
import { formateDate } from "../../../../shared/utils/date-time";
import type { IProps } from "./account-plan-usage-container";
import { accessibleOnClick } from "../../../../shared/utils/accessible-on-click";
import { filterDecimal } from "../../../../shared/utils/filter-decimal";
import { SubscriptionPlans } from "../../../../shared/enum";
import AccountPlanUsageLoader from "./account-plan-usage-loader";

const AccountPlanUsage: React.FC<IProps> = ({
  usagesData,
  quotaResetDate,
  handleRefresh,
  loadingAccountUsage,
  planCode,
}) => {
  const isFreePlan = planCode === SubscriptionPlans.FREE;

  const accountPlanUsageCard = (
    <div className="card">
      <div className="card-body">
        <div className="subscription-plan__info">
          <div className="d-flex flex-column">
            <span className="semibold-2 font-bold popover-arrow-color-txt">
              Account Plan Usage
            </span>

            {isFreePlan ? (
              <span className="regular-1 font-medium gray-txt-12">-</span>
            ) : (
              <span className="regular-1 font-medium gray-txt-12">
                Email quota resets on{" "}
                {formateDate(quotaResetDate, "Do MMM, YYYY")}
              </span>
            )}
          </div>

          {!isFreePlan && (
            <div
              role="button"
              className="d-flex align-items-center"
              {...accessibleOnClick(handleRefresh)}
            >
              <img src={Images.Refresh} alt="refresh" />
              <span className="ml-2">Refresh</span>
            </div>
          )}
        </div>

        <div className="bs-mt-20 d-flex justify-content-between">
          {usagesData.map(
            ({ label, used, remaining, total, usedPer, remainingPer }) =>
              !Number.isNaN(remainingPer) &&
              !Number.isNaN(usedPer) && (
                <div
                  key={label}
                  className="d-flex flex-column progress-bar-container"
                >
                  <div className="d-flex justify-content-between">
                    <span className="semibold-1 popover-arrow-color-txt">
                      {label}
                    </span>

                    {isFreePlan ? (
                      <span className="semibold-1 popover-arrow-color-txt">
                        -
                      </span>
                    ) : (
                      <span className="semibold-1 popover-arrow-color-txt">
                        {used} of {total}{" "}
                        <span className="regular-1">
                          ({filterDecimal(usedPer)}% used)
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <ProgressBar percentage={isFreePlan ? 0 : usedPer} />
                  </div>

                  {!isFreePlan && (
                    <div className="mt-1">
                      <span className="regular-1 gray-txt-15">
                        {`${remaining} (${filterDecimal(
                          remainingPer
                        )}%) Remaining`}
                      </span>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="subscription-plan__container">
      {loadingAccountUsage ? <AccountPlanUsageLoader /> : accountPlanUsageCard}
    </div>
  );
};

export default AccountPlanUsage;
