import api from "../../../api";
import { ResponseSuccess } from "../../../shared/types";
import {
  CreatePasswordRequest,
  LoginRequest,
  ForgotPasswordRequest,
  SignupRequestPayload,
  AcceptInvitationRequestPayload,
} from "../types";

export const createPassword = (payload: CreatePasswordRequest) =>
  api.post<ResponseSuccess>("/agency/accept-invitation", payload);

export const login = (payload: LoginRequest) =>
  api.post<ResponseSuccess>("/agency/users/login", payload);

export const verifyToken = (token: string) =>
  api.get<ResponseSuccess>(`/agency/verify-invitation-token/?token=${token}`);

export const verifyResetPasswordToken = (token: string) =>
  api.get<ResponseSuccess>(`/agency/reset-password/${token}`);

export const resetPassword = (token: string, password: string) =>
  api.post<ResponseSuccess>(`/agency/reset-password/${token}`, { password });

export const forgotPassword = (payload: ForgotPasswordRequest) =>
  api.post<ResponseSuccess>("/agency/reset-password", payload);

export const signup = (payload: SignupRequestPayload) =>
  api.post<ResponseSuccess>("/agency/signup", payload);

export const acceptInvitation = (payload: AcceptInvitationRequestPayload) =>
  api.post<ResponseSuccess>("/agency/accept-invitation", payload);

export const resendVerificationEmail = async (email: string) =>
  api.post<ResponseSuccess>("/agency/resend-verification-link", {
    email,
  });
