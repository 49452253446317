import React, { useState, useEffect } from "react";
import {
  Button,
  EmptyList,
  ImageIcon,
  Modal,
  OverlayTooltip,
  Switch,
} from "@saleshandy/designs";

import { IProps } from "./types";
import ClientListTable from "./components/client-list-table";
import { ClientWithRole } from "../../types/client-with-role";
import { Images } from "../../../../shared/utils/app-constants";
import {
  hasUserPermissionToMakeAdmin,
  getAssignClientListEmptyMessage,
} from "./util/helper";

const AssignClientModal: React.FC<IProps> = ({
  show,
  onClose,
  isSelfView,
  onSubmit,
  clients,
  clientsCount,
  agencyUserName,
  isAgencyAdmin,
  isRequestPending,
  onPageChange,
  role,
}) => {
  const [clientsToBeAssigned, setClientsToBeAssigned] = useState<
    ClientWithRole[]
  >([]);
  const [clientsToBeRemove, setClientsToBeRemove] = useState<number[]>([]);
  const [isAdminAccessChecked, setIsAdminAccessChecked] = useState<number>(0);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState<boolean>(true);
  const [formattedClients, setFormattedClients] = useState([]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handleSwitchChange = (checked: boolean) => {
    setIsAdminAccessChecked(checked ? 1 : 0);
    setIsSaveBtnDisable(false);
  };

  const isRoleAssigntToAllEnabledClient = () =>
    clientsToBeAssigned.some((obj) => obj.role === null);

  const handleClientsToBeAssigned = (id, checked, clientRole) => {
    if (checked) {
      setClientsToBeAssigned((existingClients) => {
        const client = existingClients.find((c) => c.id === id);

        if (client) {
          client.role = clientRole;

          return existingClients.map((item) =>
            item.id === id ? client : item
          );
        }

        return [
          ...existingClients,
          {
            id,
            role: clientRole,
          },
        ];
      });
      setClientsToBeRemove((prevClientsToBeRemove) =>
        prevClientsToBeRemove.filter((clientId) => clientId !== id)
      );
    } else {
      setClientsToBeAssigned((preState) =>
        preState.filter((client) => client.id !== id)
      );
    }
    setIsSaveBtnDisable(false);
  };

  const handleClientsToBeRemove = (id, checked, clientRole, defaultValue) => {
    if (defaultValue && !checked) {
      setClientsToBeRemove((preState) => [...preState, id]);
      setIsSaveBtnDisable(false);
    } else if (checked) {
      handleClientsToBeAssigned(id, checked, clientRole);
    }
  };

  function filterUniqueById(array) {
    return array.reduce((uniqueArray, currentObj) => {
      // Check if there's an object with the same id in uniqueArray
      const existingObj = uniqueArray.find((obj) => obj.id === currentObj.id);

      // If not found, add the current object to uniqueArray
      if (!existingObj) {
        uniqueArray.push(currentObj);
      }

      return uniqueArray;
    }, []);
  }

  const updateClients = (assignClients, removedClients) => {
    const updatedClients = clients
      .map((client) => {
        // Check if the client's id is in clientToBeAssigned
        const assignedClient = assignClients.find(
          (item) => item.id === client.clientId
        );
        // Check if the client's id is in clientToBeRemoved
        const isClientToBeRemoved = removedClients.includes(client.clientId);

        if (assignedClient && !isClientToBeRemoved) {
          return {
            ...client,
            assignedRole: assignedClient?.role,
            isAssigned: true,
          }; // Replace data with clientToBeAssigned object
        }

        if (isClientToBeRemoved) {
          return { ...client, assignedRole: null, isAssigned: false };
        }

        return client;
      })
      .filter(Boolean); // Remove null values

    setFormattedClients(updatedClients);
  };

  useEffect(() => {
    if (isAgencyAdmin) {
      setIsAdminAccessChecked(1);
    }

    if (show && clients?.length > 0) {
      const assignedClients =
        clients
          .filter((c) => c.isAssigned)
          ?.map((c) => ({ id: c.clientId, role: c.assignedRole })) || [];
      const filteredClients = filterUniqueById([
        ...clientsToBeAssigned,
        ...assignedClients,
      ]);
      updateClients(filteredClients, clientsToBeRemove);
      setClientsToBeAssigned(filteredClients);
    }

    if (!show) {
      setIsAdminAccessChecked(0);
      setIsSaveBtnDisable(true);
      setClientsToBeRemove([]);
      setClientsToBeAssigned([]);
    }
  }, [show, clients]);

  return (
    <Modal
      show={show}
      titleContent={
        <div className="d-flex justify-content-between align-items-center">
          <div className="header-txt">
            Assign clients to
            <span className="ml-1">{agencyUserName}</span>{" "}
          </div>
          {!isSelfView && hasUserPermissionToMakeAdmin(role) && (
            <div className="d-flex justify-content-between align-items-center">
              <OverlayTooltip text="Turning it ON will give access of all clients.">
                <ImageIcon src={Images.InfoCircle} className="mr-2 mt-1" />
              </OverlayTooltip>
              <Switch
                checked={isAdminAccessChecked === 1}
                variant={Switch.Variant.Default}
                size={Switch.Size.Small}
                onChange={(checked) => handleSwitchChange(checked)}
                label="Admin Access"
                className="switch admin-access-switch"
              />
            </div>
          )}
        </div>
      }
      onClose={onClose}
      onHide={onClose}
      onSubmit={() =>
        onSubmit(clientsToBeAssigned, clientsToBeRemove, isAdminAccessChecked)
      }
      submitButtonText="Save"
      hideSubmitButton={isSelfView}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={
        isSaveBtnDisable ||
        isRequestPending ||
        clients?.length === 0 ||
        isRoleAssigntToAllEnabledClient()
      }
      isCancelDisabled={isRequestPending}
      cancelButtonVarient={Button.Variant.Outlined}
      footerContent={
        <a
          href={process.env.REACT_APP_NEED_HELP_URL}
          rel="noreferrer"
          target="_blank"
          className="regular-2 link-txt"
        >
          Need Help?
        </a>
      }
      submitButtonClassName="btn-primary-disable"
      className="assign-client-modal"
    >
      {clients?.length > 0 ? (
        <ClientListTable
          clients={formattedClients}
          clientsCount={clientsCount}
          handleRoleChange={(id, checked, clientRole) =>
            handleClientsToBeAssigned(id, checked, clientRole)
          }
          handleStatusChange={(id, checked, clientRole, defaultValue) =>
            handleClientsToBeRemove(id, checked, clientRole, defaultValue)
          }
          isAdminAccess={isAdminAccessChecked === 1}
          isSelfView={isSelfView}
          onPageChange={(page) => onPageChangeHandler(page)}
          currentPage={currentPage}
        />
      ) : (
        <EmptyList
          title={getAssignClientListEmptyMessage(role)}
          description=""
          className="no-data"
          isVertical
        />
      )}
    </Modal>
  );
};

export default AssignClientModal;
