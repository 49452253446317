import { useEffect, useRef } from 'react';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useOutClickHook = (ref, onClick) => {
  useEffect(() => {
    /**
     * Function handle for outside click
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };

    // Binding the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbinding the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
