import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../../shared/enum/request-status";
import { RequestState } from "../../../../shared/types";
import { forgotPasswordRequest } from "./extra-action";

type State = {
  forgotPasswordRequest: RequestState;
};

const initialState: State = {
  forgotPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
};

const forgotPasswordSlice = createSlice({
  name: "createPassword",
  initialState,
  reducers: {
    resetForgotPasswordState: (state) => {
      state.forgotPasswordRequest.status = RequestStatus.Ideal;
      state.forgotPasswordRequest.message = null;
    },
  },
  extraReducers: (builder) => {
    // Reset Password
    builder.addCase(forgotPasswordRequest.pending, (state) => {
      state.forgotPasswordRequest.status = RequestStatus.Pending;
      state.forgotPasswordRequest.message = null;
      state.forgotPasswordRequest.error = null;
    });
    builder.addCase(forgotPasswordRequest.fulfilled, (state, action) => {
      state.forgotPasswordRequest.status = RequestStatus.Succeeded;
      state.forgotPasswordRequest.message = action.payload.message;
    });
    builder.addCase(forgotPasswordRequest.rejected, (state, action) => {
      state.forgotPasswordRequest.status = RequestStatus.Failed;
      state.forgotPasswordRequest.message = action.payload.message;
      state.forgotPasswordRequest.error = action.payload;
    });
  },
});

export { forgotPasswordRequest };
export const { resetForgotPasswordState } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
