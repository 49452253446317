import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseErrorWithHandled, ResponseSuccess } from "../../shared/types";
import {
  assignClient,
  deleteTeamMember,
  getAgencyUsers,
  inviteUser,
  toggleUserStatus,
} from "./helper/teams.api";
import { InviteUserRequest, GetAgencyUsersRequest } from "./types";
import { AssignClientRequest } from "./types/request/assign-client-request";

type AssignClientRequestArgs = AssignClientRequest & { agencyUserId: number };

export const getAgencyUsersRequest = createAsyncThunk<
  ResponseSuccess,
  GetAgencyUsersRequest,
  { rejectValue: ResponseErrorWithHandled }
>("getAgencyUsers/getAgencyUsersRequest", async (params, thunkAPI) => {
  try {
    return await getAgencyUsers(params);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const inviteUserRequest = createAsyncThunk<
  ResponseSuccess,
  InviteUserRequest,
  { rejectValue: ResponseErrorWithHandled }
>("inviteUser/inviteUserRequest", async (args, thunkAPI) => {
  try {
    return await inviteUser(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const toggleUserStatusRequest = createAsyncThunk<
  ResponseSuccess,
  { agencyUserId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  "toggleUserStatus/toggleUserStatusRequest",
  async ({ agencyUserId }, thunkAPI) => {
    try {
      return await toggleUserStatus(agencyUserId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const assignClientRequest = createAsyncThunk<
  ResponseSuccess,
  AssignClientRequestArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  "assignClient/assignClientRequest",
  async ({ agencyUserId, ...args }, thunkAPI) => {
    try {
      return await assignClient(agencyUserId, args);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTeamMemberRequest = createAsyncThunk<
  ResponseSuccess,
  { agencyUserId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  "deleteTeamMember/deleteTeamMemberRequest",
  async ({ agencyUserId, ...args }, thunkAPI) => {
    try {
      return await deleteTeamMember(agencyUserId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
