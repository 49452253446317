import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import type { IProps } from "./auth-container";
import Signup from "./components/signup";
import Login from "./components/login";
import CreatePassword from "./components/create-password";
import ForgotPassword from "./components/forgot-password";
import {
  destroyChatSupport,
  initializeIntercom,
} from "../../shared/utils/helpscout-handlers";

const Auth: React.FC<IProps> = () => {
  //* useEffect
  useEffect(() => {
    initializeIntercom();

    return () => {
      destroyChatSupport();
    };
  }, []);

  return (
    <Switch>
      <Route path="/signup" component={Signup} />
      <Route path="/login" component={Login} />
      <Route path="/create-password" component={CreatePassword} />
      <Route path="/reset-password" component={ForgotPassword} />
    </Switch>
  );
};

export default Auth;
