import { AgencyRole, AgencyUserStatus } from "../../../../../shared/enum";

export const isMemberStatusDisable = (row, role) =>
  row.role === AgencyRole.AgencyOwner ||
  row.status === AgencyUserStatus.Invited ||
  role === AgencyRole.AgencyUser;

export const userHasDeleteAssignClientPermission = (
  actionKey,
  userActiveStatus,
  userInviteStatus,
  role
): boolean => {
  if (role === AgencyRole.AgencyUser || role === AgencyRole.AgencyTeamLeader) {
    if (actionKey === "delete") {
      return false;
    }
  }

  if (actionKey === "assignClient") {
    return userInviteStatus !== AgencyUserStatus.Invited;
  }

  return true;
};
