import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from "../../../../shared/types";
import { login } from "../../helper/auth.api";
import { LoginRequest } from "../../types/request/login-request";

export const loginRequest = createAsyncThunk<
  ResponseSuccess,
  LoginRequest,
  { rejectValue: ResponseErrorWithHandled }
>("login/loginRequest", async (args, thunkAPI) => {
  try {
    return await login(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
