import { IntercomEvents } from "../enum/intercom-events";
import { supportUrls } from "./url";
import {
  getUserEmail,
  getUserFullName,
  getUserFirstName,
  getUserLastName,
  getUserSignUpDate,
  getUserRole,
  getAgencyName,
} from "./user-details";

const intercomScript = document.createElement("script");
intercomScript.setAttribute("id", "intercom-support-script");

export const destroyChatSupport = () => {
  if (document.getElementById("intercom-support-script")) {
    document.body.removeChild(intercomScript);
    window.Intercom && window.Intercom(IntercomEvents.SHUTDOWN);
  }
};

export const initializeIntercom = () => {
  destroyChatSupport();

  window.intercomSettings = {
    api_base: supportUrls.intercomBaseUrl,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    alignment: "right",
    horizontal_padding: 65,
    name: getUserFullName(),
    "First Name": getUserFirstName(),
    "Last Name": getUserLastName(),
    "Signed up": getUserSignUpDate(),
    email: getUserEmail(),
    role: getUserRole(),
    isAgencyUser: true,
    agencyName: getAgencyName(),
  };

  intercomScript.innerHTML = `
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}' ;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  setTimeout(() => {
    document.body.appendChild(intercomScript);
    window.Intercom &&
      window.Intercom("boot", {
        api_base: supportUrls.intercomBaseUrl,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      });
  }, 1000);
};
