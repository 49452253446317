import React, { useState } from "react";
import { Button } from "@saleshandy/design-system";
import type { IProps } from "./ev-credits-details-container";
import { supportUrls } from "../../../../shared/utils/url";
import PurchaseCreditsModal from "../../../../shared/components/purchase-ev-credits-modal";
import EVCreditsLoader from "./ev-credits-loader";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";
import { AgencyRole } from "../../../../shared/enum";

const EVCreditsDetails: React.FC<IProps> = ({
  availableCredits,
  getEVCreditsRequestStatus,
  role,
}) => {
  const [purchaseEvCreditsModal, setPurchaseEvCreditsModal] = useState(false);

  const onShowPurchaseEvCreditsModal = () => {
    setPurchaseEvCreditsModal(true);
  };

  const onHidePurchaseEvCreditsModal = () => {
    setPurchaseEvCreditsModal(false);
  };

  return (
    <div className="ev-credits-container">
      {getIsRequestPending(getEVCreditsRequestStatus) ? (
        <EVCreditsLoader />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="ev-credits-data">
                <span className="semibold-1 mb-1">Available Credits</span>
                <span className="available-credits">{availableCredits}</span>
                <span className="regular-1 gray-txt-12">
                  For more Information, Please visit Email Verification{" "}
                  <a
                    href={supportUrls.faqUrl}
                    target="_blank"
                    className="faq-page-link"
                    rel="noreferrer"
                  >
                    FAQ Page
                  </a>
                </span>
              </div>
              {role === AgencyRole.AgencyOwner && (
                <Button onClick={onShowPurchaseEvCreditsModal}>
                  Purchase Credits
                </Button>
              )}
            </div>
          </div>

          {role === AgencyRole.AgencyOwner && (
            <PurchaseCreditsModal
              show={purchaseEvCreditsModal}
              onClose={onHidePurchaseEvCreditsModal}
              availableCredits={availableCredits}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EVCreditsDetails;
