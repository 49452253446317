import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from "../../../../shared/types";
import {
  signup,
  acceptInvitation,
  resendVerificationEmail,
} from "../../helper/auth.api";
import {
  SignupRequestPayload,
  AcceptInvitationRequestPayload,
} from "../../types";

export const signupRequest = createAsyncThunk<
  ResponseSuccess,
  SignupRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>("signup/signupRequest", async (args, thunkAPI) => {
  try {
    return await signup(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const acceptInvitationRequest = createAsyncThunk<
  ResponseSuccess,
  AcceptInvitationRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>("signup/acceptInvitationRequest", async (args, thunkAPI) => {
  try {
    return await acceptInvitation(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const resendVerificationEmailRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>("signup/resendVerificationEmailRequest", async (args, thunkAPI) => {
  try {
    return await resendVerificationEmail(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
