import React from "react";
import { Modal, ImageIcon, Button } from "@saleshandy/designs";
import { IProps } from "./types";
import { Images } from "../../../../shared/utils/app-constants";

const DisableMemberStatusConfirmationModal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  isRequestPending,
}) => {
  return (
    <Modal
      show={show}
      titleContent={
        <div className='d-flex'>
          <ImageIcon src={Images.InfoCircleDanger} />
          <div className='ml-3 semibold-3'>
            Do you want to disable the team member?
          </div>
        </div>
      }
      showCloseIcon
      onClose={onClose}
      onHide={onClose}
      submitButtonText='Yes'
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      isCancelDisabled={isRequestPending}
      cancelButtonVarient={Button.Variant.Outlined}
      onSubmit={onSubmit}
      className='disable-client-confirmation-modal modal-no-header-border'
    >
      <div>
        <p className='regular-2'>
          Once you disable the team member, the member will no longer be able to
          login to partner portal.
        </p>
      </div>
    </Modal>
  );
};

export default DisableMemberStatusConfirmationModal;
