import React from "react";
import { ImageIcon } from "@saleshandy/designs";
import { Images } from "../../utils/app-constants";

type IProps = {
  isEmptyData?: boolean;
};

const NoSearchFound: React.FC<IProps> = ({ isEmptyData = false }) => {
  return (
    <div className='center-container'>
      <div className='center-container--content'>
        <div className='center-container--vertical'>
          <div>
            <ImageIcon src={Images.FileBlank} />
          </div>
          <span className='regular-2 mt-1'>
            {isEmptyData
              ? "Either you don't have an access to any client or you have not been assigned any role. Contact Admin to proceed further! "
              : "No results Found!"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoSearchFound;
