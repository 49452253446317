import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../shared/enum/request-status";
import { RequestState } from "../../shared/types";
import {
  addClientRequest,
  getClientsRequest,
  toggleClientStatusRequest,
  oneClickLoginRequest,
  editClientRequest,
  deleteClientRequest,
  getAgencyUserClientsRequest,
} from "./extra-actions";
import { Client } from "./types/response/get-client";

type State = {
  addClientRequest: RequestState;
  getClientsRequest: RequestState;
  toggleClientStatusRequest: RequestState;
  oneClickLoginRequest: RequestState;
  editClientRequest: RequestState;
  deleteClientRequest: RequestState;
  getAgencyUserClientsRequest: RequestState;
  clients: Client[];
  clientCount: number;
  agencyUserClients: Client[];
  agencyUserClientCount: number;
  oneClickLoginToken: string;
  loginUrl: string;
};

const initialState: State = {
  addClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getClientsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  toggleClientStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  oneClickLoginRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  editClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAgencyUserClientsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  clients: null,
  clientCount: null,
  agencyUserClients: null,
  agencyUserClientCount: null,
  oneClickLoginToken: null,
  loginUrl: null,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetGetAgencyUserClientsState: (state) => {
      state.getAgencyUserClientsRequest.status = RequestStatus.Ideal;
    },
  },
  extraReducers: (builder) => {
    // Add Client
    builder.addCase(addClientRequest.pending, (state) => {
      state.addClientRequest.status = RequestStatus.Pending;
      state.addClientRequest.message = null;
      state.addClientRequest.error = null;
    });
    builder.addCase(addClientRequest.fulfilled, (state, action) => {
      state.addClientRequest.status = RequestStatus.Succeeded;
      state.addClientRequest.message = action.payload.message;
    });
    builder.addCase(addClientRequest.rejected, (state, action) => {
      state.addClientRequest.status = RequestStatus.Failed;
      state.addClientRequest.message = action.payload.message;
      state.addClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Clients
    builder.addCase(getClientsRequest.pending, (state) => {
      state.getClientsRequest.status = RequestStatus.Pending;
      state.getClientsRequest.message = null;
      state.getClientsRequest.error = null;
    });
    builder.addCase(getClientsRequest.fulfilled, (state, action) => {
      state.getClientsRequest.status = RequestStatus.Succeeded;
      state.getClientsRequest.message = action.payload.message;
      state.clients = action.payload.payload?.clients;
      state.clientCount = action.payload.payload?.clientCount;
    });
    builder.addCase(getClientsRequest.rejected, (state, action) => {
      state.getClientsRequest.status = RequestStatus.Failed;
      state.getClientsRequest.message = action.payload.message;
      state.getClientsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Agency User Clients
    builder.addCase(getAgencyUserClientsRequest.pending, (state) => {
      state.getAgencyUserClientsRequest.status = RequestStatus.Pending;
      state.getAgencyUserClientsRequest.message = null;
      state.getAgencyUserClientsRequest.error = null;
    });
    builder.addCase(getAgencyUserClientsRequest.fulfilled, (state, action) => {
      state.getAgencyUserClientsRequest.status = RequestStatus.Succeeded;
      state.getAgencyUserClientsRequest.message = action.payload.message;
      state.agencyUserClients = action.payload.payload?.clients;
      state.agencyUserClientCount = action.payload.payload?.clientCount;
    });
    builder.addCase(getAgencyUserClientsRequest.rejected, (state, action) => {
      state.getAgencyUserClientsRequest.status = RequestStatus.Failed;
      state.getAgencyUserClientsRequest.message = action.payload.message;
      state.getAgencyUserClientsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Toggle Client Status
    builder.addCase(toggleClientStatusRequest.pending, (state) => {
      state.toggleClientStatusRequest.status = RequestStatus.Pending;
      state.toggleClientStatusRequest.message = null;
      state.toggleClientStatusRequest.error = null;
    });
    builder.addCase(toggleClientStatusRequest.fulfilled, (state, action) => {
      state.toggleClientStatusRequest.status = RequestStatus.Succeeded;
      state.toggleClientStatusRequest.message = action.payload.message;
    });
    builder.addCase(toggleClientStatusRequest.rejected, (state, action) => {
      state.toggleClientStatusRequest.status = RequestStatus.Failed;
      state.toggleClientStatusRequest.message = action.payload.message;
      state.toggleClientStatusRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // One Click Login
    builder.addCase(oneClickLoginRequest.pending, (state) => {
      state.oneClickLoginRequest.status = RequestStatus.Pending;
      state.oneClickLoginRequest.message = null;
      state.oneClickLoginRequest.error = null;
    });
    builder.addCase(oneClickLoginRequest.fulfilled, (state, action) => {
      state.oneClickLoginRequest.status = RequestStatus.Succeeded;
      state.oneClickLoginRequest.message = action.payload.message;
      state.oneClickLoginToken = action.payload.payload?.accessToken;
      state.loginUrl = action.payload.payload?.loginUrl;
    });
    builder.addCase(oneClickLoginRequest.rejected, (state, action) => {
      state.oneClickLoginRequest.status = RequestStatus.Failed;
      state.oneClickLoginRequest.message = action.payload.message;
      state.oneClickLoginRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Edit Client
    builder.addCase(editClientRequest.pending, (state) => {
      state.editClientRequest.status = RequestStatus.Pending;
      state.editClientRequest.message = null;
      state.editClientRequest.error = null;
    });
    builder.addCase(editClientRequest.fulfilled, (state, action) => {
      state.editClientRequest.status = RequestStatus.Succeeded;
      state.editClientRequest.message = action.payload.message;
    });
    builder.addCase(editClientRequest.rejected, (state, action) => {
      state.editClientRequest.status = RequestStatus.Failed;
      state.editClientRequest.message = action.payload.message;
      state.editClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Client
    builder.addCase(deleteClientRequest.pending, (state) => {
      state.deleteClientRequest.status = RequestStatus.Pending;
      state.deleteClientRequest.message = null;
      state.deleteClientRequest.error = null;
    });
    builder.addCase(deleteClientRequest.fulfilled, (state, action) => {
      state.deleteClientRequest.status = RequestStatus.Succeeded;
      state.deleteClientRequest.message = action.payload.message;
    });
    builder.addCase(deleteClientRequest.rejected, (state, action) => {
      state.deleteClientRequest.status = RequestStatus.Failed;
      state.deleteClientRequest.message = action.payload.message;
      state.deleteClientRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export {
  addClientRequest,
  getClientsRequest,
  toggleClientStatusRequest,
  oneClickLoginRequest,
  getAgencyUserClientsRequest,
};
export const { resetGetAgencyUserClientsState } = clientsSlice.actions;
export default clientsSlice.reducer;
