import React from "react";
import { Modal } from "@saleshandy/designs";
import { Button } from "@saleshandy/design-system";
import { AgencyRole } from "../enum";
import { contactAdmin, redirectWithoutRefresh } from "../utils";
import { Images } from "../utils/app-constants";

type IProps = {
  show: boolean;
  onClose: VoidFunction;
  role: AgencyRole;
  adminFirstName: string;
  userFirstName: string;
};

const PlanRestrictionModal: React.FC<IProps> = ({
  show,
  onClose,
  role,
  adminFirstName,
  userFirstName,
}) => {
  const getCTALabel = () => {
    if (role === AgencyRole.AgencyOwner) {
      return "Upgrade";
    }

    return "Contact Owner";
  };

  const ctaActionHandler = () => {
    onClose();
    if (role === AgencyRole.AgencyOwner) {
      redirectWithoutRefresh("/billing/subscription");
    } else {
      const subject =
        "I am unable to access Client - Saleshandy Upgrade Required";
      const body = `Hello ${adminFirstName}, %0D%0A %0D%0AIt has come to our notice that the Saleshandy Agency Portal has restricted our client login access because of the automatic downgrade of our plan to the Free version. %0D%0A %0D%0ALet’s upgrade our plan to continue using the Saleshandy Agency Portal. %0D%0A %0D%0AThanks, %0D%0A${userFirstName}`;

      contactAdmin(subject, body);
    }
  };

  return (
    <Modal
      className="confirmation-modal"
      show={show}
      onClose={onClose}
      onHide={onClose}
      hideHeader
      hideFooter
    >
      <div className="confirmation-modal--title">
        <div className="d-flex">
          <div>
            <img src={Images.InfoCircleDanger} alt="Alert" />
          </div>
          <h1 className="ml-3">Upgrade to continue</h1>
        </div>
      </div>

      <div className="confirmation-modal--description">
        <p>
          You are currently on a Free Plan, Please upgrade your plan to use the
          Agency Portal features.
        </p>
      </div>

      <div className="confirmation-modal--divider" />

      <div className="confirmation-modal--footer">
        <Button
          variant="secondary"
          onClick={onClose}
          className="secondary-cta-btn"
        >
          Cancel
        </Button>
        <Button onClick={ctaActionHandler}>{getCTALabel()}</Button>
      </div>
    </Modal>
  );
};

export default PlanRestrictionModal;
