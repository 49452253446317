import React from "react";
import produce from "immer";
import { Button } from "@saleshandy/design-system";
import { Input, ImageIcon } from "@saleshandy/designs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import queryString from "query-string";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import { Link } from "react-router-dom";

import type { IProps, IState } from "./types";

import { validate } from "./validator";
import { Images } from "../../../../shared/utils/app-constants";

import PrivacyPolicy from "../privacy-policy";
import {
  AuthHelper,
  executeOnRequestStatusWithPrevStatusCheck,
  getUserLocationMetaData,
  redirectWithoutRefresh,
  setToken,
} from "../../../../shared/utils";
import { getCurrentTimeZone } from "../../../../shared/utils/date-time";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";
import routes from "../../../../routes";
import { getCookie } from "../../../../shared/utils/cookies-handlers";

class SignupForm extends React.PureComponent<IProps, IState> {
  private readonly phoneInputField = React.createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);

    const {
      location,
      email: propsEmail,
      isInvitedUser,
      firstName,
      lastName,
    } = this.props;
    const { email: queryEmail } = queryString.parse(location.search);
    const disableEmailInputValue = isInvitedUser || queryEmail;

    this.state = {
      values: {
        firstName: firstName || "",
        lastName: lastName || "",
        email: propsEmail || queryEmail || "",
        phone: "",
        password: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
      },
      dirty: {
        firstName: firstName !== "" || false,
        lastName: lastName !== "" || false,
        email: disableEmailInputValue || false,
        phone: false,
        password: false,
      },
      disableEmailInput: disableEmailInputValue,
      countryCode: "us",
      dialCode: "",
      showPassword: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onPhoneInputChange = this.onPhoneInputChange.bind(this);
    this.onPasswordVisibilityChange =
      this.onPasswordVisibilityChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount(): void {
    const { code } = getUserLocationMetaData();

    this.setState({ countryCode: code.toLowerCase() ?? "us" });

    const phoneFormInput = document.querySelector(".phone-form-input");
    const formControl = document.querySelector(".form-control");
    const flagDropdown = document.querySelector(".flag-dropdown");
    phoneFormInput.insertBefore(flagDropdown, formControl);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      signupRequestStatus,
      signupRequestError,
      acceptInvitationRequestStatus,
      acceptInvitationRequestError,
      showSignupError,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: signupRequestStatus,
      prevStatus: prevProps.signupRequestStatus,
      onSuccess: () => {
        this.handleSuccess();
      },
      onFailed: () => {
        if (signupRequestError?.message && !showSignupError) {
          toaster.error(signupRequestError.message, { theme: Theme.New });
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: acceptInvitationRequestStatus,
      prevStatus: prevProps.acceptInvitationRequestStatus,
      onSuccess: () => {
        this.handleSuccess();
      },
      onFailed: () => {
        if (acceptInvitationRequestError?.message) {
          toaster.error(acceptInvitationRequestError.message, {
            theme: Theme.New,
          });
          if (
            acceptInvitationRequestError.message ===
            "Invitation already accepted."
          ) {
            redirectWithoutRefresh({
              pathname: routes.LOGIN,
            });
          }
        }
      },
    });
  }

  componentWillUnmount() {
    const { hideSignupRequestError } = this.props;
    hideSignupRequestError?.();
  }

  handleSuccess() {
    const { token, isInvitedUser } = this.props;

    if (token && !isInvitedUser) {
      setToken(token);
      AuthHelper.login({ token, redirect: false });
      redirectWithoutRefresh({
        pathname: routes.CLIENTS,
      });
    } else {
      redirectWithoutRefresh({
        pathname: routes.LOGIN,
      });
    }
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      })
    );

    this.onInputBlur(e);
  }

  onInputBlur(e) {
    if (e?.target) {
      const { name } = e.target;
      const { dialCode } = this.state;
      this.setState(
        produce((draft) => {
          if (draft.dirty[name]) {
            draft.errors[name] = validate(name, draft.values[name], dialCode);
          }
        })
      );
    }
  }

  onPhoneInputChange(
    value: string,
    data,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const { countryCode, dialCode } = this.state;

    if (data && dialCode !== data.dialCode) {
      this.setState({ dialCode: data.dialCode });
    }

    if (data && countryCode !== data.countryCode) {
      this.setState({ countryCode: data.countryCode, dialCode: data.dialCode });
      this.phoneInputField.current.focus();
    }

    this.setState(
      produce((draft) => {
        draft.values.phone = value;
        draft.dirty.phone = true;
      })
    );

    this.onInputBlur(e);
  }

  onPasswordVisibilityChange() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values, dialCode } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key], dialCode);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    let timeZone = getCurrentTimeZone();
    if (timeZone === "Asia/Calcutta") {
      timeZone = "Asia/Kolkata";
    }

    const {
      sendSignupRequest,
      sendAcceptInvitationRequest,
      isInvitedUser,
      invitationToken,
    } = this.props;

    if (isInvitedUser) {
      sendAcceptInvitationRequest({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        password: values.password,
        token: invitationToken,
        timeZone,
      });
    } else {
      sendSignupRequest({
        ...values,
        timeZone,
        landingPage: getCookie("sh_ulp") || "",
      });
    }
  }

  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== "" || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  render() {
    const { values, errors, countryCode, showPassword, disableEmailInput } =
      this.state;
    const {
      signupRequestStatus,
      acceptInvitationRequestStatus,
      showSignupError,
      signupRequestError,
    } = this.props;

    const isSignupBtnLoading =
      getIsRequestPending(signupRequestStatus) ||
      getIsRequestPending(acceptInvitationRequestStatus);
    const isSignupBtnDisabled = isSignupBtnLoading || this.hasErrors();

    return (
      <div className="auth__container signup">
        <div className="signup-container">
          <div className="card">
            <div className="logo--section">
              <ImageIcon src={Images.SaleshandyLogo} />
            </div>
            <div className="card-body">
              <div className="card-body__title">
                <span className="semibold-4">
                  Sign up to start your free trial
                </span>
              </div>

              <form onSubmit={this.onFormSubmit} className="auth-form">
                <div className="auth-form-row">
                  <div className="auth-form-input">
                    <Input
                      name="firstName"
                      label="First Name"
                      placeholder="John"
                      value={values.firstName}
                      variant={errors.firstName && Input.Variant.Error}
                      caption={errors.firstName}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      tabIndex={0}
                      autoFocus
                      autoComplete="current-firstName"
                    />
                  </div>

                  <div className="auth-form-input">
                    <Input
                      name="lastName"
                      label="Last Name"
                      placeholder="Doe"
                      value={values.lastName}
                      variant={errors.lastName && Input.Variant.Error}
                      caption={errors.lastName}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      tabIndex={0}
                      autoComplete="current-lastName"
                    />
                  </div>
                </div>

                <div className="auth-form-row">
                  <div className="auth-form-input">
                    <Input
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="johndoe@example.com"
                      value={values.email}
                      variant={errors.email && Input.Variant.Error}
                      disabled={disableEmailInput}
                      caption={
                        errors.email ||
                        (showSignupError ? signupRequestError.message : "")
                      }
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      tabIndex={0}
                      autoComplete="current-email"
                    />
                  </div>

                  <div className="auth-form-input phone">
                    <p>Phone Number</p>
                    <PhoneInput
                      country={countryCode}
                      value={values.phone}
                      onChange={(value, data, e) =>
                        this.onPhoneInputChange(value, data, e)
                      }
                      onBlur={this.onInputBlur}
                      containerClass={`phone-form-input ${
                        errors.phone ? "errors.phone" : ""
                      }`}
                      inputProps={{
                        tabIndex: 0,
                        name: "phone",
                        ref: this.phoneInputField,
                      }}
                    />
                    <span className="phone-form-input-error">
                      {errors.phone}
                    </span>
                  </div>
                </div>

                <div className="auth-form-row">
                  <div className="auth-form-input password">
                    <Input
                      name="password"
                      label="Password"
                      placeholder="Use 8+ characters with a mix of letters & numbers."
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      variant={errors.password && Input.Variant.Error}
                      caption={errors.password}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      className="auth-form-input"
                      autoComplete="current-password"
                      icons={[
                        {
                          place: Input.IconPlace.Right,
                          identifier: showPassword ? "eye-alt" : "eye",
                          className: "pointer",
                          onClick: this.onPasswordVisibilityChange,
                        },
                      ]}
                    />
                  </div>
                </div>

                <Button
                  isFullWidth
                  type="submit"
                  isLoading={isSignupBtnLoading}
                  disabled={isSignupBtnDisabled}
                  loadingText="Signing up, please wait..."
                >
                  Sign up
                </Button>
              </form>

              <PrivacyPolicy />

              <div className="bottom-navigation">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" tabIndex={0}>
                    Log in!
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupForm;
