import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";

import type { IProps } from "./home-container";
import ConfigRoute from "../../shared/components/config-route";
import Clients from "../clients";
import MainHeader from "../../shared/components/main-header";
import routes from "../../routes";
import { executeOnRequestStatus } from "../../shared/utils";
import {
  destroyChatSupport,
  initializeIntercom,
} from "../../shared/utils/helpscout-handlers";
import { accessibleOnClick } from "../../shared/utils/accessible-on-click";
import VerifyEmailCallback from "../auth/components/verify-email-callback";
import OnboardingModal from "../../shared/components/onboarding-modal";
import WatchVideoModal from "../../shared/components/watch-video-modal";
import {
  AgencyUserOnboardingSteps,
  AgencyUserSettingsCode,
} from "../../shared/enum";

const Home: React.FC<IProps> = ({
  firstName,
  lastName,
  email,
  verified,
  agencyLogo,
  agencyUserProfileProgress,
  subscription,
  role,
  adminFirstName,

  getUserSettingsRequestStatus,
  sendGetUserSettingsRequest,

  sendUpdateOnboardingRequest,
  sendCompleteOnboardingRequest,
  updateOnboardingRequestStatus,
  updateOnboardingRequestError,
  resetUpdateOnboardingRequest,

  resendVerificationEmailRequest,
  resendVerificationEmailRequestStatus,
  resendVerificationEmailRequestError,
}) => {
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [watchVideoModal, setWatchVideoModal] = useState(false);

  const showOnboardingModal = () => {
    setOnboardingModal(true);
  };

  const hideOnboardingModal = () => {
    setOnboardingModal(false);
  };

  const showWatchVideoModal = () => {
    setWatchVideoModal(true);
  };

  const hideWatchVideoModal = () => {
    setWatchVideoModal(false);
  };

  const handleSendVerificationLinkRequest = () => {
    resendVerificationEmailRequest(email);
  };

  useEffect(() => {
    sendGetUserSettingsRequest();

    return () => {
      destroyChatSupport();
    };
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: () => {
        initializeIntercom();

        if (agencyUserProfileProgress?.length > 0) {
          const onboarding = agencyUserProfileProgress.find(
            (progress) => progress.code === AgencyUserSettingsCode.ONBOARDING
          );

          if (onboarding?.value === AgencyUserOnboardingSteps.STEP_1) {
            showOnboardingModal();
          }

          if (onboarding?.value === AgencyUserOnboardingSteps.STEP_2) {
            showWatchVideoModal();
            sendCompleteOnboardingRequest();
          }
        }
      },
    });
  }, [getUserSettingsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateOnboardingRequestStatus,
      onSuccess: () => {
        resetUpdateOnboardingRequest();
        sendCompleteOnboardingRequest();
        hideOnboardingModal();
        showWatchVideoModal();
      },
      onFailed: () => {
        if (updateOnboardingRequestError?.message) {
          toaster.error(updateOnboardingRequestError?.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [updateOnboardingRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: resendVerificationEmailRequestStatus,
      onSuccess: () => {
        toaster.success("Email resent successfully.", {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        if (resendVerificationEmailRequestError?.message) {
          toaster.error(resendVerificationEmailRequestError.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [resendVerificationEmailRequestStatus]);

  return (
    <>
      {!verified && (
        <div className="email-verify-alert">
          <span>
            Check your registered email and authenticate your profile by
            clicking on the link. If you have not received it,{" "}
            <span {...accessibleOnClick(handleSendVerificationLinkRequest)}>
              send again
            </span>
            .
          </span>
        </div>
      )}

      <MainHeader
        firstName={firstName}
        lastName={lastName}
        email={email}
        agencyLogo={agencyLogo}
        subscription={subscription}
        role={role}
        adminFirstName={adminFirstName}
        userFirstName={firstName}
      />
      <Switch>
        <ConfigRoute
          title="Client"
          type={ConfigRoute.RouteType.Auth}
          exact
          path={routes.CLIENTS}
          component={Clients}
        />
        <ConfigRoute
          title="Team"
          type={ConfigRoute.RouteType.Auth}
          exact
          path={routes.TEAM}
          component={Clients}
        />
        <ConfigRoute
          title="Billing"
          type={ConfigRoute.RouteType.Auth}
          exact
          path={routes.BILLING}
          component={Clients}
        />
        <ConfigRoute
          title="Billing"
          type={ConfigRoute.RouteType.Auth}
          exact
          path={routes.BILLING_USAGE}
          component={Clients}
        />
        <ConfigRoute
          title="Billing"
          type={ConfigRoute.RouteType.Auth}
          exact
          path={routes.BILLING_SUBSCRIPTION}
          component={Clients}
        />
        <ConfigRoute
          path="/verify-email"
          title="Verify Email"
          component={VerifyEmailCallback}
        />
        <Redirect exact from="/" to={routes.CLIENTS} />
      </Switch>

      <OnboardingModal
        show={onboardingModal}
        firstName={firstName}
        sendUpdateOnboardingRequest={sendUpdateOnboardingRequest}
        updateOnboardingRequestStatus={updateOnboardingRequestStatus}
      />

      <WatchVideoModal
        show={watchVideoModal}
        firstName={firstName}
        onClose={hideWatchVideoModal}
      />
    </>
  );
};

export default Home;
