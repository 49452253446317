import React from "react";
import moment from "moment";
import { Button } from "@saleshandy/design-system";
import SubscriptionDetail from "./subscription-detail";
import { SubscriptionStatus } from "../../../../shared/enum";

const SubscriptionInfo = ({
  planName,
  startAt,
  nextBillingAt,
  planAmount = 0,
  stripeCredits = 0,
  status,
}) => {
  const dateFormat = "DD MMM, yyyy";

  const subscriptionDetails = [
    { id: "1", label: "Start Date", value: moment(startAt).format(dateFormat) },
    {
      id: "2",
      label: "Next Billing Date",
      value: moment(nextBillingAt).format(dateFormat),
    },
    { id: "3", label: "Next Billing Amount", value: `$${planAmount}` },
    ...(stripeCredits > 0
      ? [
          {
            id: "4",
            label: "Available Credits",
            value: `$${stripeCredits}`,
          },
        ]
      : []),
  ];

  const handleManageBilling = () => {
    window.open(process.env.REACT_APP_MANAGE_BILLING_WITH_STRIPE);
  };

  return (
    <div className="subscription-info">
      <div className="subscription-info--header">
        <p>Plan Details</p>
        <Button variant="secondary" onClick={handleManageBilling}>
          Manage Billing
        </Button>
      </div>
      <h1 className="subscription-info--plan">
        {planName}
        {status === SubscriptionStatus.Canceled && (
          <span>
            {" "}
            - <span className="red-txt-12">Canceled</span>
          </span>
        )}
      </h1>
      <div className="subscription-info--details">
        {subscriptionDetails.map((detail) => (
          <SubscriptionDetail {...detail} />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionInfo;
