import React from 'react';

export const MessageCircle = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3838 4.99186C10.4157 4.91207 8.49475 5.47173 6.97866 6.54732C5.46468 7.6214 4.46845 9.12674 4.13661 10.7661C3.8054 12.4025 4.15028 14.0983 5.12868 15.5453C5.30282 15.8029 5.34727 16.1267 5.24895 16.4217L4.50164 18.6636L7.49216 18.0273C7.70986 17.981 7.93678 18.0087 8.137 18.1059C9.85115 18.938 11.8579 19.1922 13.7777 18.8119C15.6974 18.4316 17.3711 17.4492 18.5025 16.0765C19.6309 14.7076 20.1471 13.0421 19.9851 11.3879C19.8229 9.73218 18.9877 8.15939 17.5966 6.97284C16.2029 5.7841 14.3519 5.07166 12.3838 4.99186ZM2.94423 21.0041C3.03962 21.0095 3.13406 21.0011 3.22495 20.98L7.58187 20.053C9.62162 20.96 11.945 21.2138 14.1664 20.7737C16.5045 20.3105 18.599 19.1038 20.0458 17.3486C21.4956 15.5898 22.1919 13.4017 21.9756 11.1929C21.7594 8.9858 20.6509 6.94928 18.8945 5.45117C17.1407 3.95526 14.8545 3.09039 12.4649 2.99351C10.0753 2.89662 7.71474 3.57291 5.82142 4.91612C3.92598 6.26083 2.61698 8.19253 2.17637 10.3694C1.76804 12.3867 2.13839 14.4554 3.20139 16.2398L2.05694 19.6732C2.02586 19.7611 2.00689 19.854 2.00159 19.9494C1.99463 20.0721 2.01064 20.1933 2.04685 20.3073C2.0905 20.4457 2.16234 20.569 2.25453 20.6719C2.34653 20.7751 2.46095 20.8602 2.59362 20.9191C2.7029 20.9679 2.8215 20.9973 2.94423 21.0041Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C12.5523 11 13 11.4477 13 12V12.01C13 12.5623 12.5523 13.01 12 13.01C11.4477 13.01 11 12.5623 11 12.01V12C11 11.4477 11.4477 11 12 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11C8.55228 11 9 11.4477 9 12V12.01C9 12.5623 8.55228 13.01 8 13.01C7.44772 13.01 7 12.5623 7 12.01V12C7 11.4477 7.44772 11 8 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11C16.5523 11 17 11.4477 17 12V12.01C17 12.5623 16.5523 13.01 16 13.01C15.4477 13.01 15 12.5623 15 12.01V12C15 11.4477 15.4477 11 16 11Z"
      fill="currentColor"
    />
  </svg>
);
