import { SubscriptionPlans } from "../../../shared/enum";

export const getIsUserSubscribed = (planCode: string): boolean =>
  planCode !== SubscriptionPlans.FREE && planCode !== SubscriptionPlans.TRIAL;

export const featureUsages = {
  emailSend: "EMAIL.SEND",
  prospectAdd: "PROSPECT.ADD",
  sequenceEmailAccount: "SEQUENCE.EMAIL_ACCOUNT",
};
