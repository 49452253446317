import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../../shared/enum/request-status";
import { RequestState } from "../../../../shared/types";
import { loginRequest } from "./extra-action";

type State = {
  loginRequest: RequestState;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
};

const initialState: State = {
  loginRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  accessToken: null,
  email: null,
  firstName: null,
  lastName: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Login
    builder.addCase(loginRequest.pending, (state) => {
      state.loginRequest.status = RequestStatus.Pending;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.loginRequest.status = RequestStatus.Succeeded;
      state.loginRequest.message = action.payload.message;
      state.accessToken = action.payload.payload?.accessToken;
      state.email = action.payload.payload?.email;
      state.firstName = action.payload.payload?.firstName;
      state.lastName = action.payload.payload?.lastName;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.loginRequest.status = RequestStatus.Failed;
      state.loginRequest.message = action.payload.message;
      state.loginRequest.error = action.payload;
    });
  },
});

export { loginRequest };
export default loginSlice.reducer;
