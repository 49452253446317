import React, { memo, ReactNode, useCallback, useMemo } from "react";
import { Avatar, OverlayTooltip } from "@saleshandy/designs";

export type AvatarRowProps = {
  /**
   * No of tags to display in a row. Other tags will be grouped together.
   */
  maxTags?: number;
  avatars: any[];
  renderAddTag?: () => ReactNode;
};

/**
 * Renders a Row of prospect tags.
 * Max no of tags in a row can be limited.
 * All the other tags will be grouped together.
 */
const AvatarRow: React.FC<AvatarRowProps> = ({
  avatars,
  maxTags = 4,
  renderAddTag,
}) => {
  let groupedTagNames: React.ReactNode = "";

  const [avatarsToDisplay, avatarsToGroup] = [
    avatars.slice(0, maxTags),
    avatars.slice(maxTags),
  ];

  groupedTagNames = useMemo(
    () =>
      avatarsToDisplay.map((avatar, index) => {
        const firstName = avatar.split(" ")[0];
        const lastName = avatar.split(" ")[1];

        return (
          <Avatar
            key={index}
            firstName={firstName}
            lastName={lastName}
            className='avatar'
          />
        );
      }),
    [avatarsToGroup]
  );

  const renderTags = useCallback(() => {
    const row = avatarsToDisplay.map((avatar, index) => {
      const firstName = avatar.split(" ")[0];
      const lastName = avatar.split(" ")[1];

      return (
        <OverlayTooltip text={avatar} className='tooltip-width-auto'>
          <div className='mr-10'>
            <Avatar
              key={index}
              firstName={firstName}
              lastName={lastName}
              className='avatar'
            />
          </div>
        </OverlayTooltip>
      );
    });

    if (avatarsToGroup.length) {
      row.push(
        <>
          <div
            key={avatarsToGroup.length}
            className='avatar-tag d-flex justify-content-center align-items-center'
          >
            <span>+{avatarsToGroup.length}</span>
            <div className='custom-overlay-popover'>
              <div className='custom-overlay-popover--inner'>
                {avatarsToGroup.map((text) => (
                  <span className='font-size-10'>{text}</span>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }

    return row;
  }, [groupedTagNames, avatarsToDisplay, avatarsToGroup.length]);

  return (
    <div className='avatar-group-row d-flex align-items-center'>
      {avatarsToDisplay.length ? renderTags() : "-"}
      {renderAddTag && renderAddTag()}
    </div>
  );
};

export default memo(AvatarRow);
