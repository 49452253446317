import React, { useState } from "react";
import { Button, Input } from "@saleshandy/designs";
import { IProps } from "./types";
import { InputIconConfig } from "@saleshandy/designs/lib/input/types";
import { isEnterKey } from "../../../../shared/utils/event";
import { AgencyRole } from "../../../../shared/enum";

const TeamHeaderAction: React.FC<IProps> = ({
  showInviteTeamMemberModal,
  onChange,
  role,
}) => {
  const [search, setSearch] = useState<string>("");
  const [showIconOnSearch, setShowIconOnSearch] = useState<InputIconConfig[]>([
    {
      place: Input.IconPlace.Left,
      identifier: "search",
    },
  ]);

  const onSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (isEnterKey(event.key)) {
      onChange(value);
    }
  };

  const clearSearch = () => {
    setSearch("");
    onChange("");
    setShowIconOnSearch([
      {
        place: Input.IconPlace.Left,
        identifier: "search",
      },
    ]);
  };

  const onSearchChangeHandler = (value: string) => {
    if (value.length > 0) {
      setShowIconOnSearch([
        {
          place: Input.IconPlace.Left,
          identifier: "search",
        },
        value.length > 0 && {
          place: Input.IconPlace.Right,
          identifier: "close-o",
          onClick: clearSearch,
        },
      ]);
    }
    setSearch(value);
  };

  return (
    <div className='d-flex justify-content-between mb-4 mt-2'>
      <div>
        <Input
          placeholder='Search...'
          value={search}
          onKeyPress={onSearchKeyPress}
          onChange={onSearchChangeHandler}
          className='input-without-label'
          icons={showIconOnSearch}
        />
      </div>

      {(role === AgencyRole.AgencyOwner || role === AgencyRole.AgencyAdmin) && (
        <Button
          variant={Button.Variant.Primary}
          onClick={showInviteTeamMemberModal}
          className='invite-member-btn'
        >
          Invite members
        </Button>
      )}
    </div>
  );
};

export default TeamHeaderAction;
