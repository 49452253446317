import React from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy-text">
    <p>
      By clicking sign up, you agree to the Saleshandy’s Agency{" "}
      <a
        href="https://www.saleshandy.com/privacy-policy/"
        target="_blank"
        rel="noreferrer noopener"
        tabIndex={0}
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href="https://www.saleshandy.com/terms/"
        target="_blank"
        rel="noreferrer noopener"
        tabIndex={0}
      >
        Terms of Service
      </a>
      .
    </p>
  </div>
);

export default PrivacyPolicy;
