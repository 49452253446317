import store from "../../store/store";

export const contactAdmin = (subject: string, body: string) => {
  const { home } = store.getState();

  const params = new URLSearchParams("");

  params.set("to", home.adminDetails?.email || "");

  const encodedAdminEmail = params.toString().split("=")[1];

  const mailString = `mailto:${encodedAdminEmail}?subject=${subject}&body=${body}`;

  window.open(mailString, "_blank");
};
