import ValidationChecklist from '../validation-checklist';
import React from 'react';
import passwordValidator from '../../utils/password-validator';

type IProps = {
  password: string;
};

const PasswordValidationChecklist: React.FC<IProps> = ({ password }) => (
  <ValidationChecklist
    columns={[
      [
        {
          valid: passwordValidator.uppercase(password),
          text: 'One uppercase character',
        },
        {
          valid: passwordValidator.lowercase(password),
          text: 'One lowercase character',
        },
      ],
      [
        {
          valid: passwordValidator.numeric(password),
          text: 'One numerical character',
        },
        {
          valid: passwordValidator.minLength(password),
          text: '8 characters minimum',
        },
      ],
    ]}
  />
);

export default PasswordValidationChecklist;
