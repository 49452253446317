import { connect, ConnectedProps } from "react-redux";

import { RootState } from "../../../../store/root-reducer";

import AccountPlanUsage from "./account-plan-usage";

const mapStateToProps = (state: RootState) => ({
  planCode: state.home.subscription?.planCode,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  usagesData: any;
  quotaResetDate: any;
  handleRefresh: any;
  loadingAccountUsage: any;
};

export default connector(AccountPlanUsage);
