import { RootState } from "../../../../store/root-reducer";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import Login from "./login";
import { loginRequest } from "./extra-action";
import { LoginRequest } from "../../types/request/login-request";

const mapStateToProps = (state: RootState) => ({
  loginRequestStatus: state.login.loginRequest.status,
  loginRequestMessage: state.login.loginRequest?.message,
  accessToken: state.login.accessToken,
});

const mapDispatchToProps = {
  sendLoginRequest: (payload: LoginRequest) => loginRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(Login);
