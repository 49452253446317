export enum AgencyUserSettingsCode {
  ONBOARDING = "onboarding",
  ONBOARDING_COMPLETED = "onboarding-completed",
}

export enum AgencyUserOnboardingSteps {
  STEP_1 = "onboarding-form",
  STEP_2 = "intro-video",
  STEP_3 = "onboarding-completed",
}

export enum UseCase {
  LEAD_GENERATION = "Lead Generation",
  BOOK_MEETING_AND_BUILD_SALES_PIPELINE = "Book more meetings",
  OUTREACH_RECRUITMENT = "Recruiting Outreach",
  PROMOTE_PRODUCT_AND_SERVICE = "Product & Service Promotion",
  ONE_TIME_COLD_EMAIL_OUTREACH = "One time cold email outreach",
  OTHER = "Others",
  FindLeadsFromSaleshandyConnect = "Find leads using Saleshandy connect",
}

export enum Industry {
  INFORMATION_TECHNOLOGY = "Information Technology & Services",
  MARKETING_ADVERTISING = "Marketing & Advertising",
  COMPUTER_SOFTWARE = "Computer Software",
  STAFF_RECRUITMENT = "Staff & Recruitment",
  OTHERS = "Others",
}

export enum ClientSize {
  "1-10" = "1-10",
  "11-30" = "11-30",
  MORETHAN30 = "More than 30",
}

export enum ContactableProspect {
  ZERO_TO_2K = "0 - 2k",
  TWO_K_TO_40K = "2k - 40k",
  FORTY_K_TO_100k = "40k - 100k",
  ONE_HUNDRED_k_TO_500k = "100k - 500k",
  FIVE_HUNDRED_K_PLUS = "500k+",
  OTHER = "Other",
}

export enum OutreachType {
  EMAIL_ONLY = "Email Only",
  MULTI_CHANNEL = "Multi-Channel (Email + LinkedIn/Call/Text/Other)",
}
