import React, { useState, useEffect } from "react";
import { Button, ImageIcon, Input, Modal } from "@saleshandy/designs";
import { IProps } from "./types";
import { Images } from "../../../../shared/utils/app-constants";
import { isInviteMemberBtnDisable, isValidEmail } from "../../utils/helper";

const InviteTeamMemberModal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  isRequestPending,
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsValidEmail] = useState<boolean>(null);

  const isSubmitButtonDisable = isInviteMemberBtnDisable(
    isRequestPending,
    firstName,
    lastName,
    email
  );

  const handleSubmit = () => {
    const isEmailValid = isValidEmail(email);
    setIsValidEmail(!isEmailValid);

    if (isEmailValid) {
      onSubmit({ firstName, lastName, email });
    }
  };

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
  }, [show]);

  return (
    <Modal
      show={show}
      titleContent={
        <div className='d-flex'>
          <ImageIcon src={Images.UserPlus} />
          <div className='ml-2 semibold-3'>Invite new team member</div>
        </div>
      }
      showCloseIcon
      onClose={onClose}
      onHide={onClose}
      onSubmit={handleSubmit}
      submitButtonText='Invite'
      submitButtonClassName='btn-primary-disable'
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isSubmitButtonDisable}
      isCancelDisabled={isRequestPending}
      cancelButtonVarient={Button.Variant.Outlined}
      className='invite-team-member-modal modal-no-header-border'
    >
      <div>
        <div className='d-flex justify-content-between'>
          <Input
            placeholder='First Name...'
            value={firstName}
            onChange={(e) => setFirstName(e)}
            label='First Name*'
            className='name-input input-with-label'
          />
          <Input
            placeholder='Last Name...'
            value={lastName}
            onChange={(e) => setLastName(e)}
            label='Last Name'
            className='name-input input-with-label'
          />
        </div>
        <Input
          placeholder='Enter the email address...'
          value={email}
          onChange={(e) => setEmail(e)}
          label='Email Address*'
          className='input-with-label'
        />
        {isEmailValid && (
          <span className='regular-1 error-text'>
            Please enter valid email address
          </span>
        )}
      </div>
    </Modal>
  );
};

export default InviteTeamMemberModal;
