import React from "react";

import type { IProps } from "./subscription-container";

import SubscriptionInfo from "../subscription-info";
import StripePricingPage from "../stripe-pricing-page";

import { getIsUserSubscribed } from "../../helper/helper";

const Subscription: React.FC<IProps> = ({
  userId,
  email,
  planName,
  planCode,
  startAt,
  nextBillingAt,
  planAmount,
  stripeCredits,
  status,
}) => (
  <div className="subscription-container">
    {getIsUserSubscribed(planCode) ? (
      <SubscriptionInfo
        startAt={startAt}
        nextBillingAt={nextBillingAt}
        planName={planName}
        planAmount={planAmount}
        stripeCredits={stripeCredits}
        status={status}
      />
    ) : (
      <StripePricingPage userId={userId} email={email} />
    )}
  </div>
);

export default Subscription;
