import React, { useState, useRef, useEffect } from "react";
import { Avatar, Icon, ImageIcon } from "@saleshandy/designs";
import { DateTime } from "luxon";
import { Images } from "../../utils/app-constants";
import { useOutClickHook } from "../../utils/hooks";
import { AuthHelper, contactAdmin } from "../../utils";
import { IProps } from "./types";
import { accessibleOnClick } from "../../utils/accessible-on-click";
import { AgencyRole, SubscriptionPlans } from "../../enum";
import history from "../../history";

const MainHeader: React.FC<IProps> = ({
  firstName,
  lastName,
  email,
  subscription,
  role,
  adminFirstName,
  userFirstName,
}) => {
  const [subscriptionEndDays, setSubscriptionEndDays] = useState(null);
  const [showUpgradePlanBanner, setShowUpgradePlanBanner] = useState(false);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const closeDropDown = () => setShowDropDown(false);
  const toggleDropdown = () => setShowDropDown(!showDropDown);

  const dropdownRef = useRef(null);
  useOutClickHook(dropdownRef, closeDropDown);

  const onLogoutHandler = () => AuthHelper.logout();

  const onNeedHelpHandler = () => {
    window.open(process.env.REACT_APP_NEED_HELP_URL, "_blank");
  };

  const handlePlanCTA = () => {
    if (role === AgencyRole.AgencyOwner) {
      history.push({
        pathname: "/billing/subscription",
      });
    } else {
      const subject = "Request to Upgrade Saleshandy Agency Portal Account";
      const body = `Hello ${adminFirstName}, %0D%0A %0D%0ACould you please check and upgrade the Saleshandy Agency Portal account if required?. %0D%0A %0D%0AThanks, %0D%0A${userFirstName}`;

      contactAdmin(subject, body);
    }
  };

  const getPlanWarningMessage = () => {
    let text = "";
    let days = "";

    if (
      subscription?.planCode === SubscriptionPlans.FREE ||
      subscriptionEndDays === 0
    ) {
      text = "You are currently on a free plan.";
    } else {
      text = `Trial expires in`;

      if (subscriptionEndDays === 1) {
        days = ` ${subscriptionEndDays || 0} day.`;
      } else {
        days = ` ${subscriptionEndDays || 0} days.`;
      }
    }

    return (
      <>
        {text} <span>{days}</span>
      </>
    );
  };

  const getPlanCTALabel = () => {
    if (role === AgencyRole.AgencyOwner) {
      return "Upgrade Now";
    }

    return "Contact Owner";
  };

  useEffect(() => {
    if (subscription) {
      const days = Math.ceil(
        DateTime.fromJSDate(new Date(subscription.nextBillingAt), {
          zone: "Etc/UTC",
        })
          .diff(DateTime.now().toUTC(), ["days"])
          .toObject().days
      );
      setSubscriptionEndDays(days > 0 ? days : 0);
      setShowUpgradePlanBanner(
        subscription?.planCode === SubscriptionPlans.TRIAL ||
          subscription?.planCode === SubscriptionPlans.FREE
      );
    }
  }, [subscription]);

  return (
    <div ref={dropdownRef} className="header-container">
      <div className="header header__left-side">
        <ImageIcon src={Images.SaleshandyHeaderLogo} />
      </div>
      <div className="header header__right-side d-flex justify-content-between">
        {showUpgradePlanBanner && (
          <div className="trial-plan-banner">
            <ImageIcon src={Images.AlertTriangle} />
            <p>{getPlanWarningMessage()}</p>
            <button type="button" onClick={handlePlanCTA}>
              {getPlanCTALabel()}
            </button>
          </div>
        )}
        <div
          className={`user-wrapper ${showUpgradePlanBanner ? "separator" : ""}`}
        >
          <div
            {...accessibleOnClick(toggleDropdown)}
            className="current-user d-flex align-items-center"
          >
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="avatar mr-1"
            />
            <Icon identifier="chevron-down" />
          </div>
        </div>
      </div>
      {showDropDown && (
        <div className="header logout-wrapper">
          <div className="d-flex align-items-center mt-2 mb-2 ml-3">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="avatar mr-1"
            />
            <div className="d-flex flex-column pr-2">
              <span className="regular-2">{`${firstName} ${lastName}`}</span>
              <span className="font-size-10">{email}</span>
            </div>
          </div>
          <div
            {...accessibleOnClick(onNeedHelpHandler)}
            className="logout-btn-container"
          >
            <ImageIcon src={Images.InfoCircle} />
            <span className="regular-2 logout-txt">Need Help</span>
          </div>
          <div
            {...accessibleOnClick(onLogoutHandler)}
            className="logout-btn-container"
          >
            <ImageIcon src={Images.Logout} />
            <span className="regular-2 logout-txt">Logout</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainHeader;
