import React from "react";
import { isArray } from "../utils";
import { Icon } from "@saleshandy/designs";

type ValidationItem = {
  valid?: boolean;
  text?: string;
};

type IProps = {
  columns: (ValidationItem | ValidationItem[])[];
};

const ValidationChecklist: React.FC<IProps> = (props) => {
  const columns = props.columns.map((column) => {
    if (!isArray(column)) {
      column = [column as ValidationItem];
    }
    return column;
  }) as ValidationItem[][];

  return (
    <div className='password-validation-checklist-container'>
      {columns.map((column, columnIndex) => (
        <ul key={columnIndex}>
          {column.map((item, columnItemIndex) => (
            <li key={columnItemIndex}>
              <span>
                <Icon
                  identifier={item.valid ? "check-o" : "play-stop"}
                  className={!item.valid && "dot"}
                />
              </span>
              <p className='regular-1'>{item.text}</p>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default ValidationChecklist;
