import React, { useState, useEffect } from "react";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import Tabs, { TabPane } from "rc-tabs";
import { useLocation } from "react-router";

import type { IProps } from "./clients-container";
import EmptyContent from "./components/empty-content";
import AddClientModal from "./components/add-client-modal/add-client-modal";
import ClientsListTable from "./components/clients-list-table/clients-list-table";
import { SortBy, Order, AgencyRole } from "../../shared/enum";
import {
  checkIsFreePlan,
  executeOnRequestStatus,
  redirectWithoutRefresh,
} from "../../shared/utils";
import ClientsHeaderAction from "./components/clients-header-action";
import Teams from "../teams";
import Billing from "../billing";
import NoSearchFound from "../../shared/components/no-search-found";
import Loader from "../../shared/components/loader";
import DeleteClientModal from "./components/delete-client-modal";
import { getIsRequestPending } from "../../shared/utils/get-request-status";
import DisableClientConfirmationModal from "./components/disable-client-confirmation-modal";
import PlanRestrictionModal from "../../shared/components/plan-restriction-modal";

const Clients: React.FC<IProps> = ({
  getUserSettingsRequestStatus,
  sendGetClientsRequest,
  sendAddClientRequest,
  addClientRequestStatus,
  addClientRequestMessage,
  clients,
  clientsCount,
  oneClickLoginToken,
  toggleClientStatusRequestStatus,
  toggleClientStatusRequestMessage,
  sendToggleClientRequest,
  editClientRequestStatus,
  editClientRequestMessage,
  sendEditClientRequest,
  getClientsRequestStatus,
  getClientsRequestMessage,
  oneClickLoginRequestStatus,
  oneClickLoginRequestMessage,
  sendOneClickLoginRequest,
  deleteClientRequestStatus,
  deleteClientRequestMessage,
  sendDeleteClientRequest,
  firstName,
  role,
  loginUrl,
  planCode,
  adminFirstName,
}) => {
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState<string>("clients");
  const [isAddClientModalShow, setIsAddClientModalShow] =
    useState<boolean>(false);
  const [isDeleteClientModalShow, setIsDeleteClientModalShow] =
    useState<boolean>(false);
  const [
    isDisableClientConfirmationModalShow,
    setIsDisableClientConfirmationModalShow,
  ] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>("");
  const [clientId, setClientId] = useState<number>(null);
  const [isEditAction, setIsEditAction] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.CreatedAt);
  const [order, setOrder] = useState<Order>(Order.Descending);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [planRestrictionModal, setPlanRestrictionModal] = useState(false);

  const onTabChange = (tabKey) => {
    redirectWithoutRefresh(`/${tabKey}`);
    setActiveTabKey(tabKey);
  };

  const showDisableClientConfirmationModal = () => {
    setIsDisableClientConfirmationModalShow(true);
  };

  const hideDisableClientConfirmationModal = () => {
    setIsDisableClientConfirmationModalShow(false);
  };

  const showPlanRestrictionModal = () => {
    setPlanRestrictionModal(true);
  };

  const hidePlanRestrictionModal = () => {
    setPlanRestrictionModal(false);
  };

  const onToggleClientStatus = (checked, clientIdValue: number) => {
    setClientId(clientIdValue);
    if (!checked) {
      showDisableClientConfirmationModal();
    } else if (checkIsFreePlan(planCode)) {
      showPlanRestrictionModal();
    } else {
      sendToggleClientRequest(clientIdValue);
    }
  };

  const showDeleteClientModal = () => {
    setIsDeleteClientModalShow(true);
  };

  const hideDeleteClientModal = () => {
    setIsDeleteClientModalShow(false);
  };

  const onDeleteClientModalSubmit = () => {
    sendDeleteClientRequest(clientId);
  };

  // On Search
  const onSearchChangeHandler = (newSearch: string) => {
    setSearch(newSearch);
    sendGetClientsRequest({
      page: currentPage,
      sortByKey: sortBy,
      order,
      search: newSearch,
    });
  };

  // On Edit
  const resetClientsData = () => {
    setClientName("");
    setClientId(null);
    setIsEditAction(false);
  };

  // Add Client
  const showAddClientModal = () => {
    if (checkIsFreePlan(planCode)) {
      showPlanRestrictionModal();
    } else {
      setIsAddClientModalShow(true);
    }
  };

  const hideAddClientModal = () => {
    setIsAddClientModalShow(false);
    resetClientsData();
  };

  const onAddClientModalSubmit = (payload) => {
    if (isEditAction) {
      sendEditClientRequest({ clientId, ...payload });
    } else {
      sendAddClientRequest(payload);
    }
  };

  // Delete Client
  const handleDeleteAction = (clientIdValue: number) => {
    setClientId(clientIdValue);
    showDeleteClientModal();
  };

  const handleEditAction = (clientNameValue: string, clientIdValue: number) => {
    setClientName(clientNameValue);
    setClientId(clientIdValue);
    setIsEditAction(true);
    showAddClientModal();
  };

  const fetchClientsData = (newPartialOptions) => {
    sendGetClientsRequest({
      page: 1,
      sortByKey: sortBy,
      order,
      withAssigneeData: false,
      ...newPartialOptions,
    });
  };

  // One Click Login
  const handelOneClickLogin = (id) => {
    if (checkIsFreePlan(planCode)) {
      showPlanRestrictionModal();
    } else {
      sendOneClickLoginRequest(id);
    }
  };

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
    fetchClientsData({
      page,
    });
  };

  const onSortHandler = (sortByValue: SortBy, orderValue: string) => {
    const sortOrder = orderValue === "asc" ? Order.Ascending : Order.Descending;
    setSortBy(sortByValue);
    setOrder(sortOrder);
    fetchClientsData({ sortByKey: sortBy, order: sortOrder });
  };

  // Render Functions
  const renderClientList = () => {
    if (clientsCount === 0 && search) {
      return <NoSearchFound />;
    }
    return (
      <ClientsListTable
        clients={clients}
        role={role}
        sendToggleClientRequest={(checked, id) =>
          onToggleClientStatus(checked, id)
        }
        onEditAction={(clientNameValue, id) =>
          handleEditAction(clientNameValue, id)
        }
        onDeleteAction={(id) => handleDeleteAction(id)}
        onOneClickLoginAction={(id) => handelOneClickLogin(id)}
        clientsCount={clientsCount}
        onPageChange={(page) => onPageChangeHandler(page)}
        sort={{
          sortBy,
          order,
        }}
        onSortHandler={(sortByValue, orderValue) =>
          onSortHandler(sortByValue, orderValue)
        }
        currentPage={currentPage}
        isLoading={getIsRequestPending(getClientsRequestStatus)}
      />
    );
  };

  const renderClientsView = () => {
    if (clientsCount > 0 || search.length !== 0) {
      return (
        <>
          <ClientsHeaderAction
            showAddClientModal={showAddClientModal}
            onChange={onSearchChangeHandler}
            role={role}
          />
          {renderClientList()}
        </>
      );
    }
    if (role !== AgencyRole.AgencyUser) {
      return <EmptyContent showAddClientModal={showAddClientModal} />;
    }

    return <></>;
  };

  useEffect(() => {
    if (location.pathname === "/team") {
      setActiveTabKey("team");
    } else if (location.pathname.includes("/billing")) {
      setActiveTabKey("billing");
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchClientsData({});
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getClientsRequestStatus,
      onFailed: () => {
        toaster.error(getClientsRequestMessage);
      },
    });
  }, [getClientsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addClientRequestStatus,
      onSuccess: () => {
        hideAddClientModal();
        toaster.success(addClientRequestMessage, {
          theme: Theme.New,
        });
        fetchClientsData({});
      },
      onFailed: () => {
        toaster.error(addClientRequestMessage);
      },
    });
  }, [addClientRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: toggleClientStatusRequestStatus,
      onSuccess: () => {
        fetchClientsData({ page: currentPage });
        hideDisableClientConfirmationModal();
      },
      onFailed: () => {
        toaster.error(toggleClientStatusRequestMessage);
      },
    });
  }, [toggleClientStatusRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: editClientRequestStatus,
      onSuccess: () => {
        hideAddClientModal();
        fetchClientsData({ page: currentPage });
        resetClientsData();
        toaster.success("Client has been updated successfully", {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        toaster.error(editClientRequestMessage);
      },
    });
  }, [editClientRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteClientRequestStatus,
      onSuccess: () => {
        hideDeleteClientModal();
        fetchClientsData({});
        resetClientsData();
        toaster.success("Client has been deleted successfully", {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        toaster.error(deleteClientRequestMessage);
      },
    });
  }, [deleteClientRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: oneClickLoginRequestStatus,
      onSuccess: () => {
        window.open(
          `${loginUrl}/sequence?token=${oneClickLoginToken}`,
          "_blank"
        );
      },
      onFailed: () => {
        toaster.error(oneClickLoginRequestMessage);
      },
    });
  }, [oneClickLoginRequestStatus]);

  if (
    (getIsRequestPending(getClientsRequestStatus) ||
      getIsRequestPending(getUserSettingsRequestStatus)) &&
    (!clients || !role)
  ) {
    return <Loader />;
  }

  if (
    clients?.length === 0 &&
    role === AgencyRole.AgencyUser &&
    search.length === 0
  ) {
    return <NoSearchFound isEmptyData={true} />;
  }

  return (
    <>
      <div className="clients">
        <Tabs
          key={activeTabKey}
          activeKey={activeTabKey}
          prefixCls="bs-tabs"
          className="bs-tabs-large"
          onChange={(tabKey) => onTabChange(tabKey)}
        >
          <TabPane tab="Clients" key="clients">
            <div className="clients__content">{renderClientsView()}</div>
          </TabPane>
          <TabPane tab="Team" key="team">
            <Teams />
          </TabPane>
          <TabPane tab="Billing" key="billing">
            <Billing />
          </TabPane>
        </Tabs>
      </div>
      <AddClientModal
        show={isAddClientModalShow}
        onClose={hideAddClientModal}
        onSubmit={(payload) => onAddClientModalSubmit(payload)}
        clientName={clientName}
        isEditAction={isEditAction}
        isRequestPending={
          getIsRequestPending(addClientRequestStatus) ||
          getIsRequestPending(editClientRequestStatus)
        }
      />
      <DeleteClientModal
        show={isDeleteClientModalShow}
        onClose={hideDeleteClientModal}
        onSubmit={onDeleteClientModalSubmit}
        isRequestPending={getIsRequestPending(deleteClientRequestStatus)}
      />
      <DisableClientConfirmationModal
        show={isDisableClientConfirmationModalShow}
        onClose={hideDisableClientConfirmationModal}
        onSubmit={() => sendToggleClientRequest(clientId)}
        isRequestPending={getIsRequestPending(toggleClientStatusRequestStatus)}
      />
      <PlanRestrictionModal
        show={planRestrictionModal}
        onClose={hidePlanRestrictionModal}
        role={role}
        adminFirstName={adminFirstName}
        userFirstName={firstName}
      />
    </>
  );
};

export default Clients;
