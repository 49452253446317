import React from "react";
import axios from "axios";
import queryString from "query-string";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";

import { IProps } from "./types";
import { redirectWithoutRefresh } from "../../../../shared/utils";
import routes from "../../../../routes";

class VerifyEmailCallback extends React.Component<IProps, null> {
  async componentDidMount() {
    const { location, showLoading, hideLoading, sendGetUserSettingsRequest } =
      this.props;

    const { verificationToken } = queryString.parse(location.search);

    showLoading();

    await axios
      .get(`${process.env.REACT_APP_API_URL}/agency/handle-verification-link`, {
        headers: { Authorization: `Bearer ${verificationToken}` },
      })
      .then(() => {
        toaster.success("Email verified successfully.", { theme: Theme.New });
      })
      .catch(() => {
        toaster.error("Verification link has expired.", {
          theme: Theme.New,
        });
      })
      .finally(() => {
        sendGetUserSettingsRequest();
        redirectWithoutRefresh(routes.CLIENTS);
        hideLoading();
      });
  }

  render() {
    return null;
  }
}

export default VerifyEmailCallback;
