import React, { useEffect } from "react";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";

import type { IProps } from "./usage-container";
import { getUsageData } from "../../helper/get-usage-data";
import { BillingsAndSubscriptionsTabs } from "../../../../shared/enum";
import { executeOnRequestStatus } from "../../../../shared/utils";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";

import AccountPlanUsage from "../account-plan-usage";
import EVCreditsDetails from "../ev-credits-details";

const Usage: React.FC<IProps> = ({
  activeTab,

  evCredits,
  getEVCreditsRequestStatus,
  getEVCreditsRequestError,
  sendGetEVCreditsRequest,
  resetGetEVCreditsRequest,

  accountPlanUsage,
  getAccountPlanUsageRequestStatus,
  getAccountPlanUsageRequestError,
  sendGetAccountPlanUsageRequest,
  resetGetAccountPlanUsageRequest,
}) => {
  const handleRefresh = () => {
    sendGetAccountPlanUsageRequest();
  };

  useEffect(() => {
    if (activeTab === BillingsAndSubscriptionsTabs.Usage) {
      sendGetEVCreditsRequest();
      sendGetAccountPlanUsageRequest();
    }
  }, [activeTab]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEVCreditsRequestStatus,
      onSuccess: () => {
        resetGetEVCreditsRequest();
      },
      onFailed: () => {
        if (getEVCreditsRequestError?.message) {
          toaster.error(getEVCreditsRequestError.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [getEVCreditsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAccountPlanUsageRequestStatus,
      onSuccess: () => {
        resetGetAccountPlanUsageRequest();
      },
      onFailed: () => {
        if (getAccountPlanUsageRequestError?.message) {
          toaster.error(getAccountPlanUsageRequestError.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [getAccountPlanUsageRequestStatus]);

  const usagesData = getUsageData(accountPlanUsage);

  return (
    <>
      <div className="subscription-plan__container mb-4">
        <AccountPlanUsage
          usagesData={usagesData}
          quotaResetDate={accountPlanUsage?.nextBillingDate}
          handleRefresh={handleRefresh}
          loadingAccountUsage={getIsRequestPending(
            getAccountPlanUsageRequestStatus
          )}
        />
      </div>
      <div className="billing-subscription-container__ev-credits">
        <EVCreditsDetails availableCredits={evCredits ?? 0} />
      </div>
    </>
  );
};

export default Usage;
