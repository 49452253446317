const cityTimezoneCountryMapping: Record<
  string,
  { country: string; code: string }
> = {
  Andorra: {
    country: 'Andorra',
    code: 'AD',
  },
  Dubai: {
    country: 'United Arab Emirates',
    code: 'AE',
  },
  Kabul: {
    country: 'Afghanistan',
    code: 'AF',
  },
  Tirane: {
    country: 'Albania',
    code: 'AL',
  },
  Yerevan: {
    country: 'Armenia',
    code: 'AM',
  },
  Casey: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Davis: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Mawson: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Palmer: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Rothera: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Troll: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Vostok: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Buenos_Aires: {
    country: 'Argentina',
    code: 'AR',
  },
  Cordoba: {
    country: 'Argentina',
    code: 'AR',
  },
  Salta: {
    country: 'Argentina',
    code: 'AR',
  },
  Jujuy: {
    country: 'Argentina',
    code: 'AR',
  },
  Tucuman: {
    country: 'Argentina',
    code: 'AR',
  },
  Catamarca: {
    country: 'Argentina',
    code: 'AR',
  },
  La_Rioja: {
    country: 'Argentina',
    code: 'AR',
  },
  San_Juan: {
    country: 'Argentina',
    code: 'AR',
  },
  Mendoza: {
    country: 'Argentina',
    code: 'AR',
  },
  San_Luis: {
    country: 'Argentina',
    code: 'AR',
  },
  Rio_Gallegos: {
    country: 'Argentina',
    code: 'AR',
  },
  Ushuaia: {
    country: 'Argentina',
    code: 'AR',
  },
  Pago_Pago: {
    country: 'Samoa (American)',
    code: 'AS',
  },
  Vienna: {
    country: 'Austria',
    code: 'AT',
  },
  Lord_Howe: {
    country: 'Australia',
    code: 'AU',
  },
  Macquarie: {
    country: 'Australia',
    code: 'AU',
  },
  Hobart: {
    country: 'Australia',
    code: 'AU',
  },
  Melbourne: {
    country: 'Australia',
    code: 'AU',
  },

  Sydney: {
    country: 'Australia',
    code: 'AU',
  },
  Broken_Hill: {
    country: 'Australia',
    code: 'AU',
  },
  Brisbane: {
    country: 'Australia',
    code: 'AU',
  },
  Lindeman: {
    country: 'Australia',
    code: 'AU',
  },
  Adelaide: {
    country: 'Australia',
    code: 'AU',
  },
  Darwin: {
    country: 'Australia',
    code: 'AU',
  },
  Perth: {
    country: 'Australia',
    code: 'AU',
  },
  Eucla: {
    country: 'Australia',
    code: 'AU',
  },
  Baku: {
    country: 'Azerbaijan',
    code: 'AZ',
  },
  Barbados: {
    country: 'Barbados',
    code: 'BB',
  },
  Dhaka: {
    country: 'Bangladesh',
    code: 'BD',
  },
  Brussels: {
    country: 'Belgium',
    code: 'BE',
  },
  Sofia: {
    country: 'Bulgaria',
    code: 'BG',
  },
  Bermuda: {
    country: 'Bermuda',
    code: 'BM',
  },
  Brunei: {
    country: 'Brunei',
    code: 'BN',
  },
  La_Paz: {
    country: 'Bolivia',
    code: 'BO',
  },
  Noronha: {
    country: 'Brazil',
    code: 'BR',
  },
  Belem: {
    country: 'Brazil',
    code: 'BR',
  },
  Fortaleza: {
    country: 'Brazil',
    code: 'BR',
  },
  Recife: {
    country: 'Brazil',
    code: 'BR',
  },
  Araguaina: {
    country: 'Brazil',
    code: 'BR',
  },
  Maceio: {
    country: 'Brazil',
    code: 'BR',
  },
  Bahia: {
    country: 'Brazil',
    code: 'BR',
  },
  Sao_Paulo: {
    country: 'Brazil',
    code: 'BR',
  },
  Campo_Grande: {
    country: 'Brazil',
    code: 'BR',
  },
  Cuiaba: {
    country: 'Brazil',
    code: 'BR',
  },
  Santarem: {
    country: 'Brazil',
    code: 'BR',
  },
  Porto_Velho: {
    country: 'Brazil',
    code: 'BR',
  },
  Boa_Vista: {
    country: 'Brazil',
    code: 'BR',
  },
  Manaus: {
    country: 'Brazil',
    code: 'BR',
  },
  Eirunepe: {
    country: 'Brazil',
    code: 'BR',
  },
  Rio_Branco: {
    country: 'Brazil',
    code: 'BR',
  },
  Thimphu: {
    country: 'Bhutan',
    code: 'BT',
  },
  Minsk: {
    country: 'Belarus',
    code: 'BY',
  },
  Belize: {
    country: 'Belize',
    code: 'BZ',
  },
  St_Johns: {
    country: 'Canada',
    code: 'CA',
  },
  Halifax: {
    country: 'Canada',
    code: 'CA',
  },
  Glace_Bay: {
    country: 'Canada',
    code: 'CA',
  },
  Moncton: {
    country: 'Canada',
    code: 'CA',
  },
  Goose_Bay: {
    country: 'Canada',
    code: 'CA',
  },
  Toronto: {
    country: 'Canada',
    code: 'CA',
  },
  Nipigon: {
    country: 'Canada',
    code: 'CA',
  },
  Thunder_Bay: {
    country: 'Canada',
    code: 'CA',
  },
  Iqaluit: {
    country: 'Canada',
    code: 'CA',
  },
  Pangnirtung: {
    country: 'Canada',
    code: 'CA',
  },
  Winnipeg: {
    country: 'Canada',
    code: 'CA',
  },
  Rainy_River: {
    country: 'Canada',
    code: 'CA',
  },
  Resolute: {
    country: 'Canada',
    code: 'CA',
  },
  Rankin_Inlet: {
    country: 'Canada',
    code: 'CA',
  },
  Regina: {
    country: 'Canada',
    code: 'CA',
  },
  Swift_Current: {
    country: 'Canada',
    code: 'CA',
  },
  Edmonton: {
    country: 'Canada',
    code: 'CA',
  },
  Cambridge_Bay: {
    country: 'Canada',
    code: 'CA',
  },
  Yellowknife: {
    country: 'Canada',
    code: 'CA',
  },
  Inuvik: {
    country: 'Canada',
    code: 'CA',
  },
  Dawson_Creek: {
    country: 'Canada',
    code: 'CA',
  },
  Fort_Nelson: {
    country: 'Canada',
    code: 'CA',
  },
  Whitehorse: {
    country: 'Canada',
    code: 'CA',
  },
  Dawson: {
    country: 'Canada',
    code: 'CA',
  },
  Vancouver: {
    country: 'Canada',
    code: 'CA',
  },

  Cocos: {
    country: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  Zurich: {
    country: 'Switzerland',
    code: 'CH',
  },
  Abidjan: {
    country: "Côte d'Ivoire",
    code: 'CI',
  },
  Rarotonga: {
    country: 'Cook Islands',
    code: 'CK',
  },
  Santiago: {
    country: 'Chile',
    code: 'CL',
  },
  Punta_Arenas: {
    country: 'Chile',
    code: 'CL',
  },
  Easter: {
    country: 'Chile',
    code: 'CL',
  },
  Shanghai: {
    country: 'China',
    code: 'CN',
  },
  Urumqi: {
    country: 'China',
    code: 'CN',
  },
  Bogota: {
    country: 'Colombia',
    code: 'CO',
  },
  Costa_Rica: {
    country: 'Costa Rica',
    code: 'CR',
  },
  Havana: {
    country: 'Cuba',
    code: 'CU',
  },
  Cape_Verde: {
    country: 'Cape Verde',
    code: 'CV',
  },
  Christmas: {
    country: 'Christmas Island',
    code: 'CX',
  },
  Nicosia: {
    country: 'Cyprus',
    code: 'CY',
  },
  Famagusta: {
    country: 'Cyprus',
    code: 'CY',
  },
  Prague: {
    country: 'Czech Republic',
    code: 'CZ',
  },
  Berlin: {
    country: 'Germany',
    code: 'DE',
  },
  Copenhagen: {
    country: 'Denmark',
    code: 'DK',
  },
  Santo_Domingo: {
    country: 'Dominican Republic',
    code: 'DO',
  },
  Algiers: {
    country: 'Algeria',
    code: 'DZ',
  },
  Guayaquil: {
    country: 'Ecuador',
    code: 'EC',
  },
  Galapagos: {
    country: 'Ecuador',
    code: 'EC',
  },
  Tallinn: {
    country: 'Estonia',
    code: 'EE',
  },
  Cairo: {
    country: 'Egypt',
    code: 'EG',
  },
  El_Aaiun: {
    country: 'Western Sahara',
    code: 'EH',
  },
  Madrid: {
    country: 'Spain',
    code: 'ES',
  },
  Ceuta: {
    country: 'Spain',
    code: 'ES',
  },
  Canary: {
    country: 'Spain',
    code: 'ES',
  },
  Helsinki: {
    country: 'Finland',
    code: 'FI',
  },
  Fiji: {
    country: 'Fiji',
    code: 'FJ',
  },
  Stanley: {
    country: 'Falkland Islands',
    code: 'FK',
  },
  Chuuk: {
    country: 'Micronesia',
    code: 'FM',
  },
  Pohnpei: {
    country: 'Micronesia',
    code: 'FM',
  },
  Kosrae: {
    country: 'Micronesia',
    code: 'FM',
  },
  Faroe: {
    country: 'Faroe Islands',
    code: 'FO',
  },
  Paris: {
    country: 'France',
    code: 'FR',
  },
  London: {
    country: 'Britain (UK)',
    code: 'GB',
  },
  Tbilisi: {
    country: 'Georgia',
    code: 'GE',
  },
  Cayenne: {
    country: 'French Guiana',
    code: 'GF',
  },
  Gibraltar: {
    country: 'Gibraltar',
    code: 'GI',
  },
  Nuuk: {
    country: 'Greenland',
    code: 'GL',
  },
  Danmarkshavn: {
    country: 'Greenland',
    code: 'GL',
  },
  Scoresbysund: {
    country: 'Greenland',
    code: 'GL',
  },
  Thule: {
    country: 'Greenland',
    code: 'GL',
  },
  Athens: {
    country: 'Greece',
    code: 'GR',
  },
  South_Georgia: {
    country: 'South Georgia & the South Sandwich Islands',
    code: 'GS',
  },
  Guatemala: {
    country: 'Guatemala',
    code: 'GT',
  },
  Guam: {
    country: 'Guam',
    code: 'GU',
  },

  Bissau: {
    country: 'Guinea-Bissau',
    code: 'GW',
  },
  Guyana: {
    country: 'Guyana',
    code: 'GY',
  },
  Hong_Kong: {
    country: 'Hong Kong',
    code: 'HK',
  },
  Tegucigalpa: {
    country: 'Honduras',
    code: 'HN',
  },
  'Port-au-Prince': {
    country: 'Haiti',
    code: 'HT',
  },
  Budapest: {
    country: 'Hungary',
    code: 'HU',
  },
  Jakarta: {
    country: 'Indonesia',
    code: 'ID',
  },
  Pontianak: {
    country: 'Indonesia',
    code: 'ID',
  },
  Makassar: {
    country: 'Indonesia',
    code: 'ID',
  },
  Jayapura: {
    country: 'Indonesia',
    code: 'ID',
  },
  Dublin: {
    country: 'Ireland',
    code: 'IE',
  },
  Jerusalem: {
    country: 'Israel',
    code: 'IL',
  },
  Kolkata: {
    country: 'India',
    code: 'IN',
  },
  Calcutta: {
    country: 'India',
    code: 'IN',
  },
  Chagos: {
    country: 'British Indian Ocean Territory',
    code: 'IO',
  },
  Baghdad: {
    country: 'Iraq',
    code: 'IQ',
  },
  Tehran: {
    country: 'Iran',
    code: 'IR',
  },
  Reykjavik: {
    country: 'Iceland',
    code: 'IS',
  },
  Rome: {
    country: 'Italy',
    code: 'IT',
  },
  Jamaica: {
    country: 'Jamaica',
    code: 'JM',
  },
  Amman: {
    country: 'Jordan',
    code: 'JO',
  },
  Tokyo: {
    country: 'Japan',
    code: 'JP',
  },
  Nairobi: {
    country: 'Kenya',
    code: 'KE',
  },
  Bishkek: {
    country: 'Kyrgyzstan',
    code: 'KG',
  },
  Tarawa: {
    country: 'Kiribati',
    code: 'KI',
  },
  Kanton: {
    country: 'Kiribati',
    code: 'KI',
  },
  Kiritimati: {
    country: 'Kiribati',
    code: 'KI',
  },
  Pyongyang: {
    country: 'Korea (North)',
    code: 'KP',
  },
  Seoul: {
    country: 'Korea (South)',
    code: 'KR',
  },

  Almaty: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Qyzylorda: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Qostanay: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Aqtobe: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Aqtau: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Atyrau: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Oral: {
    country: 'Kazakhstan',
    code: 'KZ',
  },
  Beirut: {
    country: 'Lebanon',
    code: 'LB',
  },
  Colombo: {
    country: 'Sri Lanka',
    code: 'LK',
  },
  Monrovia: {
    country: 'Liberia',
    code: 'LR',
  },
  Vilnius: {
    country: 'Lithuania',
    code: 'LT',
  },
  Luxembourg: {
    country: 'Luxembourg',
    code: 'LU',
  },
  Riga: {
    country: 'Latvia',
    code: 'LV',
  },
  Tripoli: {
    country: 'Libya',
    code: 'LY',
  },
  Casablanca: {
    country: 'Morocco',
    code: 'MA',
  },
  Monaco: {
    country: 'Monaco',
    code: 'MC',
  },
  Chisinau: {
    country: 'Moldova',
    code: 'MD',
  },
  Majuro: {
    country: 'Marshall Islands',
    code: 'MH',
  },
  Kwajalein: {
    country: 'Marshall Islands',
    code: 'MH',
  },

  Yangon: {
    country: 'Myanmar (Burma)',
    code: 'MM',
  },
  Ulaanbaatar: {
    country: 'Mongolia',
    code: 'MN',
  },
  Hovd: {
    country: 'Mongolia',
    code: 'MN',
  },
  Choibalsan: {
    country: 'Mongolia',
    code: 'MN',
  },
  Macau: {
    country: 'Macau',
    code: 'MO',
  },
  Martinique: {
    country: 'Martinique',
    code: 'MQ',
  },
  Malta: {
    country: 'Malta',
    code: 'MT',
  },
  Mauritius: {
    country: 'Mauritius',
    code: 'MU',
  },
  Maldives: {
    country: 'Maldives',
    code: 'MV',
  },
  Mexico_City: {
    country: 'Mexico',
    code: 'MX',
  },
  Cancun: {
    country: 'Mexico',
    code: 'MX',
  },
  Merida: {
    country: 'Mexico',
    code: 'MX',
  },
  Monterrey: {
    country: 'Mexico',
    code: 'MX',
  },
  Matamoros: {
    country: 'Mexico',
    code: 'MX',
  },
  Mazatlan: {
    country: 'Mexico',
    code: 'MX',
  },
  Chihuahua: {
    country: 'Mexico',
    code: 'MX',
  },
  Ojinaga: {
    country: 'Mexico',
    code: 'MX',
  },
  Hermosillo: {
    country: 'Mexico',
    code: 'MX',
  },
  Tijuana: {
    country: 'Mexico',
    code: 'MX',
  },
  Bahia_Banderas: {
    country: 'Mexico',
    code: 'MX',
  },
  Kuala_Lumpur: {
    country: 'Malaysia',
    code: 'MY',
  },
  Kuching: {
    country: 'Malaysia',
    code: 'MY',
  },
  Maputo: {
    country: 'Mozambique',
    code: 'MZ',
  },
  Windhoek: {
    country: 'Namibia',
    code: 'NA',
  },
  Noumea: {
    country: 'New Caledonia',
    code: 'NC',
  },
  Norfolk: {
    country: 'Norfolk Island',
    code: 'NF',
  },
  Lagos: {
    country: 'Nigeria',
    code: 'NG',
  },
  Managua: {
    country: 'Nicaragua',
    code: 'NI',
  },
  Amsterdam: {
    country: 'Netherlands',
    code: 'NL',
  },
  Oslo: {
    country: 'Norway',
    code: 'NO',
  },
  Kathmandu: {
    country: 'Nepal',
    code: 'NP',
  },
  Nauru: {
    country: 'Nauru',
    code: 'NR',
  },
  Niue: {
    country: 'Niue',
    code: 'NU',
  },
  Auckland: {
    country: 'New Zealand',
    code: 'NZ',
  },
  Chatham: {
    country: 'New Zealand',
    code: 'NZ',
  },
  Panama: {
    country: 'Panama',
    code: 'PA',
  },
  Lima: {
    country: 'Peru',
    code: 'PE',
  },
  Tahiti: {
    country: 'French Polynesia',
    code: 'PF',
  },
  Marquesas: {
    country: 'French Polynesia',
    code: 'PF',
  },
  Gambier: {
    country: 'French Polynesia',
    code: 'PF',
  },
  Port_Moresby: {
    country: 'Papua New Guinea',
    code: 'PG',
  },
  Bougainville: {
    country: 'Papua New Guinea',
    code: 'PG',
  },
  Manila: {
    country: 'Philippines',
    code: 'PH',
  },
  Karachi: {
    country: 'Pakistan',
    code: 'PK',
  },
  Warsaw: {
    country: 'Poland',
    code: 'PL',
  },
  Miquelon: {
    country: 'St Pierre & Miquelon',
    code: 'PM',
  },
  Pitcairn: {
    country: 'Pitcairn',
    code: 'PN',
  },
  Puerto_Rico: {
    country: 'Puerto Rico',
    code: 'PR',
  },
  Gaza: {
    country: 'Palestine',
    code: 'PS',
  },
  Hebron: {
    country: 'Palestine',
    code: 'PS',
  },
  Lisbon: {
    country: 'Portugal',
    code: 'PT',
  },
  Madeira: {
    country: 'Portugal',
    code: 'PT',
  },
  Azores: {
    country: 'Portugal',
    code: 'PT',
  },
  Palau: {
    country: 'Palau',
    code: 'PW',
  },
  Asuncion: {
    country: 'Paraguay',
    code: 'PY',
  },
  Qatar: {
    country: 'Qatar',
    code: 'QA',
  },
  Reunion: {
    country: 'Réunion',
    code: 'RE',
  },
  Bucharest: {
    country: 'Romania',
    code: 'RO',
  },
  Belgrade: {
    country: 'Serbia',
    code: 'RS',
  },
  Kaliningrad: {
    country: 'Russia',
    code: 'RU',
  },
  Moscow: {
    country: 'Russia',
    code: 'RU',
  },
  Simferopol: {
    country: 'Russia',
    code: 'RU',
  },
  Kirov: {
    country: 'Russia',
    code: 'RU',
  },
  Astrakhan: {
    country: 'Russia',
    code: 'RU',
  },
  Volgograd: {
    country: 'Russia',
    code: 'RU',
  },
  Saratov: {
    country: 'Russia',
    code: 'RU',
  },
  Ulyanovsk: {
    country: 'Russia',
    code: 'RU',
  },
  Samara: {
    country: 'Russia',
    code: 'RU',
  },
  Yekaterinburg: {
    country: 'Russia',
    code: 'RU',
  },
  Omsk: {
    country: 'Russia',
    code: 'RU',
  },
  Novosibirsk: {
    country: 'Russia',
    code: 'RU',
  },
  Barnaul: {
    country: 'Russia',
    code: 'RU',
  },
  Tomsk: {
    country: 'Russia',
    code: 'RU',
  },
  Novokuznetsk: {
    country: 'Russia',
    code: 'RU',
  },
  Krasnoyarsk: {
    country: 'Russia',
    code: 'RU',
  },
  Irkutsk: {
    country: 'Russia',
    code: 'RU',
  },
  Chita: {
    country: 'Russia',
    code: 'RU',
  },
  Yakutsk: {
    country: 'Russia',
    code: 'RU',
  },
  Khandyga: {
    country: 'Russia',
    code: 'RU',
  },
  Vladivostok: {
    country: 'Russia',
    code: 'RU',
  },
  'Ust-Nera': {
    country: 'Russia',
    code: 'RU',
  },
  Magadan: {
    country: 'Russia',
    code: 'RU',
  },
  Sakhalin: {
    country: 'Russia',
    code: 'RU',
  },
  Srednekolymsk: {
    country: 'Russia',
    code: 'RU',
  },
  Kamchatka: {
    country: 'Russia',
    code: 'RU',
  },
  Anadyr: {
    country: 'Russia',
    code: 'RU',
  },

  Riyadh: {
    country: 'Saudi Arabia',
    code: 'SA',
  },
  Guadalcanal: {
    country: 'Solomon Islands',
    code: 'SB',
  },

  Seychelles: {
    country: 'Seychelles',
    code: 'SC',
  },
  Khartoum: {
    country: 'Sudan',
    code: 'SD',
  },
  Stockholm: {
    country: 'Sweden',
    code: 'SE',
  },
  Singapore: {
    country: 'Singapore',
    code: 'SG',
  },

  Paramaribo: {
    country: 'Suriname',
    code: 'SR',
  },
  Juba: {
    country: 'South Sudan',
    code: 'SS',
  },
  Sao_Tome: {
    country: 'Sao Tome & Principe',
    code: 'ST',
  },
  El_Salvador: {
    country: 'El Salvador',
    code: 'SV',
  },
  Damascus: {
    country: 'Syria',
    code: 'SY',
  },
  Grand_Turk: {
    country: 'Turks & Caicos Is',
    code: 'TC',
  },
  Ndjamena: {
    country: 'Chad',
    code: 'TD',
  },
  Kerguelen: {
    country: 'French Southern & Antarctic Lands',
    code: 'TF',
  },
  Bangkok: {
    country: 'Thailand',
    code: 'TH',
  },
  Dushanbe: {
    country: 'Tajikistan',
    code: 'TJ',
  },
  Fakaofo: {
    country: 'Tokelau',
    code: 'TK',
  },
  Dili: {
    country: 'East Timor',
    code: 'TL',
  },
  Ashgabat: {
    country: 'Turkmenistan',
    code: 'TM',
  },
  Tunis: {
    country: 'Tunisia',
    code: 'TN',
  },
  Tongatapu: {
    country: 'Tonga',
    code: 'TO',
  },
  Istanbul: {
    country: 'Turkey',
    code: 'TR',
  },
  Funafuti: {
    country: 'Tuvalu',
    code: 'TV',
  },
  Taipei: {
    country: 'Taiwan',
    code: 'TW',
  },
  Kiev: {
    country: 'Ukraine',
    code: 'UA',
  },
  Uzhgorod: {
    country: 'Ukraine',
    code: 'UA',
  },
  Zaporozhye: {
    country: 'Ukraine',
    code: 'UA',
  },
  Wake: {
    country: 'US minor outlying islands',
    code: 'UM',
  },
  New_York: {
    country: 'United States',
    code: 'US',
  },
  Detroit: {
    country: 'United States',
    code: 'US',
  },
  Louisville: {
    country: 'United States',
    code: 'US',
  },
  Monticello: {
    country: 'United States',
    code: 'US',
  },
  Indianapolis: {
    country: 'United States',
    code: 'US',
  },
  Vincennes: {
    country: 'United States',
    code: 'US',
  },
  Winamac: {
    country: 'United States',
    code: 'US',
  },
  Marengo: {
    country: 'United States',
    code: 'US',
  },
  Petersburg: {
    country: 'United States',
    code: 'US',
  },
  Vevay: {
    country: 'United States',
    code: 'US',
  },
  Chicago: {
    country: 'United States',
    code: 'US',
  },
  Tell_City: {
    country: 'United States',
    code: 'US',
  },
  Knox: {
    country: 'United States',
    code: 'US',
  },
  Menominee: {
    country: 'United States',
    code: 'US',
  },
  Center: {
    country: 'United States',
    code: 'US',
  },
  New_Salem: {
    country: 'United States',
    code: 'US',
  },
  Beulah: {
    country: 'United States',
    code: 'US',
  },
  Denver: {
    country: 'United States',
    code: 'US',
  },
  Boise: {
    country: 'United States',
    code: 'US',
  },
  Phoenix: {
    country: 'United States',
    code: 'US',
  },
  Los_Angeles: {
    country: 'United States',
    code: 'US',
  },
  Anchorage: {
    country: 'United States',
    code: 'US',
  },
  Juneau: {
    country: 'United States',
    code: 'US',
  },
  Sitka: {
    country: 'United States',
    code: 'US',
  },
  Metlakatla: {
    country: 'United States',
    code: 'US',
  },
  Yakutat: {
    country: 'United States',
    code: 'US',
  },
  Nome: {
    country: 'United States',
    code: 'US',
  },
  Adak: {
    country: 'United States',
    code: 'US',
  },
  Honolulu: {
    country: 'United States',
    code: 'US',
  },

  Montevideo: {
    country: 'Uruguay',
    code: 'UY',
  },
  Samarkand: {
    country: 'Uzbekistan',
    code: 'UZ',
  },
  Tashkent: {
    country: 'Uzbekistan',
    code: 'UZ',
  },
  Caracas: {
    country: 'Venezuela',
    code: 'VE',
  },
  Ho_Chi_Minh: {
    country: 'Vietnam',
    code: 'VN',
  },
  Efate: {
    country: 'Vanuatu',
    code: 'VU',
  },
  Wallis: {
    country: 'Wallis & Futuna',
    code: 'WF',
  },
  Apia: {
    country: 'Samoa (western)',
    code: 'WS',
  },
  Johannesburg: {
    country: 'South Africa',
    code: 'ZA',
  },
  Antigua: {
    country: 'Antigua & Barbuda',
    code: 'AG',
  },
  Anguilla: {
    country: 'Anguilla',
    code: 'AI',
  },
  Luanda: {
    country: 'Angola',
    code: 'AO',
  },
  McMurdo: {
    country: 'Antarctica',
    code: 'AQ',
  },
  DumontDUrville: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Syowa: {
    country: 'Antarctica',
    code: 'AQ',
  },
  Aruba: {
    country: 'Aruba',
    code: 'AW',
  },
  Mariehamn: {
    country: 'Åland Islands',
    code: 'AX',
  },
  Sarajevo: {
    country: 'Bosnia & Herzegovina',
    code: 'BA',
  },
  Ouagadougou: {
    country: 'Burkina Faso',
    code: 'BF',
  },
  Bahrain: {
    country: 'Bahrain',
    code: 'BH',
  },
  Bujumbura: {
    country: 'Burundi',
    code: 'BI',
  },
  'Porto-Novo': {
    country: 'Benin',
    code: 'BJ',
  },
  St_Barthelemy: {
    country: 'St Barthelemy',
    code: 'BL',
  },
  Kralendijk: {
    country: 'Caribbean NL',
    code: 'BQ',
  },
  Nassau: {
    country: 'Bahamas',
    code: 'BS',
  },
  Gaborone: {
    country: 'Botswana',
    code: 'BW',
  },
  'Blanc-Sablon': {
    country: 'Canada',
    code: 'CA',
  },
  Atikokan: {
    country: 'Canada',
    code: 'CA',
  },
  Creston: {
    country: 'Canada',
    code: 'CA',
  },
  Kinshasa: {
    country: 'Congo (Dem. Rep.)',
    code: 'CD',
  },
  Lubumbashi: {
    country: 'Congo (Dem. Rep.)',
    code: 'CD',
  },
  Bangui: {
    country: 'Central African Rep.',
    code: 'CF',
  },
  Brazzaville: {
    country: 'Congo (Rep.)',
    code: 'CG',
  },

  Douala: {
    country: 'Cameroon',
    code: 'CM',
  },
  Curacao: {
    country: 'Curaçao',
    code: 'CW',
  },
  Busingen: {
    country: 'Germany',
    code: 'DE',
  },
  Djibouti: {
    country: 'Djibouti',
    code: 'DJ',
  },
  Dominica: {
    country: 'Dominica',
    code: 'DM',
  },
  Asmara: {
    country: 'Eritrea',
    code: 'ER',
  },
  Addis_Ababa: {
    country: 'Ethiopia',
    code: 'ET',
  },
  Libreville: {
    country: 'Gabon',
    code: 'GA',
  },
  Grenada: {
    country: 'Grenada',
    code: 'GD',
  },
  Guernsey: {
    country: 'Guernsey',
    code: 'GG',
  },
  Accra: {
    country: 'Ghana',
    code: 'GH',
  },
  Banjul: {
    country: 'Gambia',
    code: 'GM',
  },
  Conakry: {
    country: 'Guinea',
    code: 'GN',
  },
  Guadeloupe: {
    country: 'Guadeloupe',
    code: 'GP',
  },
  Malabo: {
    country: 'Equatorial Guinea',
    code: 'GQ',
  },
  Zagreb: {
    country: 'Croatia',
    code: 'HR',
  },
  Isle_of_Man: {
    country: 'Isle of Man',
    code: 'IM',
  },
  Jersey: {
    country: 'Jersey',
    code: 'JE',
  },
  Phnom_Penh: {
    country: 'Cambodia',
    code: 'KH',
  },
  Comoro: {
    country: 'Comoros',
    code: 'KM',
  },
  St_Kitts: {
    country: 'St Kitts & Nevis',
    code: 'KN',
  },
  Kuwait: {
    country: 'Kuwait',
    code: 'KW',
  },
  Cayman: {
    country: 'Cayman Islands',
    code: 'KY',
  },
  Vientiane: {
    country: 'Laos',
    code: 'LA',
  },
  St_Lucia: {
    country: 'St Lucia',
    code: 'LC',
  },
  Vaduz: {
    country: 'Liechtenstein',
    code: 'LI',
  },
  Maseru: {
    country: 'Lesotho',
    code: 'LS',
  },
  Podgorica: {
    country: 'Montenegro',
    code: 'ME',
  },
  Marigot: {
    country: 'St Martin (French)',
    code: 'MF',
  },
  Antananarivo: {
    country: 'Madagascar',
    code: 'MG',
  },
  Skopje: {
    country: 'North Macedonia',
    code: 'MK',
  },
  Bamako: {
    country: 'Mali',
    code: 'ML',
  },
  Saipan: {
    country: 'Northern Mariana Islands',
    code: 'MP',
  },

  Nouakchott: {
    country: 'Mauritania',
    code: 'MR',
  },
  Montserrat: {
    country: 'Montserrat',
    code: 'MS',
  },
  Blantyre: {
    country: 'Malawi',
    code: 'MW',
  },
  Niamey: {
    country: 'Niger',
    code: 'NE',
  },
  Muscat: {
    country: 'Oman',
    code: 'OM',
  },
  Kigali: {
    country: 'Rwanda',
    code: 'RW',
  },
  St_Helena: {
    country: 'St Helena',
    code: 'SH',
  },
  Ljubljana: {
    country: 'Slovenia',
    code: 'SI',
  },
  Longyearbyen: {
    country: 'Svalbard & Jan Mayen',
    code: 'SJ',
  },
  Bratislava: {
    country: 'Slovakia',
    code: 'SK',
  },
  Freetown: {
    country: 'Sierra Leone',
    code: 'SL',
  },
  San_Marino: {
    country: 'San Marino',
    code: 'SM',
  },
  Dakar: {
    country: 'Senegal',
    code: 'SN',
  },
  Mogadishu: {
    country: 'Somalia',
    code: 'SO',
  },
  Lower_Princes: {
    country: 'St Maarten (Dutch)',
    code: 'SX',
  },
  Mbabane: {
    country: 'Eswatini (Swaziland)',
    code: 'SZ',
  },
  Lome: {
    country: 'Togo',
    code: 'TG',
  },
  Port_of_Spain: {
    country: 'Trinidad & Tobago',
    code: 'TT',
  },
  Dar_es_Salaam: {
    country: 'Tanzania',
    code: 'TZ',
  },
  Kampala: {
    country: 'Uganda',
    code: 'UG',
  },
  Midway: {
    country: 'US minor outlying islands',
    code: 'UM',
  },
  Vatican: {
    country: 'Vatican City',
    code: 'VA',
  },
  St_Vincent: {
    country: 'St Vincent',
    code: 'VC',
  },
  Tortola: {
    country: 'Virgin Islands (UK)',
    code: 'VG',
  },
  St_Thomas: {
    country: 'Virgin Islands (US)',
    code: 'VI',
  },
  Aden: {
    country: 'Yemen',
    code: 'YE',
  },
  Mayotte: {
    country: 'Mayotte',
    code: 'YT',
  },
  Lusaka: {
    country: 'Zambia',
    code: 'ZM',
  },
  Harare: {
    country: 'Zimbabwe',
    code: 'ZW',
  },
};

type UserLocationMetaData = {
  region: string;
  city: string;
  code: string;
  country: string;
};

/**
 * This function return UserLocationMetaData based on
 * the user system timezone by mapping the city value
 * in the timezone with the country object
 */
export const getUserLocationMetaData = (): UserLocationMetaData => {
  try {
    if (typeof Intl !== 'undefined') {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Ex. User TimeZone: Asia/Calcutta

      if (userTimeZone && userTimeZone.includes('/')) {
        const tzArr = userTimeZone.split('/');
        // Ex. tzArr will be: ['Asia', 'Calcutta']

        const region = tzArr[0];
        // Ex. Region: Asia

        const city = tzArr[tzArr.length - 1];
        // Ex. City: Calcutta

        // Finding the country data, based on the city
        const { code, country } = cityTimezoneCountryMapping[city] || {
          code: '',
          country: '',
        };

        return {
          region,
          city,
          code,
          country,
        };
      }
    }
  } catch (error) {
    console.error('Error retrieving user location metadata:', error);
  }

  return {
    region: '',
    city: '',
    code: '',
    country: '',
  };
};
