import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { RootState } from "../../../../store/root-reducer";

import {
  SignupRequestPayload,
  AcceptInvitationRequestPayload,
} from "../../types";

import { signupRequest, acceptInvitationRequest } from "../signup/extra-action";
import { hideSignupRequestError } from "../signup/signup-slice";

import SignupForm from "./signup-form";

const mapStateToProps = (state: RootState) => ({
  signupRequestStatus: state.signup.signupRequest.status,
  signupRequestMessage: state.signup.signupRequest.message,
  signupRequestError: state.signup.signupRequest.error,

  acceptInvitationRequestStatus: state.signup.acceptInvitationRequest.status,
  acceptInvitationRequestMessage: state.signup.acceptInvitationRequest.message,
  acceptInvitationRequestError: state.signup.acceptInvitationRequest.error,

  token: state.signup.token,
  trackingId: state.signup.trackingId,
  showSignupError: state.signup.showSignupError,
});

const mapDispatchToProps = {
  sendSignupRequest: (payload: SignupRequestPayload) => signupRequest(payload),

  hideSignupRequestError: () => hideSignupRequestError(),

  sendAcceptInvitationRequest: (payload: AcceptInvitationRequestPayload) =>
    acceptInvitationRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    firstName?: string;
    lastName?: string;
    email?: string;
    isInvitedUser?: boolean;
    invitationToken?: string;
  };

export default withRouter(connector(SignupForm));
