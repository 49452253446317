import { AgencyRole } from "../../../../../shared/enum";

export const getSwitchTooltip = (
  isChecked: boolean,
  isDisabled: boolean
): string => {
  if (!isDisabled) {
    return isChecked ? "Disable" : "Enable";
  }

  return "";
};

export const hasUserPermissionToMakeAdmin = (role: AgencyRole): boolean =>
  role === AgencyRole.AgencyAdmin || role === AgencyRole.AgencyOwner;

export const getAssignClientListEmptyMessage = (role: AgencyRole): string => {
  return hasUserPermissionToMakeAdmin(role)
    ? "No clients added"
    : "You don't have permission to perform this task";
};
