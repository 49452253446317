/* eslint-disable react/react-in-jsx-scope */
import ReactDOM from "react-dom";
import "@saleshandy/designs/lib/assets/css/design-system.scss";
import history from "./shared/history";
import store from "./store";
import Root from "./root";
import "./assets/css/design-system.scss";

declare global {
  interface Window {
    analytics: any;
    intercomSettings: any;
    Intercom: any;
  }
}

ReactDOM.render(
  <Root history={history} store={store} />,
  document.getElementById("root")
);
