import store from "../../store/store";

const getState = () => store.getState();

export const getUserFirstName = () => getState().home?.firstName;

export const getUserLastName = () => getState().home?.lastName;

export const getUserFullName = () =>
  `${getUserFirstName()} ${getUserLastName()}`;

export const getUserEmail = () => getState().home?.email;

export const getUserSignUpDate = () => getState().home?.createdAt;

export const getUserRole = () => getState().home?.role;

export const getAgencyName = () => getState().home?.agency?.name;
