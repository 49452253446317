import React from "react";
import { Route } from "react-router";
import { RouteProps, withRouter, RouteComponentProps } from "react-router";
import { AuthHelper } from "../utils";

export type IProps = RouteComponentProps & Omit<RouteProps, "render">;
class ProtectedRoute extends React.Component<IProps, null> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.verifyAuth = this.verifyAuth.bind(this);
  }

  verifyAuth() {
    if (!AuthHelper.isAuthenticated) {
      AuthHelper.logout({ fallbackTo: this.props.location });
    }
  }

  componentDidUpdate() {
    this.verifyAuth();
  }

  componentDidMount() {
    this.verifyAuth();
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          AuthHelper.isAuthenticated ? <Component {...props} /> : null
        }
      />
    );
  }
}

export default withRouter(ProtectedRoute);
