import React, { useState, useEffect } from "react";
import { Modal } from "@saleshandy/designs";
import { Button } from "@saleshandy/design-system";
import { CircleCheck } from "@saleshandy/icons";

import { RequestStatus } from "../../enum";
import { UpdateOnboardingPayload } from "../../../components/home/types/request/update-onboarding";

import { getOnboardingQuestions } from "./onboarding-questions";
import { OnboardingQuestionOption, OnboardingQuestionType } from "./type";
import { getIsRequestPending } from "../../utils/get-request-status";
import { Images } from "../../utils/app-constants";

type IProps = {
  show: boolean;
  firstName: string;
  sendUpdateOnboardingRequest: (payload: UpdateOnboardingPayload) => void;
  updateOnboardingRequestStatus: RequestStatus;
};

const OnboardingModal: React.FC<IProps> = ({
  show,
  firstName,
  sendUpdateOnboardingRequest,
  updateOnboardingRequestStatus,
}) => {
  const onboardingQuestions = getOnboardingQuestions();

  const [currentQuestion, setCurrentQuestion] =
    useState<OnboardingQuestionType>(onboardingQuestions[0]);

  const [selectedOptions, setSelectedOptions] =
    useState<UpdateOnboardingPayload>({
      useCase: null,
      industry: null,
      clientSize: null,
      contactableProspect: null,
      outreachType: null,
    });

  const onSelectedOptionChanged = (value: string) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestion.key]: value,
    });

    if (
      currentQuestion.questionNumber !==
      onboardingQuestions[onboardingQuestions.length - 1].questionNumber
    ) {
      setCurrentQuestion(onboardingQuestions[currentQuestion.questionNumber]);
    }
  };

  const previousQuestion = () => {
    setCurrentQuestion(onboardingQuestions[currentQuestion.questionNumber - 2]);
  };

  const extractMultiText = (txt: string) => {
    // Define a regular expression pattern to match the desired format
    const regex = /^(.+?)\s+\((.+?)\)$/;

    // Use the pattern to match the input text
    const matches = txt.match(regex);

    if (matches) {
      // Extract the two parts of the text
      const firstPart = matches[1].trim();
      const secondPart = matches[2].trim();

      // Return an object with both parts
      return {
        firstPart,
        secondPart,
      };
    }
    // If no match is found, return null or handle it as needed
    return null;
  };

  useEffect(() => {
    if (
      show &&
      currentQuestion.questionNumber ===
        onboardingQuestions[onboardingQuestions.length - 1].questionNumber &&
      selectedOptions.outreachType
    ) {
      sendUpdateOnboardingRequest(selectedOptions);
    }
  }, [selectedOptions]);

  const isLoading = getIsRequestPending(updateOnboardingRequestStatus);

  return (
    <Modal
      show={show}
      hideHeader
      hideFooter
      backdrop="static"
      extraModalProps={{
        centered: true,
        "aria-labelledby": "contained-modal-title-vcenter",
      }}
      className="onboarding-modal--wrapper"
    >
      <div>
        <div
          className={`onboarding-modal--header ${isLoading ? "isLoading" : ""}`}
        >
          <h1>
            Hey <span>{firstName}</span> 👋🏻{!isLoading && <>,</>}
          </h1>
          {!isLoading && <p>Let’s know each other better...</p>}
        </div>

        <div
          key={currentQuestion.questionNumber}
          className="onboarding-modal--question-wrapper"
        >
          {isLoading ? (
            <div className="d-flex flex-column justify-content-center align-items-center onboarding-modal--loader">
              <img src={Images.Rocket} alt="Rocket" />
              <p>Get ready to dominate your email outreach!</p>
            </div>
          ) : (
            <>
              <p className="onboarding-modal--question-number">
                Question {currentQuestion.questionNumber}/
                {onboardingQuestions.length}
              </p>
              <h1 className="onboarding-modal--question">
                {currentQuestion.question}
              </h1>
              <div className="onboarding-modal--options">
                {currentQuestion.options.map(
                  (option: OnboardingQuestionOption) => {
                    const multiText = extractMultiText(option.value);

                    return (
                      <button
                        key={option.value}
                        className={`onboarding-modal--option ${
                          selectedOptions[currentQuestion.key] === option.value
                            ? "active"
                            : ""
                        }`}
                        tabIndex={0}
                        type="button"
                        onClick={() => onSelectedOptionChanged(option.value)}
                      >
                        {multiText ? (
                          <span className="multi-text">
                            <span className="firstPart">
                              {multiText.firstPart}
                            </span>
                            <span className="secondPart">
                              ({multiText.secondPart})
                            </span>
                          </span>
                        ) : (
                          <span>{option.value}</span>
                        )}

                        {selectedOptions[currentQuestion.key] ===
                          option.value && (
                          <div className="icon">
                            <CircleCheck />
                          </div>
                        )}
                      </button>
                    );
                  }
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {!isLoading && (
        <div
          className={`onboarding-modal--footer ${
            currentQuestion.questionNumber === 1 ? "justify-content-end" : ""
          } `}
        >
          {currentQuestion.questionNumber !== 1 && (
            <Button
              variant="link-subtle"
              className="back-btn"
              onClick={previousQuestion}
            >
              Back
            </Button>
          )}

          <div className="onboarding-modal--indicators">
            {onboardingQuestions.map((onboardingQuestion) => (
              <div
                className={`indicator ${
                  currentQuestion.questionNumber ===
                  onboardingQuestion.questionNumber
                    ? "active"
                    : ""
                }`}
              />
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default OnboardingModal;
