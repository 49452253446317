import React from "react";
import clsx from "clsx";
import { SkeletonLoading } from "@saleshandy/design-system";
import { accessibleOnClick } from "../../../utils/accessible-on-click";
import { EVCreditsPlan } from "../../../../components/billing/types";

type IProps = {
  plans: EVCreditsPlan[];
  selectedPlan: EVCreditsPlan;
  availableCredits: number;
  handleSelectPlan: (plan: EVCreditsPlan) => void;
  isLoading: boolean;
};

const EvCreditsPlans: React.FC<IProps> = ({
  plans,
  selectedPlan,
  handleSelectPlan,
  isLoading,
}) => {
  const renderLoader = () =>
    [...Array(3)].map(() => (
      <div className="credit-plan border-gray">
        <div className="d-flex align-items-center">
          <SkeletonLoading width={80} height={22} />
        </div>
        <SkeletonLoading width={28} height={22} />
      </div>
    ));

  const renderPlans = () =>
    plans?.map((plan) => {
      const containerClassName = clsx([
        "credit-plan",
        {
          "border-blue": plan.id === selectedPlan?.id,
        },
        {
          "border-gray": plan.id !== selectedPlan?.id,
        },
      ]);

      return (
        <div
          className={containerClassName}
          {...accessibleOnClick(() => handleSelectPlan(plan))}
        >
          <div className="d-flex align-items-center">
            <span className="semibold-2">{plan.name}</span>

            {plan.isBestValue === 1 && (
              <span className="best-value-txt">BEST VALUE</span>
            )}
          </div>
          <span className="semibold-2">${plan.amount}</span>
        </div>
      );
    });

  return <>{isLoading ? renderLoader() : renderPlans()}</>;
};

export default EvCreditsPlans;
