import { connect, ConnectedProps } from "react-redux";

import { RootState } from "../../../store/root-reducer";

import {
  getEVCreditsPlanRequest,
  getEVCreditsRequest,
  purchaseEVCreditsRequest,
} from "../../../components/billing/extra-actions";

import {
  resetGetEVPlanRequest,
  resetPurchaseEVCreditsRequest,
  resetPurchaseEVCreditsResponse,
} from "../../../components/billing/billing-slice";

import { PurchaseEVCreditsRequest } from "../../../components/billing/types";

import PurchaseCreditsModal from "./purchase-ev-credits-modal";

const mapStateToProps = (state: RootState) => ({
  getEVCreditsPlanRequestStatus: state.billing.getEVCreditsPlanRequest.status,
  getEVCreditsPlanRequestError: state.billing.getEVCreditsPlanRequest.error,

  purchaseEVCreditsRequestStatus: state.billing.purchaseEVCreditsRequest.status,
  purchaseEVCreditsRequestError: state.billing.purchaseEVCreditsRequest.error,
  purchaseEVCreditsRequestMessage:
    state.billing.purchaseEVCreditsRequest.message,

  evCreditsPlans: state.billing.evCreditsPlans,
  purchaseEVCreditsResponse: state.billing.purchaseEVCreditsResponse,
});

const mapDispatchToProps = {
  sendGetEVCreditsPlanRequest: () => getEVCreditsPlanRequest(),
  resetGetEVPlanRequest: () => resetGetEVPlanRequest(),

  sendPurchaseEVCreditsRequest: (payload: PurchaseEVCreditsRequest) =>
    purchaseEVCreditsRequest(payload),
  resetPurchaseEVCreditsRequest: () => resetPurchaseEVCreditsRequest(),

  resetPurchaseEVCreditsResponse: () => resetPurchaseEVCreditsResponse(),

  sendGetEVCreditsRequest: () => getEVCreditsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: VoidFunction;
  availableCredits?: number;
};

export default connector(PurchaseCreditsModal);
