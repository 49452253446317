import { OnboardingQuestionType } from "./type";
import {
  ClientSize,
  ContactableProspect,
  Industry,
  OutreachType,
  UseCase,
} from "../../enum/onboarding";

const shuffle = (array) => {
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(
      (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
        currentIndex
    );

    // eslint-disable-next-line no-plusplus
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

// Pre-shuffled arrays for options
const shuffledUseCaseOptions = shuffle([
  { value: UseCase.LEAD_GENERATION },
  { value: UseCase.BOOK_MEETING_AND_BUILD_SALES_PIPELINE },
  { value: UseCase.OUTREACH_RECRUITMENT },
  { value: UseCase.FindLeadsFromSaleshandyConnect },
  { value: UseCase.PROMOTE_PRODUCT_AND_SERVICE },
  { value: UseCase.ONE_TIME_COLD_EMAIL_OUTREACH },
]);

const shuffledIndustryOptions = shuffle([
  { value: Industry.INFORMATION_TECHNOLOGY },
  { value: Industry.MARKETING_ADVERTISING },
  { value: Industry.COMPUTER_SOFTWARE },
  { value: Industry.STAFF_RECRUITMENT },
]);

const shuffledOutreachProspect = shuffle([
  { value: OutreachType.EMAIL_ONLY },
  { value: OutreachType.MULTI_CHANNEL },
]);

// Function to get the onboarding questions
export const getOnboardingQuestions = (): OnboardingQuestionType[] => {
  const onboardingQuestions = [
    {
      question: "What is your purpose for using Saleshandy?",
      key: "useCase",
      options: [...shuffledUseCaseOptions, { value: UseCase.OTHER }],
    },
    {
      question: "Which industry do you work in?",
      key: "industry",
      options: [...shuffledIndustryOptions, { value: Industry.OTHERS }],
    },
    {
      question: "How many clients do you need to serve?",
      key: "clientSize",
      options: [
        { value: ClientSize["1-10"] },
        { value: ClientSize["11-30"] },
        { value: ClientSize.MORETHAN30 },
      ],
    },
    {
      question: "How many prospects you might contact using Saleshandy?",
      key: "contactableProspect",
      options: [
        { value: ContactableProspect.ZERO_TO_2K },
        { value: ContactableProspect.TWO_K_TO_40K },
        { value: ContactableProspect.FORTY_K_TO_100k },
        { value: ContactableProspect.ONE_HUNDRED_k_TO_500k },
        { value: ContactableProspect.FIVE_HUNDRED_K_PLUS },
        { value: ContactableProspect.OTHER },
      ],
    },
    {
      question: "How do you outreach your prospects/contacts?",
      key: "outreachType",
      options: shuffledOutreachProspect,
    },
  ];

  return onboardingQuestions.map((item, index) => ({
    ...item,
    questionNumber: index + 1,
  }));
};
