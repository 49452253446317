import React from "react";
import { SkeletonLoading } from "@saleshandy/design-system";

const AccountPlanUsageLoader = () => (
  <div className="card">
    <div className="card-body apu-loader">
      <div className="subscription-plan__info">
        <div className="d-flex flex-column">
          <SkeletonLoading width={160} height={18} />
          <SkeletonLoading width={208} height={16} />
        </div>
        <SkeletonLoading width={100} height={20} />
      </div>

      <div className="bs-mt-20 d-flex justify-content-between">
        <div className="d-flex flex-column progress-bar-container">
          <div className="d-flex justify-content-between">
            <SkeletonLoading width={120} height={20} />
            <SkeletonLoading width={120} height={20} />
          </div>
          <div className="mt-3 skeleton-w-100">
            <SkeletonLoading width={560} height={10} />
          </div>

          <div className="mt-1">
            <SkeletonLoading width={130} height={14} />
          </div>
        </div>

        <div className="d-flex flex-column progress-bar-container">
          <div className="d-flex justify-content-between">
            <SkeletonLoading width={120} height={20} />
            <SkeletonLoading width={120} height={20} />
          </div>
          <div className="mt-3">
            <SkeletonLoading width={560} height={10} />
          </div>

          <div className="mt-1">
            <SkeletonLoading width={130} height={14} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AccountPlanUsageLoader;
