import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { RootState } from "../../../../store/root-reducer";
import CreatePassword from "./create-password";
import {
  createPasswordRequest,
  resetPasswordRequest,
  verifyResetPasswordTokenRequest,
  verifyTokenRequest,
} from "./extra-action";
import { CreatePasswordRequest } from "../../types";

const mapStateToProps = (state: RootState) => ({
  createPasswordRequestStatus:
    state.createPassword.createPasswordRequest.status,
  verifyTokenRequestStatus: state.createPassword.verifyTokenRequest.status,
  verifyTokenRequestMessage: state.createPassword.verifyTokenRequest.message,
  verifyResetPasswordTokenRequestStatus:
    state.createPassword.verifyResetPasswordTokenRequest.status,
  verifyResetPasswordTokenRequestMessage:
    state.createPassword.verifyResetPasswordTokenRequest.message,
  resetPasswordRequestStatus: state.createPassword.resetPasswordRequest.status,
  resetPasswordRequestMessage:
    state.createPassword.resetPasswordRequest.message,

  firstName: state.createPassword.firstName,
  lastName: state.createPassword.lastName,
  email: state.createPassword.email,
});

const mapDispatchToProps = {
  sendCreatePasswordRequest: (payload: CreatePasswordRequest) =>
    createPasswordRequest(payload),
  sendResetPasswordRequest: (token: string, password: string) =>
    resetPasswordRequest({ token, password }),
  sendVerifyPasswordRequest: (token: string) => verifyTokenRequest({ token }),
  sendVerifyResetPasswordTokenRequest: (token: string) =>
    verifyResetPasswordTokenRequest({ token }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(CreatePassword);
