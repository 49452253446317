import React from "react";
import { Button, EmptyList } from "@saleshandy/designs";
import { IProps } from "./types";

const EmptyContent: React.FC<IProps> = ({ showAddClientModal }) => {
  return (
    <div style={{ height: "90vh" }}>
      <EmptyList
        isVertical
        title='Add your first client'
        description='You can add your client’s account just by naming it. Later your team member can log into it with different roles and permissions you give them.'
        className='empty-content'
      >
        <Button variant={Button.Variant.Primary} onClick={showAddClientModal}>
          Add new Client
        </Button>
      </EmptyList>
    </div>
  );
};

export default EmptyContent;
