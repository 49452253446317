import * as React from "react";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const PricingPage = ({ userId, email }) => (
  <stripe-pricing-table
    pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
    publishable-key={process.env.REACT_APP_STRIPE_KEY}
    client-reference-id={userId}
    customer-email={email}
  />
);

export default PricingPage;
