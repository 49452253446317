import api from "../../../api";
import { ResponseSuccess } from "../../../shared/types";
import { UpdateOnboardingPayload } from "../types/request/update-onboarding";

export const getUserSettings = () => api.get<ResponseSuccess>("/agency/meta");

export const updateOnboarding = (payload: UpdateOnboardingPayload) =>
  api.patch<ResponseSuccess>("/agency/onboarding", payload);

export const completeOnboarding = () =>
  api.patch<ResponseSuccess>("/agency/complete-onboarding");
