import api from '../../../api'
import { ResponseSuccess } from '../../../shared/types'
import { InviteUserRequest } from '../types'
import { AssignClientRequest } from '../types/request/assign-client-request'

export const getAgencyUsers = (params) =>
	api.get<ResponseSuccess>(`/agency/users`, { params })

export const inviteUser = (payload: InviteUserRequest) =>
	api.post<ResponseSuccess>('/agency/users/invite', payload)

export const toggleUserStatus = (agencyUserId: number) =>
	api.patch<ResponseSuccess>(`/agency/toggle-user-status/${agencyUserId}`)

export const assignClient = (
	agencyUserId: number,
	payload: AssignClientRequest
) =>
	api.patch<ResponseSuccess>(`/agency/assign-clients/${agencyUserId}`, payload)

export const deleteTeamMember = (agencyUserId: number) =>
	api.delete<ResponseSuccess>(`/agency/user/${agencyUserId}`)
