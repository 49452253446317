import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseErrorWithHandled, ResponseSuccess } from "../../shared/types";
import {
  getEVCredits,
  getAccountPlanUsage,
  getEVCreditsPlan,
  purchaseEVCredits,
} from "./helper/billing.api";
import { PurchaseEVCreditsRequest } from "./types";

export const getEVCreditsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>("billing/getEVCreditsRequest", async (_args, thunkAPI) => {
  try {
    return await getEVCredits();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAccountPlanUsageRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>("billing/getAccountPlanUsageRequest", async (_args, thunkAPI) => {
  try {
    return await getAccountPlanUsage();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getEVCreditsPlanRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>("billing/getEVCreditsPlanRequest", async (_args, thunkAPI) => {
  try {
    return await getEVCreditsPlan();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const purchaseEVCreditsRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseEVCreditsRequest,
  { rejectValue: ResponseErrorWithHandled }
>("billing/purchaseEVCreditsRequest", async (args, thunkAPI) => {
  try {
    return await purchaseEVCredits(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
