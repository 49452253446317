import history from "../history";
import { LocationDescriptor, LocationState } from "history";

export const redirectWithRefresh = (url: string) => {
  window.location.href = url;
};

export const redirectWithoutRefresh = <S = LocationState>(
  location: LocationDescriptor<S>
) => {
  history.push(location);
};
