import { createSlice } from "@reduxjs/toolkit";
import { AgencyRole } from "../../shared/enum";
import { RequestStatus } from "../../shared/enum/request-status";
import { AgencyUser, RequestState } from "../../shared/types";
import { moveArrayItem } from "../../shared/utils/move-array-item";
import {
  getAgencyUsersRequest,
  inviteUserRequest,
  toggleUserStatusRequest,
  assignClientRequest,
  deleteTeamMemberRequest,
} from "./extra-action";

type State = {
  getAgencyUsersRequest: RequestState;
  inviteUserRequest: RequestState;
  toggleUserStatusRequest: RequestState;
  assignClientRequest: RequestState;
  deleteTeamMemberRequest: RequestState;
  agencyUsers: AgencyUser[];
};

const initialState: State = {
  getAgencyUsersRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  inviteUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  toggleUserStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  assignClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteTeamMemberRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  agencyUsers: null,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    resetAssignClientsState: (state) => {
      state.assignClientRequest.status = RequestStatus.Ideal;
      state.assignClientRequest.message = null;
    },
    resetDeleteAgencyMemberState: (state) => {
      state.deleteTeamMemberRequest.status = RequestStatus.Ideal;
      state.deleteTeamMemberRequest.message = null;
    },
  },
  extraReducers: (builder) => {
    // Get Agency Users
    builder.addCase(getAgencyUsersRequest.pending, (state) => {
      state.getAgencyUsersRequest.status = RequestStatus.Pending;
      state.getAgencyUsersRequest.message = null;
      state.getAgencyUsersRequest.error = null;
    });
    builder.addCase(getAgencyUsersRequest.fulfilled, (state, action) => {
      let agencyUsers;
      const payload = action.payload.payload.items;
      const getOwnerIndex = payload.findIndex(
        (item) => item.role === AgencyRole.AgencyOwner
      );

      if (getOwnerIndex !== -1) {
        agencyUsers = moveArrayItem(
          action.payload.payload.items,
          getOwnerIndex,
          0
        );
      }

      state.getAgencyUsersRequest.status = RequestStatus.Succeeded;
      state.getAgencyUsersRequest.message = action.payload.message;
      state.agencyUsers = agencyUsers || payload;
    });
    builder.addCase(getAgencyUsersRequest.rejected, (state, action) => {
      state.getAgencyUsersRequest.status = RequestStatus.Failed;
      state.getAgencyUsersRequest.message = action.payload.message;
      state.getAgencyUsersRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Invite Agency User
    builder.addCase(inviteUserRequest.pending, (state) => {
      state.inviteUserRequest.status = RequestStatus.Pending;
      state.inviteUserRequest.message = null;
      state.inviteUserRequest.error = null;
    });
    builder.addCase(inviteUserRequest.fulfilled, (state, action) => {
      state.inviteUserRequest.status = RequestStatus.Succeeded;
      state.inviteUserRequest.message = action.payload.message;
    });
    builder.addCase(inviteUserRequest.rejected, (state, action) => {
      state.inviteUserRequest.status = RequestStatus.Failed;
      state.inviteUserRequest.message = action.payload.message;
      state.inviteUserRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Toggle Agency User Status
    builder.addCase(toggleUserStatusRequest.pending, (state) => {
      state.toggleUserStatusRequest.status = RequestStatus.Pending;
      state.toggleUserStatusRequest.message = null;
      state.toggleUserStatusRequest.error = null;
    });
    builder.addCase(toggleUserStatusRequest.fulfilled, (state, action) => {
      state.toggleUserStatusRequest.status = RequestStatus.Succeeded;
      state.toggleUserStatusRequest.message = action.payload.message;
    });
    builder.addCase(toggleUserStatusRequest.rejected, (state, action) => {
      state.toggleUserStatusRequest.status = RequestStatus.Failed;
      state.toggleUserStatusRequest.message = action.payload.message;
      state.toggleUserStatusRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Assign Client
    builder.addCase(assignClientRequest.pending, (state) => {
      state.assignClientRequest.status = RequestStatus.Pending;
      state.assignClientRequest.message = null;
      state.assignClientRequest.error = null;
    });
    builder.addCase(assignClientRequest.fulfilled, (state, action) => {
      state.assignClientRequest.status = RequestStatus.Succeeded;
      state.assignClientRequest.message = action.payload.message;
    });
    builder.addCase(assignClientRequest.rejected, (state, action) => {
      state.assignClientRequest.status = RequestStatus.Failed;
      state.assignClientRequest.message = action.payload.message;
      state.assignClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Team Member
    builder.addCase(deleteTeamMemberRequest.pending, (state) => {
      state.deleteTeamMemberRequest.status = RequestStatus.Pending;
      state.deleteTeamMemberRequest.message = null;
      state.deleteTeamMemberRequest.error = null;
    });
    builder.addCase(deleteTeamMemberRequest.fulfilled, (state, action) => {
      state.deleteTeamMemberRequest.status = RequestStatus.Succeeded;
      state.deleteTeamMemberRequest.message = action.payload.message;
    });
    builder.addCase(deleteTeamMemberRequest.rejected, (state, action) => {
      state.deleteTeamMemberRequest.status = RequestStatus.Failed;
      state.deleteTeamMemberRequest.message = action.payload.message;
      state.deleteTeamMemberRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export {
  getAgencyUsersRequest,
  inviteUserRequest,
  toggleUserStatusRequest,
  assignClientRequest,
  deleteTeamMemberRequest,
};
export const { resetAssignClientsState, resetDeleteAgencyMemberState } =
  teamsSlice.actions;
export default teamsSlice.reducer;
