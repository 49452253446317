import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseErrorWithHandled, ResponseSuccess } from "../../shared/types";
import {
  completeOnboarding,
  getUserSettings,
  updateOnboarding,
} from "./helper/home.api";
import { UpdateOnboardingPayload } from "./types/request/update-onboarding";

export const getUserSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>("home/getUserSettingsRequest", async (_args, thunkAPI) => {
  try {
    return await getUserSettings();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateOnboardingRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateOnboardingPayload,
  { rejectValue: ResponseErrorWithHandled }
>("home/updateOnboardingRequest", async (args, thunkAPI) => {
  try {
    return await updateOnboarding(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const completeOnboardingRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>("home/completeOnboardingRequest", async (_args, thunkAPI) => {
  try {
    return await completeOnboarding();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
