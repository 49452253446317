import React, { useState, useEffect } from "react";
import { Button, Input, ImageIcon } from "@saleshandy/designs";
import { Link } from "react-router-dom";
import toaster from "@saleshandy/designs/lib/toaster";
import validator from "validator";
import { Images } from "../../../../shared/utils/app-constants";
import type { IProps } from "./login-container";
import {
  executeOnRequestStatus,
  redirectWithoutRefresh,
} from "../../../../shared/utils";
import { setToken } from "../../../../shared/utils/token";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";
import routes from "../../../../routes";

const Login: React.FC<IProps> = ({
  sendLoginRequest,
  loginRequestStatus,
  loginRequestMessage,
  accessToken,
}) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const togglePasswordVisibility = () => {
    setShowPassword((preState) => !preState);
  };

  const validateEmail = () => {
    if (!validator.isEmail(email)) {
      return "Enter a valid email address";
    }
    return "";
  };

  const onInputBlur = () => {
    setError(validateEmail());
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    sendLoginRequest({ email, password });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: loginRequestStatus,
      onSuccess: () => {
        setToken(accessToken);
        redirectWithoutRefresh(routes.CLIENTS);
      },
      onFailed: () => {
        if (loginRequestMessage) {
          toaster.error(loginRequestMessage);
        }
      },
    });
  }, [loginRequestStatus]);

  return (
    <div className="auth__container">
      <div className="card">
        <div className="logo--section">
          <ImageIcon src={Images.SaleshandyLogo} />
        </div>
        <div className="card-body">
          <div className="card-body__title">
            <span className="semibold-4">Saleshandy Partner Portal</span>
          </div>
          <form onSubmit={onFormSubmit}>
            <Input
              name="email"
              label="Email"
              placeholder="Enter your email address..."
              value={email}
              variant={error && Input.Variant.Error}
              onChange={(e) => setEmail(e)}
              onBlur={onInputBlur}
              caption={error}
              autoComplete="current-email"
              className="input-field"
            />
            <Input
              name="password"
              label="Password"
              placeholder="Enter your password here..."
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e)}
              autoComplete="login-password"
              icons={[
                {
                  place: Input.IconPlace.Right,
                  identifier: showPassword ? "eye-alt" : "eye",
                  className: "pointer",
                  onClick: togglePasswordVisibility,
                },
              ]}
              className="input-field mt-4"
            />
            <div className="forgot-link regular-1">
              <Link to="/reset-password" className="pointer">
                Forgot Password?
              </Link>
            </div>

            <div className="card-body__btn-login">
              <Button
                variant={Button.Variant.Primary}
                type={Button.Type.Submit}
                isLoading={getIsRequestPending(loginRequestStatus)}
                disabled={
                  getIsRequestPending(loginRequestStatus) ||
                  !validator.isEmail(email) ||
                  validator.isEmpty(email) ||
                  validator.isEmpty(password)
                }
                className="w-100 btn-primary-disable"
              >
                Login
              </Button>
            </div>
          </form>
          <div className="bottom-navigation">
            <p>
              Don’t have an account?{" "}
              <Link to="/signup" tabIndex={0}>
                Sign up!
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
