import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from "../../../../shared/types";
import {
  createPassword,
  resetPassword,
  verifyToken,
  verifyResetPasswordToken,
} from "../../helper/auth.api";
import { CreatePasswordRequest } from "../../types";

export const createPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  CreatePasswordRequest,
  { rejectValue: ResponseErrorWithHandled }
>("create-password/createPasswordRequest", async (args, thunkAPI) => {
  try {
    return await createPassword(args);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const resetPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  { token: string; password: string },
  { rejectValue: ResponseErrorWithHandled }
>(
  "reset-password/resetPasswordRequest",
  async ({ token, password }, thunkAPI) => {
    try {
      return await resetPassword(token, password);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyTokenRequest = createAsyncThunk<
  ResponseSuccess,
  { token: string },
  { rejectValue: ResponseErrorWithHandled }
>("verifyToken/verifyTokenRequest", async ({ token }, thunkAPI) => {
  try {
    return await verifyToken(token);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const verifyResetPasswordTokenRequest = createAsyncThunk<
  ResponseSuccess,
  { token: string },
  { rejectValue: ResponseErrorWithHandled }
>(
  "verifyResetPasswordToken/verifyResetPasswordTokenRequest",
  async ({ token }, thunkAPI) => {
    try {
      return await verifyResetPasswordToken(token);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
