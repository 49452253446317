const routes = {
  SIGNUP: "/signup",
  LOGIN: "/login",
  CREATE_PASSWORD: "/create-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "/verify-email",
  CLIENTS: "/clients",
  TEAM: "/team",
  BILLING: "/billing",
  BILLING_USAGE: "/billing/usage",
  BILLING_SUBSCRIPTION: "/billing/subscription",
};

export default routes;
