import axios from "axios";
import { AuthHelper, getToken } from "./shared/utils";
import { ExceptionTypes, ResponseErrorWithHandled } from "./shared/types";
import history from "./shared/history";
import toaster from "@saleshandy/designs/lib/toaster";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => response.data,
  (err): Promise<ResponseErrorWithHandled> => {
    let error: ResponseErrorWithHandled;

    if (isCustomError(err)) {
      error = { ...err.response.data, isHandled: false };

      switch (error.type) {
        case ExceptionTypes.Auth:
          AuthHelper.logout({ fallbackTo: history.location });
          break;

        case ExceptionTypes.System:
          toaster.error(error.messages[0]);
          error.isHandled = true;
          break;

        case ExceptionTypes.Validation: {
          toaster.error(error.messages[0]);
          error.isHandled = true;
          break;
        }
        case ExceptionTypes.PlanPermission: {
          toaster.error(error.messages[0]);
          error.isHandled = true;
          break;
        }
      }
    } else {
      error = {
        error: true,
        type: ExceptionTypes.System,
        message: err.message,
        isHandled: true,
      };
    }

    return Promise.reject(error);
  }
);

const isCustomError = (err: any): boolean => !!err.response?.data?.type;

export default instance;
