import { formatNumber } from "../../../shared/utils";
import { featureUsages } from "./helper";

const calculateRemainingLimitPercentage = (
  totalSentLimit: number,
  remainingSentLimit: number
): number => {
  if (totalSentLimit === 0) {
    return 0;
  }

  return (remainingSentLimit / totalSentLimit) * 100;
};

export const getUsageData = (accountPlanData: {
  featureUsages: any;
  nextBillingDate?: Date;
}) => {
  const emailSendingDetails = accountPlanData?.featureUsages.filter(
    (usage: { featureCode: string }) =>
      usage.featureCode === featureUsages.emailSend
  )?.[0];
  const prospectAddingDetails = accountPlanData?.featureUsages.filter(
    (usage: { featureCode: string }) =>
      usage.featureCode === featureUsages.prospectAdd
  )?.[0];

  // Email Sent Limit
  const totalEmailSentLimit = Number(emailSendingDetails?.total) || 0;
  const remainingEmailSentLimit = Number(emailSendingDetails?.remaining) || 0;
  const remainingEmailSentLimitPer = calculateRemainingLimitPercentage(
    totalEmailSentLimit,
    remainingEmailSentLimit
  );

  //   Prospect Limit
  const totalProspectLimit = Number(prospectAddingDetails?.total) || 0;
  const remainingProspectLimit = Number(prospectAddingDetails?.remaining) || 0;
  const remainingProspectLimitPer = calculateRemainingLimitPercentage(
    totalProspectLimit,
    remainingProspectLimit
  );

  return [
    {
      label: "Monthly Emails sent",
      used: formatNumber(totalEmailSentLimit - remainingEmailSentLimit),
      remaining: formatNumber(remainingEmailSentLimit),
      total: formatNumber(totalEmailSentLimit),
      usedPer: 100 - remainingEmailSentLimitPer,
      remainingPer: remainingEmailSentLimitPer,
    },
    {
      label: "Total Prospects",
      used: formatNumber(totalProspectLimit - remainingProspectLimit),
      remaining: formatNumber(remainingProspectLimit),
      total: formatNumber(totalProspectLimit),
      usedPer: 100 - remainingProspectLimitPer,
      remainingPer: remainingProspectLimitPer,
    },
  ];
};
