import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../../shared/enum/request-status";
import { RequestState } from "../../../../shared/types";
import {
  signupRequest,
  acceptInvitationRequest,
  resendVerificationEmailRequest,
} from "./extra-action";

type State = {
  signupRequest: RequestState;
  acceptInvitationRequest: RequestState;
  resendVerificationEmailRequest: RequestState;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  showSignupError: boolean;
};

const initialState: State = {
  signupRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  acceptInvitationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendVerificationEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  trackingId: null,
  firstName: "",
  lastName: "",
  email: "",
  token: "",
  showSignupError: false,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    hideSignupRequestError: (state) => {
      state.showSignupError = false;
    },
  },
  extraReducers: (builder) => {
    // Signup
    builder.addCase(signupRequest.pending, (state) => {
      state.signupRequest.status = RequestStatus.Pending;
    });
    builder.addCase(signupRequest.fulfilled, (state, action) => {
      state.signupRequest.status = RequestStatus.Succeeded;
      state.signupRequest.message = action.payload.message;
      state.firstName = action.payload.payload?.firstName;
      state.lastName = action.payload.payload?.lastName;
      state.email = action.payload.payload?.email;
      state.token = action.payload.payload?.accessToken;
    });
    builder.addCase(signupRequest.rejected, (state, action) => {
      state.signupRequest.status = RequestStatus.Failed;
      state.signupRequest.message = action.payload.message;
      state.signupRequest.error = action.payload;

      const errorMessages = ["User already exists", "Bad email address"];
      state.showSignupError = errorMessages.includes(action.payload.message);
    });

    // Accept Invitation
    builder.addCase(acceptInvitationRequest.pending, (state) => {
      state.acceptInvitationRequest.status = RequestStatus.Pending;
    });
    builder.addCase(acceptInvitationRequest.fulfilled, (state, action) => {
      state.acceptInvitationRequest.status = RequestStatus.Succeeded;
      state.acceptInvitationRequest.message = action.payload.message;
      state.firstName = action.payload.payload?.firstName;
      state.lastName = action.payload.payload?.lastName;
      state.email = action.payload.payload?.email;
      state.token = action.payload.payload?.accessToken;
    });
    builder.addCase(acceptInvitationRequest.rejected, (state, action) => {
      state.acceptInvitationRequest.status = RequestStatus.Failed;
      state.acceptInvitationRequest.message = action.payload.message;
      state.acceptInvitationRequest.error = action.payload;
    });

    builder.addCase(resendVerificationEmailRequest.pending, (state) => {
      state.resendVerificationEmailRequest.status = RequestStatus.Pending;
      state.resendVerificationEmailRequest.error = null;
    });
    builder.addCase(
      resendVerificationEmailRequest.fulfilled,
      (state, action) => {
        state.resendVerificationEmailRequest.status = RequestStatus.Succeeded;
        state.resendVerificationEmailRequest.message = action.payload.message;
      }
    );
    builder.addCase(
      resendVerificationEmailRequest.rejected,
      (state, action) => {
        state.resendVerificationEmailRequest.status = RequestStatus.Failed;
        state.resendVerificationEmailRequest.error =
          !action.payload.isHandled && action.payload;
      }
    );
  },
});

export const { hideSignupRequestError } = signupSlice.actions;

export default signupSlice.reducer;
