import api from '../../../api'
import { ResponseSuccess } from '../../../shared/types'
import {
	AddClientRequest,
	GetClientsRequest,
	EditClientRequest
} from '../types'

export const addClient = (payload: AddClientRequest) =>
	api.post<ResponseSuccess>('/agency/add-client', payload)

export const getClients = (params: GetClientsRequest) =>
	api.get<ResponseSuccess>('/agency/clients', {
		params
	})

export const toggleClientStatus = (clientId: number) =>
	api.patch<ResponseSuccess>(`/agency/toggle-client-status/${clientId}`)

export const oneClickLogin = (clientId: number) =>
	api.get<ResponseSuccess>(`/agency/one-click-token/${clientId}`)

export const editClient = (payload: EditClientRequest) =>
	api.patch<ResponseSuccess>(`/agency/update-client/${payload.clientId}`, {
		clientName: payload.clientName
	})

export const deleteClient = (clientId: number) =>
	api.delete<ResponseSuccess>(`/agency/client/${clientId}`)
